import React, {useEffect} from 'react';
import axios from 'axios';
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText  from '@material-ui/core/FormHelperText';
import { CheckBox } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from './Components/LinkButton'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(3),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(0),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	commonAlert: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
		marginBottom: theme.spacing(1)
	},
	tabPanel: {
		padding: theme.spacing(3),
		minHeight: 300
	},
	tabItem: {
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
		borderColor: theme.palette.primary.main,
		'&:focus': {
			outline: 0,
		},
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
	  >
		{value === index && <Box>{children}</Box>}
	  </Typography>
	);
}


export default function Organisation(props) {

	const classes = useStyles();

	const [selectedTab, setSelectedTab] = React.useState(0);
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const [isFetchingData, setIsFetchingData] = React.useState(true);

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [organisationData, setOrganisationData] = React.useState({
		CANPUBLISH: false,
		AGREEWWWPUBLISH: false,
		AGREERESEARCH: false,
		AGREEMARKETING: false,
		DECISIONMUNICIPALITY: false,
		DECISIONORGANISATION: false,
		OPERATINGSECTOR: 'public',
		FOODSERVICEPROVIDER: 'public',
		INDUSTRY: ''
	});

	const [exams, setExams] = React.useState([]);


	const handleTextInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setOrganisationData({
			...organisationData,
			[event.target.id]: event.target.value
		});
	};

	const handleSelectChange = (event) => {
		//console.log(event.target.name + ' : ' + event.target.value)
		setOrganisationData({
			...organisationData,
			[event.target.name]: event.target.value
		});
	};
	
	const handleSwitchChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)
		setOrganisationData({
			...organisationData,
			[event.target.id]: event.target.checked
		});
	};

	const handlePublicPrivateChange = (event) => {
		setOrganisationData({
			...organisationData,
			[event.target.id]: event.target.value
		});
	};
	
	const handleSave = (event) => {
		event.preventDefault()

		console.log(organisationData)

		let isFormValid = true;

		if (organisationData.NAME=="" ||
			organisationData.Y_TUNNUS=="" || 
			organisationData.ORGADDRESS=="" || 
			organisationData.ZIP=="" || 
			organisationData.CITY=="" || 
			organisationData.MUNICIPALITY=="" || 
			organisationData.ADDRESS=="" || 
			organisationData.FIRSTNAME=="" || 
			organisationData.LASTNAME=="" || 
			organisationData.PHONE=="" || 
			organisationData.EMAIL=="" || 
			organisationData.INDUSTRY=="" || 
			organisationData.OPERATINGSECTOR=="" || 
			organisationData.FOODSERVICEPROVIDER=="") {
				isFormValid = false;
		}

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (isFormValid) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=save', organisationData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {

				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)
				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	}
	
	useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=info', {
				id: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsFetchingData(false)
					setOrganisationData(response.data.organisation)
					setExams(response.data.exams)
				} else {
					setIsFetchingData(true)
				}
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});
		}
	}, [props.chainid])

	
	const [munipalicities, setMunipalicities] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/munipalicity.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setMunipalicities(response.data.munipalicities)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])

	return (
		<Container maxWidth="xl" className={classes.container}>

		<h4 className={classes.PaperHeader}>Organisaation tiedot</h4>

			<Paper className={classes.paper}>

				<Tabs value={selectedTab} onChange={handleChange} indicatorColor="primary" variant="fullWidth" aria-label="Organisaation tiedot välilehdet">
					<Tab label="Perustiedot" className={selectedTab===0 ? classes.tabItem : classes.tabItem2} />
					<Tab label="Yhteydet" className={selectedTab===1 ? classes.tabItem : classes.tabItem2} />
				</Tabs>

				{isSaveOk &&
				<Alert severity="success" className={classes.commonAlert}>
					<AlertTitle>Tallennettu</AlertTitle>
					Organisaation tiedot on nyt tallennettu
				</Alert>
				}

				{isError &&
				<Alert severity="error" className={classes.commonAlert}>
					<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
					Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
				</Alert>
				}

				<form onSubmit={handleSave}>

					<TabPanel value={selectedTab} index={0} className={classes.tabPanel}>

						<Typography className={classes.maintText}>Täytä tähän organisaation/yrityksen virallinen nimi, osoite, laskutustiedot, organisaatiossa toimivan ohjelman yhteyshenkilön tiedot sekä toimialatiedot. Täydennä myös luomun käyttöön liittyvien strategisten päätösten tiedot.</Typography>

						<TextField
							id="NAME"
							label="Yrityksen virallinen nimi *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.NAME}
							fullWidth
							helperText="Kaupparekisterin mukainen nimi"
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.NAME==""}
						/>

						<TextField
							id="Y_TUNNUS"
							label="Y-Tunnus *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.Y_TUNNUS}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.Y_TUNNUS==""}
						/>	

						<TextField
							id="ORGADDRESS"
							label="Osoite *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.ORGADDRESS}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.ORGADDRESS==""}
						/>

						<Grid container spacing={2}>
							<Grid item xs={2}>
								<TextField
									id="ZIP"
									label="Postinumero *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={organisationData.ZIP}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && organisationData.ZIP==""}
								/>
							</Grid>
							<Grid item xs={10}>
								<TextField
									id="CITY"
									label="Postitoimipaikka *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={organisationData.CITY}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && organisationData.CITY==""}
								/>
							</Grid>
						</Grid>	

						<TextField
							id="WWW"
							label="Organisaation internetsivut *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.WWW}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.WWW==""}
						/>

						<TextField
							id="ADDRESS"
							label="Laskutustiedot *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.ADDRESS}
							fullWidth
							multiline
							rows="4"
							onChange={handleTextInputChange}
							helperText="Yrityksen laskutustiedot esim osoite tai verkkolaskuosoite"
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.ADDRESS==""}
						/>	

						<FormControl component="fieldset" fullWidth>
							<FormLabel component="legend">Luvat tietojen käyttöön</FormLabel>
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={organisationData.CANPUBLISH} onChange={handleSwitchChange} id="CANPUBLISH" name="CANPUBLISH" />} label="Organisaation nimen ja yhteystiedot saa julkaista Portaat luomuun ohjelman www-sivuilla" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={organisationData.AGREEWWWPUBLISH} onChange={handleSwitchChange} id="AGREEWWWPUBLISH" name="AGREEWWWPUBLISH" />} label="Internetsivujen osoitteen saa julkaista luomuravintola.fi sivuilla" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={organisationData.AGREERESEARCH} onChange={handleSwitchChange} id="AGREERESEARCH" name="AGREERESEARCH" />} label="Yhteystietoja saa käyttää luomualan ja -markkinoiden kehittämiseen liittyvien tutkimusten ja selvitysten tekemiseen" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={organisationData.AGREEMARKETING} onChange={handleSwitchChange} id="AGREEMARKETING" name="AGREEMARKETING" />} label="Yhteystietoja saa käyttää kaupalliseen markkinointiin (kuten tukkutoimijoiden markkinakirjeet jne)" />
						</FormControl>

						<hr/>

						<h4 className={classes.mainHeader}>Organisaation yhteyshenkilö</h4>

						<Grid container spacing={2}>
							<Grid item xs={4}>
								<TextField
									id="FIRSTNAME"
									label="Etunimi *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={organisationData.FIRSTNAME}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && organisationData.FIRSTNAME==""}
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField
									id="LASTNAME"
									label="Sukunimi *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={organisationData.LASTNAME}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && organisationData.LASTNAME==""}
								/>
							</Grid>
						</Grid>	

						<TextField
							id="PHONE"
							label="Puhelin *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.PHONE}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.PHONE==""}
						/>	

						<TextField
							id="EMAIL"
							label="Sähköposti *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.EMAIL}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && organisationData.EMAIL==""}
						/>				

						<hr/>

						<h4 className={classes.mainHeader}>Organisaation toiminnan kuvaus</h4>	
						
						<FormControl variant="outlined" fullWidth className={classes.formInput}>
							<InputLabel id="INDUSTRY-select-label" >Toimiala *</InputLabel>
							<Select
								labelId="INDUSTRY-select-label"
								id="INDUSTRY"
								name="INDUSTRY"
								value={organisationData.INDUSTRY}
								onChange={handleSelectChange}
								error={!isValid && organisationData.INDUSTRY==""}
								>
								<MenuItem key={1} value={'peruskoulu/lukio'}>peruskoulu/lukio</MenuItem>
								<MenuItem key={2} value={'muu oppilaitos'}>muu oppilaitos</MenuItem>
								<MenuItem key={3} value={'päiväkoti'}>päiväkoti</MenuItem>
								<MenuItem key={4} value={'sairaala/terveyskeskus'}>sairaala/terveyskeskus</MenuItem>
								<MenuItem key={5} value={'hoitokoti/palvelukoti'}>hoitokoti/palvelukoti</MenuItem>
								<MenuItem key={6} value={'henkilöstö-/lounasravintola'}>lounasravintola</MenuItem>
								<MenuItem key={7} value={'kahvila'}>kahvila</MenuItem>
								<MenuItem key={8} value={'ravintola'}>ravintola</MenuItem>
								<MenuItem key={9} value={'pikaruokaravintola'}>pikaruokaravintola</MenuItem>
								<MenuItem key={10} value={'hotelli'}>hotelli/motelli</MenuItem>
								<MenuItem key={11} value={'maaseutumatkailu'}>matkailu</MenuItem>
								<MenuItem key={12} value={'kurssikeskus/leirikeskus'}>kurssikeskus/leirikeskus</MenuItem>
							</Select>
						</FormControl>

						<TextField
							id="INDUSTRYOTHER"
							label="Muu toimiala"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={organisationData.INDUSTRYOTHER}
							fullWidth
							onChange={handleTextInputChange}
							helperText="Jos valitsit 'muu toimiala' niin mikä"
							InputLabelProps={{
								shrink: true,
							}}
						/>	

						<FormControl component="fieldset" fullWidth>
							<FormLabel component="legend">Toimintasektori *</FormLabel>
							<RadioGroup aria-label="operatingsector" value={organisationData.OPERATINGSECTOR} onChange={handlePublicPrivateChange} error={!isValid && (organisationData.OPERATINGSECTOR==null || organisationData.OPERATINGSECTOR=="")}>
								<FormControlLabel control={<Radio id="OPERATINGSECTOR" checked={organisationData.OPERATINGSECTOR === 'public'} value="public"/>} label="Julkinen" />
								<FormControlLabel control={<Radio id="OPERATINGSECTOR" checked={organisationData.OPERATINGSECTOR === 'private'} value="private"/>} label="Yksityinen" />
							</RadioGroup>
							<FormHelperText><strong>Julkinen,</strong> Julkisin varoin tuettua ja tuotettua ruokapalvelua, esim. koulut, päiväkodit, palvelukodit, seurakunnat. <strong>Yksityinen,</strong> Ravintolat, kahvilat, hotellit, lounasravintolat</FormHelperText>
						</FormControl>

						<FormControl component="fieldset" fullWidth>
							<FormLabel component="legend">Ruokapalvelun tuottaja *</FormLabel>
							<RadioGroup aria-label="foodserviceproducer" value={organisationData.FOODSERVICEPROVIDER} onChange={handlePublicPrivateChange} error={!isValid && (organisationData.FOODSERVICEPROVIDER==null || organisationData.FOODSERVICEPROVIDER=="")}>
								<FormControlLabel control={<Radio id="FOODSERVICEPROVIDER" checked={organisationData.FOODSERVICEPROVIDER === 'public'} value="public"/>} label="Julkinen (kunnan/kuntayhtymän omistuksessa oleva ruokapalvelu)" />
								<FormControlLabel control={<Radio id="FOODSERVICEPROVIDER" checked={organisationData.FOODSERVICEPROVIDER === 'private'} value="private"/>} label="Yksityinen" />
							</RadioGroup>
						</FormControl>

						<Grid container spacing={0}>
							<Grid item xs={12} className={classes.infogrid}>
								<Typography className={classes.maintText}>Toimipaikkoja yhteensä: <strong>{organisationData.totallocations} kpl</strong><br/>
								Tarjottavia aterioita yhteensä: <strong>{organisationData.totalmealsperyear} kpl</strong><br/>
								Toimipaikkojen henkilökuntaa yhteensä: <strong>{organisationData.totalpersonnel} kpl</strong></Typography>

							</Grid>
						</Grid>

						<Typography className={classes.maintText}>Organisaation/Yrityksen henkilöstömäärä ja vuosittain tarjottavien annosten määrä ilmoitetaan toimipaikkakohtaisesti <Link href="/locations">Toimipaikat -sivulla</Link>.<br/>Tähän organisaation/yrityksen tiedot sivulle yhdistetään toimipaikkakohtaiset määrät.</Typography>
						
						<hr/>
						<h4 className={classes.mainHeader}>Luomutesti</h4>

						<Typography className={classes.maintText}>Alla teidän oma organisaatiokohtainen linkki Luomutestiin. Linkin kautta testin suorittaneiden määrä ja nimet tallentuvat tänne lomakkeelle. Suoritukset päivittyvät kerran kalenterivuoden aikana. Kopio linkki ja lähetä se esimerkiksi sähköpostilla henkilöille, joiden haluat testin tekevän.<br/>
							<Link target="_blank" href={"https://plohjelma.bonsait.fi/exam/" + props.chainid}>{"https://plohjelma.bonsait.fi/exam/" + props.chainid}</Link>
						</Typography>

						{(exams && exams.length>0) &&
							<React.Fragment>
								<div className={classes.maintText}>Tentin suorittaneet viimeisen vuoden aikana:<br/>
								{exams.map((exam) =>
									<Typography key={exam.id} className={classes.PaperText}>{exam.name} <span className={classes.muted}>{exam.agreementdate}</span><br/></Typography>
								)}
								</div>
							</React.Fragment>
						}


						{organisationData.AGREEMENT &&
						<React.Fragment>
							<hr/>
							<FormLabel component="legend">Organisaatio on hyväksynyt ohjelman käyttöehdot rekisteröitymisen yhteydessä {organisationData.created}</FormLabel>
						</React.Fragment>
						}
						<hr/>


					</TabPanel>
					<TabPanel value={selectedTab} index={1} className={classes.tabPanel}>

					<Typography className={classes.maintText}>Täytä tähän tavarantoimittajien ja/tai toiminnanohjausjärjestelmien antamat asiakasnumerot.</Typography>

					<FormControl component="fieldset" fullWidth>
						<FormLabel component="legend">Lupa tietojen siirtoon</FormLabel>
						<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={organisationData.INTEGRATIONAGREED} onChange={handleSwitchChange} id="INTEGRATIONAGREED" name="INTEGRATIONAGREED" />} label="Hyväksyn tuotteiden ostotietojen automaattisen siirron kolmannen osapuolten ohjelmistoista kuten tavarantoimittajat ja/tai toiminnanohjausjärjestelmien toimittajat." />
					</FormControl>
					
					<h4 className={classes.mainHeader}>Heinon tukku</h4>
					<TextField
						id="HEINOID"
						label="Asiakasnumero"
						variant="outlined"
						className={classes.formInput}
						type="text"
						value={organisationData.HEINOID}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>	

					<hr/>

					</TabPanel>

					<Grid container className={classes.bottomGrid}>
						<Grid item xs={8}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Tallenna</Button>
						</Grid>
						<Grid item xs={4} className={classes.bottomGridRight}>
							<LinkButton to='/dashboard' className={classes.cancelButton}>Peruuta</LinkButton>
						</Grid>
					</Grid>	

				</form>

			</Paper>

		</Container>
	);
}

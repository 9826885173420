import React, { useState } from "react";
import { BrowserRouter as Router, Switch as RouterSwitch, Route } from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/fi";
import { AuthContext } from './Auth/Auth';
import PrivateRoute from './Auth/PrivateRoute';
import MainToolBar from './MainToolbar';
import Dashboard from './Dashboard';
import Plan from './Plan';
import Organisation from './Organisation';
import Locations from './Locations';
import Auditions from './Auditions';
import User from './User';
import Login from './Login';
import Forgotpassword from "./Forgotpassword.js"
import Resetpassword from "./Resetpassword.js"
import Register from './Register';
import Exam from './Exam';
import KatsyDemo from './KatsyDemo'
import SustainableDevelopment from './SustainableDevelopment';
import Chainchooser from './Chainchooser';

moment.locale("fi")

const font =  "'Work Sans', sans-serif";
const theme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1600,
		},
	},
	palette: {
		primary: {
			main: '#38b559',
		},
		secondary: {
			main: '#1d91bd',
		}
	},
	shadows: ["none"],
	typography: {
		fontFamily: font,
		h1: {
			fontFamily: font
		},
		h2: {
			fontFamily: font
		},
		h3: {
			fontFamily: font
		},
		h4: {
			fontFamily: font
		},
		h5: {
			fontFamily: font
		},
		button: {
			fontFamily: font
		},
		body1: {
			fontFamily: font
		}
	},
	overrides: {
		MuiListItem: {
			root: {
				"&$selected": {
					backgroundColor: '#38b559',
					color: '#fff',
					"&:hover": {
						backgroundColor: '#38b559',
					},
				},
			},
			button: {
				"&:hover": {
					backgroundColor: '#38b559',
				},
			},
		},
		MuiFormControlLabel: {
			root: {
				marginBottom: '0'
			},
			label: {
				fontFamily: "'Work Sans', sans-serif",
				fontWeight: '300',
			},
		},
		MuiFormControl: {
			root: {
				marginBottom: '1.5rem'
			},
		}
	},
})

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		marginBottom: 80
	},
	chainselectbar: {
		position: 'absolute',
		right: 0,
		width: '30%',
		backgroundColor: "#38b559",
		borderBottomLeftRadius: 20,
		color: "#fff",
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	chainselecttitle: {
		marginTop: 3,
	},
	chainselectbutton: {
	},
	gridRight: {
		textAlign: 'right'
	},
	speedDial: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 1200,
		minWidth: 800,
		minHeight: 500,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	adminbuttons: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		zIndex: 9999,
		maxWidth: 260,
		textAlign: 'right'
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	fab: {
		marginBottom: theme.spacing(2),
	},
	fabDelete: {
		backgroundColor: "#fdecea",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	},
	chainList: {
		marginBottom: theme.spacing(2),
		minHeight: 400,
		maxHeight: 400,
		overflow: 'auto'
	},
	copyright: {
		marginTop: 30,
		paddingBottom: 16,
		textAlign: "center",
		color: "#6d6d6d"
	}
}));

const userdefault = {
	displayName : '',
	email : '',
	uid : '',
	chains: [],
	selectedChain: {
		id: null,
		name: ''
	}
}

export default function App() {
	const classes = useStyles();

	//const existingTokens = JSON.parse(localStorage.getItem("tokens"));
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userData, setUserData] = useState(userdefault);

	const [selectedchain, setSelectedchain] = useState('');

	const [filteredChains, setFilteredChains] = React.useState([]);
	const handleFilter = (event) => {
		filterchains(event.target.value)
    };	
	const filterchains = (strfilter) => {
		let filteredList = []
		let chainlist = userData.chains;
		if (!strfilter) {
			filteredList = chainlist
		} else {
			chainlist.map((chain) => {
				if (chain.name.toLowerCase().indexOf(strfilter.toLowerCase())>-1) {
					filteredList.push(chain)
				}
			})
		}
		setFilteredChains(filteredList)
	}

	const setUser = (data) => {
		setUserData(data);
		// set first chain
		if (data.chains[0]) {
			setSelectedchain(data.chains[0].id)
		}
	}

	const clearChain = () => {
		setSelectedchain(null)
	}

	const reloadChain = () => {
		setSelectedchain(null)
		setSelectedchain(selectedchain)
	}

	const handleChainChange = (chain) => {
		setSelectedchain(chain.id)
		userData.selectedChain = chain
		handleChangeChainModalClose();
	};

	const [OpenChainMenuEl, setOpenChainMenuEl] = React.useState(null);
	const handleOpenChainMenu = (event) => {
		setOpenChainMenuEl(event.currentTarget)
	};
	const handleCloseChainMenu = () => {
		setOpenChainMenuEl(null);
	};

	
	const [changeChainModalOpen, setChangeChainModalOpen] = React.useState(false);
	const handleChangeChainModalOpen = () => {
		filterchains()
		setChangeChainModalOpen(true)
	};
	const handleChangeChainModalClose = () => {
		filterchains()
		setChangeChainModalOpen(false);
	};

	return (
		<AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, userData, setUserData: setUser }}>
			<Router>
				<ThemeProvider theme={theme}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<div className={classes.grow}>
							<MainToolBar chainid={selectedchain} clearchain={clearChain} reloadchain={reloadChain}/>
							{isAuthenticated &&
							<Chainchooser handleChainChange={handleChainChange} />
							}
							<RouterSwitch>
								<Route path="/login"><Login /></Route>
								<Route path="/forgotpasswd"><Forgotpassword /></Route>
								<Route path="/resetpasswd"><Resetpassword /></Route>
								<Route path="/register"><Register chainid={selectedchain} handleChainChange={handleChainChange}/></Route>
								<Route exact path="/exam"><Exam /></Route>
								<Route exact path="/katsy"><KatsyDemo /></Route>
								<Route path="/exam/:organisationid"><Exam /></Route>
								<PrivateRoute path="/sustainabledevelopment"><SustainableDevelopment chainid={selectedchain} /></PrivateRoute>
								<PrivateRoute path="/plan"><Plan chainid={selectedchain} /></PrivateRoute>
								<PrivateRoute path="/organisation"><Organisation chainid={selectedchain}/></PrivateRoute>
								<PrivateRoute path="/locations"><Locations chainid={selectedchain} /></PrivateRoute>
								<PrivateRoute path="/auditions"><Auditions chainid={selectedchain} /></PrivateRoute>
								<PrivateRoute path="/user"><User userid={userData.uid}/></PrivateRoute>
								<PrivateRoute path="/dashboard"><Dashboard chainid={selectedchain}/></PrivateRoute>
								<PrivateRoute exact path="/"><Dashboard chainid={selectedchain}/></PrivateRoute>
							</RouterSwitch>

							<div className={classes.copyright}>
								Copyright &copy; Savon Koulutus Oy, <a href="https://www.luomuravintola.fi/tietosuoja" target="blank">Tietosuojaseloste</a>
							</div>
						</div>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</Router>
		</AuthContext.Provider>
	);
}
import React, {useState} from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useAuth } from './Auth/Auth'
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ContactSupportOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: 120
	},
	topGrid: {
		marginBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
	bottomGrid: {
		paddingBottom: theme.spacing(2),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(4),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(4	)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	}
}));


export default function Forgotpassword(props) {

	const classes = useStyles();
	const authData = {}
	const [isValid, setIsValid] = useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isEmailfailed, setEmailfailed] = useState(false);

	const handleTextInputChange = (event) => {
		authData[event.target.id] = event.target.value
    };
	
	const handleForgot = (event) => {
		event.preventDefault()
		if (authData.email) {
			axios.post(plconfig.APIBase + 'apps/auth.groovy?action=passwd', {
				email: authData.email,
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsValid(true)
					setEmailfailed(false)
					setIsSaveOk(true)
				} else {
					setIsValid(false)
					setEmailfailed(true)
					setIsSaveOk(false)
				}
			})
			.catch(function(error) {
				console.log(error)
				setIsValid(false)
				setEmailfailed(true)
				setIsSaveOk(false)
			});

		} else {
			setIsValid(false)
			setIsSaveOk(false)
		}
	}

	return (
		<Container maxWidth="sm" className={classes.container}>

			<h4 className={classes.PaperHeader}>Unohtuiko salasanasi</h4>

			<Paper className={classes.paper}>

				<Typography className={classes.maintText}>Syötä palvelussa käyttämäsi sähköpostiosoite alla olevaan kenttään.</Typography>

				{isSaveOk &&
				<Alert severity="success" className={classes.commonAlert}>
					<AlertTitle>Uusi salasana</AlertTitle>
					Antamaasi sähköpostisosoitteeseen on lähetetty viesti jossa on ohjeet salasanan vaihtamiseen.
				</Alert>
				}

				<form onSubmit={handleForgot}>

					<TextField
						id="email"
						name="email"
						label="Sähköpostiosoite"
						variant="outlined"
						error={!isValid && authData.email != ""}
						className={classes.formInput}
						type="email"
						value={authData.email}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>	
	
					<hr/>
	
					<Grid container className={classes.bottomGrid}>
						<Grid item xs={12}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Lähetä</Button>
						</Grid>
					</Grid>	
					
					{isEmailfailed &&
						<Typography className={classes.maintText}>Sähköpostiosoitetta ei löytynyt. <a href="https://www.luomuravintola.fi/yhteys" target="_blank">Ota yhteyttä</a> järjestelmän toimittajaan salasanan palauttamiseksi.</Typography>
					}

				</form>

			</Paper>

		</Container>
	);

}

import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useAuth } from './Auth/Auth'
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ContactSupportOutlined } from '@material-ui/icons';
import LinkButton from './Components/LinkButton'


const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: 120
	},
	topGrid: {
		marginBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
	bottomGrid: {
		paddingBottom: theme.spacing(2),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(4),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(4	)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
}));


export default function Login(props) {

	const emailField = useRef(null)
	const passwordField = useRef(null)

	const classes = useStyles();
	const authData = {}
	const [isValid, setIsValid] = useState(true);
	const [isLoginfailed, setLoginfailed] = useState(false);

	const referer = '/dashboard';
	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();
	
	const handleTextInputChange = (event) => {
		authData[event.target.id] = event.target.value
    };
	
	const handleLogin = (event) => {
		event.preventDefault()

		// check if autofilled fields
		if (!authData.username && emailField.current) {
			authData.username = emailField.current.value
		}
		if (!authData.passwd && passwordField.current) {
			authData.passwd = passwordField.current.value
		}
		
		if (authData.username && authData.passwd) {
			axios.post(plconfig.APIBase + 'apps/auth.groovy', {
				username: authData.username,
				passwd: authData.passwd
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsValid(true)
					setLoginfailed(false)
					setIsAuthenticated(true)
					setUserData(response.data.user)
					let sessionStorage = window.sessionStorage;
					sessionStorage.setItem('aat', response.data.authtokens);
				} else {
					setIsValid(false)
					setLoginfailed(true)
				}
			})
			.catch(function(error) {
				setIsValid(false)
				setLoginfailed(true)
			});

		} else {
			setIsValid(false)
		}
	}

	if (isAuthenticated) {
		return <Redirect to={referer} />;
	}

	return (
		<Container maxWidth="sm" className={classes.container}>

			<h4 className={classes.PaperHeader}>Kirjaudu</h4>

			<Paper className={classes.paper}>

				<Typography className={classes.maintText}>Syötä käyttäjätunnus sekä salasana alla oleviin kenttiin</Typography>

				<form onSubmit={handleLogin}>

					<TextField
						id="username"
						name="username"
						label="Käyttäjätunnus"
						variant="outlined"
						error={!isValid && authData.username != ""}
						className={classes.formInput}
						type="text"
						value={authData.username}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							ref: emailField
						}}
					/>	
	
					<TextField
						id="passwd"
						name="passwd"
						label="Salasana"
						variant="outlined"
						error={!isValid && authData.passwd != ""}
						className={classes.formInput}
						type="password"
						value={authData.passwd}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							ref: passwordField
						}}
					/>		
	
					<hr/>
	
					<Grid container className={classes.bottomGrid}>
						<Grid item xs={6}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Kirjaudu</Button>
						</Grid>
						<Grid item xs={6} className={classes.bottomGridRight}>
							<LinkButton className={classes.cancelButton} to='/forgotpasswd' variant="none" disableElevation>Unohtuiko salasana?</LinkButton>
						</Grid>
					</Grid>	
					
					{isLoginfailed &&
						<Typography className={classes.maintText}>Käyttäjätunnusta ei löytynyt tai salasana virheellinen.</Typography>
					}

				</form>

			</Paper>

		</Container>
	);

}

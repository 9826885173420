import React, {useEffect} from 'react';
import axios from 'axios';
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import { DatePicker } from "@material-ui/pickers";
import FileInput from './Components/FileInput';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from './Components/LinkButton'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Loader } from "@googlemaps/js-api-loader"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
	},
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	addNewButton: {
		marginBottom: theme.spacing(2),
    },
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	productHeader: {
		marginBottom: theme.spacing(2),
		fontWeight: 600
	},
	productForm: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	}
}));

function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	} else {
		return ""
	}
}


export default function Locations(props) {

	const classes = useStyles();

	const [isFetchingData, setIsFetchingData] = React.useState(true);

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [selectedLocationData, setSelectedLocationData] = React.useState({
		id: ''
	});
	const [locationData, setLocationData] = React.useState({
		locations: []
	})

	const [filteredLocations, setFilteredLocations] = React.useState([]);
	const [filter, setFilter] = React.useState('');
	const handleFilter = (event) => {
		setFilter(event.target.value)
		filterLocations()
    };
	
	const handleTextInputChange = (event) => {
		setSelectedLocationData({
			...selectedLocationData,
			[event.target.id]: event.target.value
		});
	};
	const handleMunipalicityInputChange = (event, newValue) => {
		//console.log(newValue)
		let value = newValue;
		if (!value) {
			value = event.target.value
		}
		//console.log(value)
		setSelectedLocationData({
			...selectedLocationData,
			["MUNIPALICITY"]: value
		});
	}
	const handleSelectChange = (event) => {
		setSelectedLocationData({
			...selectedLocationData,
			[event.target.name]: event.target.value
		});
    };
    const handleSwitchChange = (event) => {
        setSelectedLocationData({
			...selectedLocationData,
			[event.target.id]: event.target.value
		});
	};
	const handlePublicPrivateChange = (event) => {
		setSelectedLocationData({
			...selectedLocationData,
			[event.target.id]: event.target.value
		});
	};

	const handleAgreewwwChange = (event) => {
		let agree = false;
		if (event.target.value=='true') {
			agree = true;
		}
		setSelectedLocationData({
			...selectedLocationData,
			[event.target.id]: agree
		});
	};

	

	let geocoder;
	const loader = new Loader({
		apiKey: "AIzaSyCsYPIJnlxqfxa5rQ-TFm5Xrutd3ntudnk",
		version: "weekly"
	});
	loader.load().then(() => {
		geocoder = new google.maps.Geocoder();

		// add autocomplete for address
		var defaultBounds = new google.maps.LatLngBounds(
		new google.maps.LatLng(-33.8902, 151.1759),
		new google.maps.LatLng(-33.8474, 151.2631));

	});

	const getGeoLocation = (event, reason) => {
		if (geocoder) {
			let address = selectedLocationData.ADDRESS + ", " + selectedLocationData.ZIP + " " + selectedLocationData.CITY
			//console.log(address)

			geocoder.geocode( { 'address': address}, function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					//console.log('got location!')
					let newlocation = {}
					newlocation.lat = results[0].geometry.location.lat();
					newlocation.lng = results[0].geometry.location.lng();
					setSelectedLocationData({
						...selectedLocationData,
						['location']: newlocation
					});

				} else {
					//console.log('error getting location')
				}
			});
		}
	}

	useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/location.groovy?action=info', {
				id: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					clearForm()
					setIsFetchingData(false)
					setLocationData(response.data)
					filterLocations(response.data.locations);
				} else {
					setIsFetchingData(true)
				}
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});
		}
	}, [props.chainid])

	const [munipalicities, setMunipalicities] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/munipalicity.groovy?action=simple', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setMunipalicities(response.data.munipalicities)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])

	const filterLocations = (locations) => {
		let filteredList = []
		let loclist = [...locationData.locations];
		if (locations) {
			loclist = locations
		}
		loclist.map((location) => {
			if (location.NAME.toLowerCase().indexOf(filter.toLowerCase())>-1 || filter==='') {
				filteredList.push(location)
			}
		})
		setFilteredLocations(filteredList)

		//console.log(filteredList)
	}

	const locations = filteredLocations.map((location) =>
		<ListItem key={location.id} button selected={selectedLocationData.id === location.id} onClick={(e) => {selectLocation(location)}}>
			<ListItemText primary={location.NAME} secondary={location.ADDRESS + ', ' + location.ZIP + ' ' + capitalizeFirstLetter(location.CITY)} />
			<ListItemSecondaryAction>
				<IconButton edge="end" aria-label="delete" onClick={(e) => {deleteLocation(location)}}><DeleteIcon style={{ color: '#fff' }}/></IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);

	const clearForm = () => {
		setSelectedLocationData({id: ''})
		clearValidation(true)
	}

	const clearValidation = () => {
		setIsSaveOk(false)
		setIsError(false)
		setIsValid(true)
	}

	const selectLocation = (location) => {
		clearForm()
		setSelectedLocationData(location)
		clearValidation();
		//console.log(location)
	};



	const addLocation = () => {
		let ld = locationData;
		if (ld.locations || (ld.locations[0] && ld.locations[0].NAME!=="Uusi toimipaikka")) {
			let location = {
				ADDRESS: "",
				AGREEWWWPUBLISH: true,
				CANPUBLISH: false,
				CITY: "",
				CONTACTPERSON: "",
				DESCRIPTION: "",
				EMAIL: "",
				INDUSTRY: "",
				MEALPERYEAR: 0,
				MUNIPALICITY: "",
				NAME: "Uusi toimipaikka",
				OPERATINGSECTOR: "public",
				PERSONNEL: 0,
				PROFILEPIC: null,
				TYPE: "",
				WWW: "",
				ZIP: "",
				id: "new",
				CHAINID: props.chainid
			}
			setSelectedLocationData(location)
			//ld.locations.unshift(location)
			//setLocationData(ld)
			//filterLocations(ld.locations)
		}
	};

	const updateSelectedLocation = () => {
		let found = false;
		let loclist = [...locationData.locations]
		if (loclist) {
			for (const location in loclist) {
				if (selectedLocationData.id==loclist[location].id) {
					loclist[location] = selectedLocationData
					found = true;
				}
			}
		}
		// if it's new location
		if (!found) {
			loclist.unshift(selectedLocationData);
		}

		locationData.locations = loclist;
		setLocationData(locationData);
		filterLocations(locationData.locations)
	}

	const selectedLocationToList = (location) => {
		let found = false;
		let loclist = [...locationData.locations]
		// if it's new location
		loclist.unshift(location);

		locationData.locations = loclist;
		setLocationData(locationData);
		filterLocations(locationData.locations)
	}

	const saveLocation = (event) => {
		event.preventDefault()
		//console.log(selectedLocationData)

		var isnew = false;
		if (selectedLocationData.id=="new") {
			isnew = true;
		}

		let isFormValid = true;

		if (selectedLocationData.NAME=="" ||
			selectedLocationData.ZIP=="" || 
			selectedLocationData.CITY=="" || 
			selectedLocationData.MUNICIPALITY=="" || 
			selectedLocationData.ADDRESS=="" || 
			selectedLocationData.CONTACTPERSON=="" || 
			selectedLocationData.EMAIL=="" || 
			selectedLocationData.INDUSTRY=="" || 
			selectedLocationData.OPERATINGSECTOR=="" || 
			selectedLocationData.TYPE=="" || 
			selectedLocationData.PERSONNEL=="" || 
			selectedLocationData.MEALPERYEAR=="") {
				isFormValid = false;
		}

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (isFormValid) {
			axios.post(plconfig.APIBase + 'apps/location.groovy?action=save', selectedLocationData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)

					setSelectedLocationData(response.data.location)

					// put selected to list
					if (isnew) {
						selectedLocationToList(response.data.location);
					} else {
						updateSelectedLocation()
					}
					
				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	};

	const deleteLocation = (location) => {
		let found = -1;
		let loclist = [...locationData.locations];
		if (loclist) {
			for (const loc in loclist) {
				if (location.id==loclist[loc].id) {
					found = loc;
				}
			}
		}
		// found it so remove
		if (found>-1) {
			if (confirm("Haluatko varmasti poistaa toimipaikan '" + location.NAME + "'?")) {

				axios.post(plconfig.APIBase + 'apps/location.groovy?action=delete', location, { headers: { "Content-Type": "text/plain" } })
				.then(function (response) {
					if (response.data.success) {
						loclist.splice(found, 1);
						locationData.locations = loclist;
						setLocationData(locationData);
						filterLocations(loclist)
						clearForm()
					}
				})
				.catch(function(error) {
				});
					
			}
		}

	};

	const uploadFile = (file) => {
		const data = new FormData() 
        data.append('file', file)
		data.append('id', selectedLocationData.id);
		axios.post(plconfig.APIBase + 'apps/location.groovy?action=upload', data)
		.then(function (response) {
			if (response.data.success) {
				let loc = response.data.location;
				if (loc) {
					setSelectedLocationData({
						...selectedLocationData,
						["PROFILEPIC"]: loc.PROFILEPIC
					})

					let loclist = [...locationData.locations];
					loclist.map((location) => {
						if (location.id==loc.id) {
							location.PROFILEPIC = loc.PROFILEPIC
						}
					})
					console.log(loclist)
					setLocationData({
						...locationData,
						locations: loclist
					})
				}
			}
		})
		.catch(function(error) {
		});
	};

	const fixSlash = (url) => {
		let newurl = url;
		if (url.indexOf("/")>-1) {
			newurl = url.substring(0, url.length-1);
		}
		return newurl
	};

	return (
		<Container maxWidth="xl" className={classes.container}>

			<h4 className={classes.PaperHeader}>Toimipaikat</h4>

			<Paper className={classes.paper}>

				<Typography className={classes.maintText}>Täytä tähän kaikki yrityksen/organisaation toimipaikat, joissa on käytössä samat luomutuotteet, myös jakelukeittiöt. Jokaiselle toimipaikalle voi määritellä oman yhteyshenkilön. </Typography>

				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography className={classes.productHeader}>Toimipaikat</Typography>

						<Button variant="outlined" color="primary" className={classes.addNewButton} onClick={addLocation}>Lisää toimipaikka</Button>

						<TextField
							label="Hae toimipaikkoja"
							className={classes.formInput}
							type="text"
							value={filter}
							fullWidth
							onChange={handleFilter}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<List component="nav" aria-label="Toimipaikat" dense={false}>
							{locations}
						</List>

					</Grid>
					<Grid item xs={8}>

					
					{selectedLocationData.id!=='' &&

						<div className={classes.productForm}>

							{isSaveOk &&
							<Alert severity="success" className={classes.commonAlert}>
								<AlertTitle>Tallennettu</AlertTitle>
								Toimipaikan tiedot on nyt tallennettu
							</Alert>
							}

							{isError &&
							<Alert severity="error" className={classes.commonAlert}>
								<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
								Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
							</Alert>
							}

							<form onSubmit={saveLocation}>

								<h4 className={classes.mainHeader}>Perustiedot</h4>

								<TextField
									id="NAME"
									label="Nimi"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={selectedLocationData.NAME}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && (selectedLocationData.NAME=="" || selectedLocationData.NAME==null)}
								/>

								<TextField
									id="DESCRIPTION"
									label="Kuvaus"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={selectedLocationData.DESCRIPTION}
									fullWidth
									multiline
									rows="3"
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>

								{(selectedLocationData.id!=='new') &&
									<FormControl component="fieldset" fullWidth>
										<FormLabel component="legend">Toimipaikan logo</FormLabel>
										<FileInput fileHandler={uploadFile} btntext="Lataa logo"/>
										{selectedLocationData.PROFILEPIC &&
											<div>
												<img style={{marginTop: 5,maxWidth: 160, height: "auto"}} src={fixSlash(plconfig.APIBase)+selectedLocationData.PROFILEPIC } />
											</div>
										}
										<FormHelperText>Lataa logo png- tai jpg-muodossa. Koko max 3 mt.</FormHelperText>
									</FormControl>
								}

								<TextField
									id="ADDRESS"
									label="Lähiosoite *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={selectedLocationData.ADDRESS}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && (selectedLocationData.ADDRESS=="" || selectedLocationData.ADDRESS==null)}
								/>

								<Grid container spacing={2}>
									<Grid item xs={2}>
										<TextField
											id="ZIP"
											label="Postinumero *"
											variant="outlined"
											className={classes.formInput}
											type="text"
											value={selectedLocationData.ZIP}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											error={!isValid && (selectedLocationData.ZIP=="" || selectedLocationData.ZIP==null)}
										/>
									</Grid>
									<Grid item xs={10}>
										<TextField
											id="CITY"
											label="Postitoimipaikka *"
											variant="outlined"
											className={classes.formInput}
											type="text"
											value={selectedLocationData.CITY}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											error={!isValid && (selectedLocationData.CITY=="" || selectedLocationData.CITY==null)}
										/>
									</Grid>
								</Grid>	

								<Autocomplete
									id="MUNIPALICITY-combo"
									options={munipalicities}
									getOptionLabel={(option) => option}
									autoComplete
									autoSelect={true}
									value={selectedLocationData.MUNIPALICITY} 
									//onClose={getGeoLocation}
									onChange={handleMunipalicityInputChange} 
									renderInput={(params) => {
										params.inputProps.autoComplete = 'new-password';
										return (
											<TextField 
												{...params} 
												id="MUNIPALICITY"
												label="Sijaintikunta *" 
												variant="outlined" 
												className={classes.formInput} 
												fullWidth 
												InputLabelProps={{shrink: true}} 
												error={!isValid && (selectedLocationData.MUNIPALICITY=="" || selectedLocationData.MUNIPALICITY==null)}
												helperText="Sijaintikunta, jonka perusteella toimipaikka löytyy ravintolahausta"
											/>
										);
									}}
								/>

								{selectedLocationData.location &&
								<FormHelperText><CheckCircleOutlineIcon  /> Sijainti paikannettu ravintolahakua varten</FormHelperText>
								}

								<hr/>
								<h4 className={classes.mainHeader}>Yhteystiedot</h4>

								<TextField
									id="CONTACTPERSON"
									label="Yhteyshenkilö *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={selectedLocationData.CONTACTPERSON}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && (selectedLocationData.CONTACTPERSON=="" || selectedLocationData.CONTACTPERSON==null)}
								/>

								<TextField
									id="WWW"
									label="Internetsivut"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={selectedLocationData.WWW}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>

								<TextField
									id="EMAIL"
									label="Sähköpostiosoite *"
									variant="outlined"
									className={classes.formInput}
									type="text"
									value={selectedLocationData.EMAIL}
									fullWidth
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && (selectedLocationData.EMAIL=="" || selectedLocationData.EMAIL==null)}
								/>

								<hr />
								<h4 className={classes.mainHeader}>Volyymi</h4>

								<Grid container spacing={2}>
									<Grid item xs={6}>
										<TextField
											id="PERSONNEL"
											label="Henkilökunta (kokonaislukuna) *"
											variant="outlined"
											className={classes.formInput}
											type="number"
											value={selectedLocationData.PERSONNEL}
											InputProps={{
												endAdornment: <InputAdornment position="end">kpl</InputAdornment>,
											}}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											error={!isValid && (selectedLocationData.PERSONNEL=="" || selectedLocationData.PERSONNEL==null)}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											id="MEALPERYEAR"
											label="Aterioita vuodessa *"
											variant="outlined"
											className={classes.formInput}
											type="number"
											value={selectedLocationData.MEALPERYEAR}
											InputProps={{
												endAdornment: <InputAdornment position="end">kpl</InputAdornment>,
											}}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											error={!isValid && (selectedLocationData.MEALPERYEAR=="" || selectedLocationData.MEALPERYEAR==null)}
										/>
									</Grid>
								</Grid>	
								<FormHelperText>Merkitse tähän, kuinka monta ateriaa/asiakastapahtumaa tarjotaan vuosittain. Jos siihen kuuluu useita keittiöitä, ilmoita tässä niiden yhteenlaskettu ateriamäärä. Merkitse henkilömäärä kokonaislukuna.</FormHelperText>

								<hr />
								<h4 className={classes.mainHeader}>Toiminta</h4>
								
								<FormControl component="fieldset" fullWidth>
									<FormLabel component="legend">Ravintola/kahvila/ruokailupaikka on kaikille avoin *</FormLabel>
									<RadioGroup aria-label="open" id="OPERATINGSECTOR" value={selectedLocationData.OPERATINGSECTOR} onChange={handlePublicPrivateChange} error={!isValid && (selectedLocationData.OPERATINGSECTOR=="" || selectedLocationData.OPERATINGSECTOR==null)}>
										<FormControlLabel control={<Radio id="OPERATINGSECTOR" checked={selectedLocationData.OPERATINGSECTOR === 'private'} value="private"/>} label="Kyllä" />
										<FormControlLabel control={<Radio id="OPERATINGSECTOR" checked={selectedLocationData.OPERATINGSECTOR === 'public'} value="public"/>} label="Ei" />
									</RadioGroup>
								</FormControl>

								<FormControl variant="outlined" fullWidth shrink className={classes.formInput}>
									<InputLabel id="TYPE-select-label">Toimipaikan tyyppi *</InputLabel>
									<Select
										labelId="TYPE-select-label"
										id="TYPE"
										name="TYPE"
										value={selectedLocationData.TYPE}
										onChange={handleSelectChange}
										error={!isValid && (selectedLocationData.TYPE=="" || selectedLocationData.TYPE==null)}
										>
										<MenuItem key={1} value={'valmistus/jakelukeittiö'}>valmistus/jakelukeittiö</MenuItem>
										<MenuItem key={2} value={'keskuskeittiö'}>keskuskeittiö</MenuItem>
										<MenuItem key={3} value={'valmistuskeittiö'}>valmistuskeittiö</MenuItem>
										<MenuItem key={4} value={'kuumennus/palvelukeittiö'}>kuumennus/palvelukeittiö</MenuItem>
										<MenuItem key={5} value={'jakelukeittiö'}>jakelukeittiö</MenuItem>
									</Select>
								</FormControl>

								<FormControl variant="outlined" fullWidth shrink className={classes.formInput}>
									<InputLabel id="INDUSTRY-select-label" >Toimiala *</InputLabel>
									<Select
										labelId="INDUSTRY-select-label"
										id="INDUSTRY"
										name="INDUSTRY"
										value={selectedLocationData.INDUSTRY}
										onChange={handleSelectChange}
										error={!isValid && (selectedLocationData.INDUSTRY=="" || selectedLocationData.INDUSTRY==null)}
										>
										<MenuItem key={1} value={'peruskoulu/lukio'}>peruskoulu/lukio</MenuItem>
										<MenuItem key={2} value={'muu oppilaitos'}>muu oppilaitos</MenuItem>
										<MenuItem key={3} value={'päiväkoti'}>päiväkoti</MenuItem>
										<MenuItem key={4} value={'sairaala/terveyskeskus'}>sairaala/terveyskeskus</MenuItem>
										<MenuItem key={5} value={'hoitokoti/palvelukoti'}>hoitokoti/palvelukoti</MenuItem>
										<MenuItem key={6} value={'henkilöstö-/lounasravintola'}>lounasravintola</MenuItem>
										<MenuItem key={7} value={'kahvila'}>kahvila</MenuItem>
										<MenuItem key={8} value={'ravintola'}>ravintola</MenuItem>
										<MenuItem key={9} value={'pikaruokaravintola'}>pikaruokaravintola</MenuItem>
										<MenuItem key={10} value={'hotelli'}>hotelli/motelli</MenuItem>
										<MenuItem key={11} value={'maaseutumatkailu'}>matkailu</MenuItem>
										<MenuItem key={12} value={'kurssikeskus/leirikeskus'}>kurssikeskus/leirikeskus</MenuItem>
									</Select>
								</FormControl>

								<FormControl component="fieldset" fullWidth>
									<FormLabel component="legend">Julkaise toimipaikan tiedot luomuravintola.fi sivustolla *</FormLabel>
									<RadioGroup aria-label="open" id="AGREEWWWPUBLISH" value={selectedLocationData.AGREEWWWPUBLISH} onChange={handleAgreewwwChange} error={!isValid && (selectedLocationData.AGREEWWWPUBLISH=="" || selectedLocationData.AGREEWWWPUBLISH==null)}>
										<FormControlLabel control={<Radio id="AGREEWWWPUBLISH" checked={selectedLocationData.AGREEWWWPUBLISH} value="true"/>} label="Kyllä" />
										<FormControlLabel control={<Radio id="AGREEWWWPUBLISH" checked={!selectedLocationData.AGREEWWWPUBLISH} value="false"/>} label="Ei" />
									</RadioGroup>
								</FormControl>

								<hr/>

								<Grid container className={classes.bottomGrid}>
									<Grid item xs={8}>
										<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Tallenna</Button>
									</Grid>
									<Grid item xs={4} className={classes.bottomGridRight}>
										<Button onClick={() => selectLocation({})} className={classes.cancelButton}>Peruuta</Button>
									</Grid>
								</Grid>	

							</form>

						</div>

					}

					</Grid>
				</Grid>	

					
				

				
			</Paper>
			

		</Container>
	);
}

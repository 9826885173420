import React, { useState, useEffect } from "react";
import { useAuth } from './Auth/Auth'
import { makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinkButton from './Components/LinkButton'
import "moment/locale/fi";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		marginBottom: 80
	},
	chainselectbar: {
		position: 'absolute',
		right: 0,
		width: '30%',
		backgroundColor: "#38b559",
		borderBottomLeftRadius: 20,
		color: "#fff",
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	chainselecttitle: {
		marginTop: 3,
	},
	chainselectbutton: {
	},
	gridLeft: {
		textAlign: 'left'
	},
	gridRight: {
		textAlign: 'right'
	},
	gridCenter: {
		textAlign: 'center'
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 1200,
		minWidth: 800,
		minHeight: 500,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	},
	chainList: {
		display: "block",
		width: "100%",
		marginBottom: theme.spacing(2),
		minHeight: 400,
		maxHeight: 400,
		overflowX: 'hidden',
		overflowY: 'auto',
		cursor: 'pointer'
	},
	chainHeaderRow: {
		marginBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		fontSize: 10,
		fontWeight: 700
	},
	chainListItem: {
		padding: theme.spacing(1),
		alignContent: 'center',
		'&:hover': {
			backgroundColor: "#38b559",
		},
		fontSize: 14
	},
	chainListItemSelected: {
		padding: theme.spacing(1),
		backgroundColor: "#38b559",
		alignContent: 'center',
		'&:hover': {
			backgroundColor: "#38b559",
		},
		fontSize: 14
	},
}));

const userdefault = {
	displayName : '',
	email : '',
	uid : '',
	chains: [],
	selectedChain: {
		id: null,
		name: ''
	}
}

export default function Chainchooser(props) {
	const classes = useStyles();

	//const existingTokens = JSON.parse(localStorage.getItem("tokens"));
	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();

	const [selectedchain, setSelectedchain] = useState('');

	const [filteredChains, setFilteredChains] = React.useState([]);
	const handleFilter = (event) => {
		filterchains(event.target.value)
    };	
	const filterchains = (strfilter) => {
		let filteredList = []
		let chainlist = userData.chains;
		if (!strfilter) {
			filteredList = chainlist
		} else {
			chainlist.map((chain) => {
				if (chain.name.toLowerCase().indexOf(strfilter.toLowerCase())>-1) {
					filteredList.push(chain)
				}
			})
		}
		setFilteredChains(filteredList)
	}

	const handleChainChange = (chain) => {
		props.handleChainChange(chain)
		handleChangeChainModalClose();
	};

	
	const [changeChainModalOpen, setChangeChainModalOpen] = React.useState(false);
	const handleChangeChainModalOpen = () => {
		filterchains()
		setChangeChainModalOpen(true)
	};
	const handleChangeChainModalClose = () => {
		filterchains()
		setChangeChainModalOpen(false);
	};

	return (
		<div className={classes.chainselectbar}>
			{(userData && userData.chains) &&
				<React.Fragment>

					<Grid container spacing={0}>
						<Grid item xs={10}>
							<div className={classes.chainselecttitle}>Valittu organisaatio: <strong>{userData.selectedChain.name}</strong></div>
						</Grid>
						<Grid item xs={2} className={classes.gridRight}>
							<Button className={classes.chainselectbutton} color="inherit" size="small" onClick={handleChangeChainModalOpen}>Vaihda</Button>
						</Grid>
					</Grid>	

					<Modal open={changeChainModalOpen} onClose={handleChangeChainModalClose}>

						<div className={classes.modalPaper}>

						<Grid container spacing={0}>
							<Grid item xs={8}>
								<Typography className={classes.productHeader}>Organisaatiot</Typography>
							</Grid>
							<Grid item xs={4} className={classes.gridRight}>
								<LinkButton to='/register' onClick={handleChangeChainModalClose} variant="outlined" color="primary" className={classes.menubutton2} startIcon={<LibraryAddIcon />}>Lisää uusi</LinkButton>
							</Grid>
						</Grid>	


						<TextField
							label="Hae organisaatioita"
							className={classes.formInput}
							type="text"
							fullWidth
							onChange={handleFilter}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<Grid container spacing={2} className={classes.chainHeaderRow}>
							{(userData.aadms)
							? (
								<>
									<Grid item xs={7}>Nimi</Grid>
									<Grid item xs={1} className={classes.gridLeft}>Luomu-<br/>suunnitelma</Grid>
									<Grid item xs={1} className={classes.gridCenter}>Audi-<br/>tointi</Grid>
									<Grid item xs={1} className={classes.gridLeft}>Muistiin-<br/>panot</Grid>
									<Grid item xs={2} className={classes.gridCenter}>Portaalla</Grid>
								</>
							) : (
								<>
									<Grid item xs={7}>Nimi</Grid>
									<Grid item xs={5} className={classes.gridRight}>Portaalla</Grid>
								</>
							)
							}
						</Grid>	
						
						<div className={classes.chainList}>
							{filteredChains.map((chain) =>
								<Grid container className={(props.selectedchain === chain.id) ? classes.chainListItemSelected : classes.chainListItem} spacing={2} key={chain.id} onClick={(e) => {handleChainChange(chain)}}>
									{(userData.aadms)
									? (
										<>
											<Grid item xs={7}>{chain.name}</Grid>
											<Grid item xs={1} className={classes.gridCenter}>
												{(userData.aadms && chain.sendagreed) &&
													<CheckIcon style={{ color: '#333' }}/>
												}
											</Grid>
											<Grid item xs={1} className={classes.gridCenter}>
												{(userData.aadms && chain.auditwait) &&
													<CheckIcon style={{ color: '#333' }}/>
												}
											</Grid>
											<Grid item xs={1} className={classes.gridCenter}>
												{(userData.aadms && chain.adminnotes) &&
													<CheckIcon style={{ color: '#333' }}/>
												}
											</Grid>
											<Grid item xs={2} className={classes.gridRight}>
												{(userData.aadms && chain.stepagreed==="0")
													? <Chip label={"Uusi"} color="primary" icon={<NewReleasesIcon style={{ color: '#fff' }}/>} />
													: <Chip label={"Portaalla " + chain.stepagreed} />
												}
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={9}>{chain.name}</Grid>
											<Grid item xs={3} className={classes.gridRight}>
												<Chip label={"Portaalla " + chain.stepagreed} />
											</Grid>
										</>
										
									)}
									
								</Grid>	
							)}
						</div>
						
						{/* 
						<List className={classes.chainList} component="nav" aria-label="Organisaatiot" dense={false}>
							{filteredChains.map((chain) =>
								<ListItem key={chain.id} button selected={props.selectedchain === chain.id} onClick={(e) => {handleChainChange(chain)}}>
									<ListItemText primary={chain.name} secondary={chain.additional} />
									{userData.aadms && 
									<>
									<ListItemSecondaryAction>
										{chain.stepagreed==="0"
											? <Chip label={"Uusi"} color="primary" icon={<NewReleasesIcon style={{ color: '#fff' }}/>} />
											: <Chip label={"Portaalla " + chain.stepagreed} />
										}
									</ListItemSecondaryAction>
									<ListItemSecondaryAction>
									{chain.stepagreed==="0"
										? <Chip label={"Uusi"} color="primary" icon={<NewReleasesIcon style={{ color: '#fff' }}/>} />
										: <Chip label={"Portaalla " + chain.stepagreed} />
									}
								</ListItemSecondaryAction>
								</>
									}
								</ListItem>
							)}
						</List>
						*/}

						</div>
					</Modal>
					
				</React.Fragment>
			}
		</div>
	);
}
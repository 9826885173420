import React, {useEffect} from 'react';
import {Prompt} from "react-router-dom";
import axios from 'axios';
import moment from "moment";
import "moment/locale/fi";
import { useAuth } from './Auth/Auth'

import plconfig from './config.js'
import ImportChooser from './Importer/ImportChooser'

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileInput from './Components/FileInput';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from './Components/LinkButton'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import { ContactSupportOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	bottombutton1: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(2)
	},
	bottombutton2: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2),
		color: '#fff'
	},
	bottombutton3: {
		marginTop: theme.spacing(3),
		marginRight: theme.spacing(3)
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	linkcursor: {
		cursor: 'pointer'
	},
	tabItem: {
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
		borderColor: theme.palette.primary.main,
		'&:focus': {
			outline: 0,
		},
	},
	tabItemError: {
		backgroundColor: "#fdecea",
		color: "#f44336",
		borderColor: "#fdecea",
		'&:focus': {
			outline: 0,
		},
	},
	tabItemError2: {
		color: "#f44336",
	},
	tabPanel: {
		padding: theme.spacing(3),
		minHeight: 300
	},
	productColumn1: {
		backgroundColor: "#efeeee",
	},
	productColumn2: {
		backgroundColor: "#f7f7f7",
	},
	productColumn3: {
		backgroundColor: "#ffffff",
	},
	formInput: {
		marginBottom: theme.spacing(3),
		width: '100%'
	},
	formInputSmall: {
		marginBottom: theme.spacing(3),
		width: '33%'
	},
	productHeader: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(1),
		fontWeight: 600
	},
	productHeader2: {
		marginTop: theme.spacing(0),
		marginLeft: theme.spacing(0),
		marginRight: theme.spacing(0),
		marginBottom: theme.spacing(3),
		fontWeight: 600
	},
	productForm: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	extraInfoText: {
		paddingTop: theme.spacing(1),
	},
	extraInfoText2: {
		paddingBottom: theme.spacing(2),
	},
	commonAlert: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	infogrid: {
		marginBottom: theme.spacing(2)
	},
	infogridright: {
		marginTop: theme.spacing(5),
		paddingLeft: theme.spacing(3),
		textAlign: 'right'
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 800,
		height: 350,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	modalCloseButton: {
		position: 'absolute',
		top: 5,
		right: 5,
	},
	HelpIconButton: {
		padding: 0,
	},
	deleteallbutton: {
		color: "#f44336",
		borderColor: "#f44336",
		marginLeft: theme.spacing(3)
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
	  >
		{value === index && <Box>{children}</Box>}
	  </Typography>
	);
}

function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string
	}
	
}

export default function Plan(props) {

	const classes = useStyles();

	const {isAuthenticated, setIsAuthenticated, userData, setUserData} = useAuth();

	const [planSaved, setPlanSaved] = React.useState(true);

	const [selectedTab, setSelectedTab] = React.useState(0);
	const [selectedProductGroup, setSelectedProductGroup] = React.useState("");
	const [selectedProduct, setSelectedProduct] = React.useState("");
	const [selectedWildProductGroup, setSelectedWildProductGroup] = React.useState("");
	const [selectedWildProduct, setSelectedWildProduct] = React.useState("");

	const [selectedProducer, setSelectedProducer] = React.useState({
		PRODUCERIDENTIFIER: '',
		PRODUCERVALIDITYDATE: null,
		PRODUCER: ""
	});
	const [selectedOrganicGradient, setSelectedOrganicGradient] = React.useState({
		USAGE: 'allthetime',
		BIODYNAMIC: false,
		WILDPRODUCT: false,
		RATE: 0,
		NAME: '',
		TUKKUCODE: ''
	});

	const [selectedWildProductGradient, setSelectedWildProductGradient] = React.useState({
		USAGE: 'nousage',
		NAME: ''
	});

	const [selectedImportedProducts, setSelectedImportedProducts] = React.useState([]);

	let examcount = 0;

	const [isFetchingData, setIsFetchingData] = React.useState(true);

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [isPhase1Valid, setIsPhase1Valid] = React.useState(true);
	const [isPhase2Valid, setIsPhase2Valid] = React.useState(true);
	const [isPhase3Valid, setIsPhase3Valid] = React.useState(true);
	const [isPhase4Valid, setIsPhase4Valid] = React.useState(true);
	const [isPhase5Valid, setIsPhase5Valid] = React.useState(true);
	const [isPhase6Valid, setIsPhase6Valid] = React.useState(true);

	const [planData, setPlanData] = React.useState({
		PRODUCERS: [],
		CRITICAL1: [],
		CRITICAL2: [],
		CRITICAL3: [],
		CRITICAL4: [],
		CRITICAL5: [],
		CRITICAL6: [],
		APPLYFORSTEP: '',
		DECISIONMUNICIPALITY : false, 
		DECISIONORGANISATION : false
	});


	const [sertifiedHelpModalOpen, setSertifiedHelpModalOpen] = React.useState(false);
	const handleModalOpen = () => {
		setSertifiedHelpModalOpen(true);
	};
	const handleModalClose = () => {
		setSertifiedHelpModalOpen(false);
	};

	
	const [totalHelpModalOpen, setTotalHelpModalOpen] = React.useState(false);
	const handleTotalModalOpen = () => {
		setTotalHelpModalOpen(true);
	};
	const handleTotalModalClose = () => {
		setTotalHelpModalOpen(false);
	};

	// TAB CHANGED HANDLERS
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	const handleNext = (newValue) => {
		setSelectedTab(newValue);
	};


	// COMMON INPUT HANDLERS
	const handleTextInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setPlanData({
			...planData,
			[event.target.id]: event.target.value
		});
		setPlanSaved(false)
	};
	const handleSelectInputChange = (event) => {
		//console.log(event.target.name + ' : ' + event.target.value)
		setPlanData({
			...planData,
			[event.target.name]: event.target.value
		});
		setPlanSaved(false)
	};
	
	const handlePublicPrivateChange = (event) => {
		setPlanData({
			...planData,
			[event.target.id]: event.target.value
		});
		setPlanSaved(false)
	};
	const handleDateChange = (date) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setPlanData({
			...planData,
			[event.target.id]: event.target.value
		});
		setPlanSaved(false)
	};

	const handleSwitchChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)
		setPlanData({
			...planData,
			[event.target.id]: event.target.checked
		});
		setPlanSaved(false)
	};



	// ORGANICGRADIENT INPUT HANDLERS
	const handleGradientInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		//console.log(selectedOrganicGradient)

		// clean if nousage
		if (event.target.value==='nousage') {
			let og = [...planData.ORGANICINGREDIENTS];
			
			let found = false;
			let foundindex = -1;
			let tukkucode = ""

			for (const gradient in og) {
				if (og[gradient].PRODUCTID === selectedOrganicGradient.PRODUCTID) {
					//og[gradient] = selectedWildProductGradient
					og[gradient][event.target.id] = event.target.value
					found = true;
					foundindex = gradient;
					tukkucode = selectedOrganicGradient.TUKKUCODE
				}
			}
			// if found remove from list
			if (found) {
				//console.log(" - found " + tukkucode);
				// remove from array
				og.splice(foundindex, 1)
				setSelectedOrganicGradient({
					USAGE: 'nousage',
					NAME: ''
				});
				// if imported products clear import data 
				if (selectedProductGroup && (selectedProductGroup=='615fcfb6a1c99a6fb14e09c0' || selectedProductGroup=='61652555e4b013438203c9dc')) {
					//console.log(" - remove " + selectedProductGroup);
					axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=clearimporteddata', {
						chainid: props.chainid,
						planid: planData.id,
						type: tukkucode
					}, { headers: { "Content-Type": "text/plain" } })
					.then(function (response) {
						if (response.data.success) {
							setSelectedImportedProducts([])
						} else {
						}
					})
					.catch(function(error) {
					});
				}
			}

			setPlanData({
				...planData,
				["ORGANICINGREDIENTS"]: og
			});

		} else {

			setSelectedOrganicGradient({
				...selectedOrganicGradient,
				[event.target.id]: event.target.value
			});
			// update original gradient also
			let og = [...planData.ORGANICINGREDIENTS];
			let found = false;
			if (og) {
				for (const gradient in og) {
					if (og[gradient].PRODUCTID === selectedOrganicGradient.PRODUCTID) {
						//og[gradient] = selectedOrganicGradient
						og[gradient][event.target.id] = event.target.value
						found = true;
					}
				}
				// if not found add clone from selected
				if (!found) {
					const clone = JSON.parse(JSON.stringify(selectedOrganicGradient));
					clone[event.target.id] = event.target.value
					og.push(clone)
				}
			}
			setPlanData({
				...planData,
				["ORGANICINGREDIENTS"]: og
			});
			//console.log(planData)
		}

		setPlanSaved(false)
		
	};
	const handleGradientSwitchChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)
		setSelectedOrganicGradient({
			...selectedOrganicGradient,
			[event.target.id]: event.target.checked
		});
		// update original gradient also
		let og = [...planData.ORGANICINGREDIENTS]
		let found = false;
		if (og) {
			for (const gradient in og) {
				if (og[gradient].PRODUCTID === selectedOrganicGradient.PRODUCTID) {
					//og[gradient] = selectedOrganicGradient
					og[gradient][event.target.id] = event.target.checked
					found = true;
				}
			}
			// if not found add clone from selected
			if (!found) {
				const clone = JSON.parse(JSON.stringify(selectedOrganicGradient));
				clone[event.target.id] = event.target.checked
				og.push(clone)
			}
		}
		setPlanData({
			...planData,
			["ORGANICINGREDIENTS"]: og
		});
		//console.log(planData);
		setPlanSaved(false)
	};

	// CRITICAL CHECKBOX INPUT CHANGER
	const handleCriticalCheckboxChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)

		let currentcritical = planData[event.target.name];
		if (event.target.checked) {
			if (currentcritical.includes(event.target.id)) {
			} else {
				currentcritical.push(event.target.id);
			}
		} else {
			if (currentcritical.includes(event.target.id)) {
				let found = -1;
				if (currentcritical) {
					for (const item in currentcritical) {
						if (event.target.id==currentcritical[item]) {
							found = item;
						}
					}
				}
				if (found>-1) {
					currentcritical.splice(found, 1);
				}
			}
		}
		setPlanData({
			...planData,
			[event.target.name]: currentcritical
		});

		//console.log(planData[event.target.name]);

		setPlanSaved(false)

	};


	// WILDPRODUCT INPUT HANDLERS
	const handleWildProductInputChange = (event) => {
		//console.log(event.target.name + ' : ' + event.target.value)

		setSelectedWildProductGradient({
			...selectedWildProductGradient,
			[event.target.id]: event.target.value
		});

		// update original gradient also
		let og = planData.WILDPRODUCTS;
		if (!og) {
			og = []
		}

		// clean if nousage
		if (event.target.value==='nousage') {

			let found = false;
			let foundindex = -1;
			for (const gradient in og) {
				if (og[gradient].PRODUCTID === selectedWildProductGradient.PRODUCTID) {
					//og[gradient] = selectedWildProductGradient
					og[gradient][event.target.id] = event.target.value
					found = true;
					foundindex = gradient;
				}
			}
			// if found remove from list
			if (found) {
				og.splice(foundindex, 1)
				setSelectedWildProductGradient({
					USAGE: 'nousage',
					NAME: ''
				});
			}
			setPlanData({
				...planData,
				["WILDPRODUCTS"]: og
			});

		} else {

			let found = false;
			for (const gradient in og) {
				if (og[gradient].PRODUCTID === selectedWildProductGradient.PRODUCTID) {
					//og[gradient] = selectedWildProductGradient
					og[gradient][event.target.id] = event.target.value
					found = true;
				}
			}
			// if not found add clone from selected
			if (!found) {
				const clone = JSON.parse(JSON.stringify(selectedWildProductGradient));
				clone[event.target.id] = event.target.value
				og.push(clone)
				//console.log(clone)
			}
			//console.log(og);

			setPlanData({
				...planData,
				["WILDPRODUCTS"]: og
			});

		}
		

		setPlanSaved(false)
	};



	// PRODUCER INPUT HANDLERS
	const handleProducerInputChange = (event) => {
		
		let name = event.target.id
		if (!name) {
			name = event.target.name
		}
		//console.log(name + ' : ' + event.target.value)
		setSelectedProducer({
			...selectedProducer,
			[name]: event.target.value
		});

		// update producerlist 
		let pl = planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					//pl[producerindex] = selectedProducer
					pl[producerindex][name] = event.target.value
					found = true;
				}
			}
		}
		setPlanData({
			...planData,
			["PRODUCERS"]: pl
		});
		//console.log(planData)

		setPlanSaved(false)

	};

	const handleProducerNameChange = (event, newValue) => {
		let value = newValue;
		if (!value) {
			value = event.target.value
		}

		// get certfile by name
		let newcert = "";
		let newdate = null;
		producersdb.producers.map((producerItem) => {
			if (producerItem.NAME==value) {
				newcert = producerItem.CERT;
				if (producerItem.VALIDITYDATESTR) {
					newdate = moment(producerItem.VALIDITYDATESTR, "DD.MM.YYYY");
				}
			}
		});

		setSelectedProducer({
			...selectedProducer,
			PRODUCER: value,
			CERT: newcert,
			PRODUCERVALIDITYDATE: newdate
		});

		// update producerlist 
		let pl = planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					//pl[producerindex] = selectedProducer
					pl[producerindex].PRODUCER = value
					found = true;
				}
			}
		}
		setPlanData({
			...planData,
			["PRODUCERS"]: pl
		});
		//console.log(planData)

		setPlanSaved(false)
	};


	const handleProducerCheckboxChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)
		let currentgroups = selectedProducer.GROUPS;
		if (event.target.checked) {
			if (currentgroups.includes(event.target.id)) {
			} else {
				currentgroups.push(event.target.id);
			}
		} else {
			if (currentgroups.includes(event.target.id)) {
				let found = -1;
				if (currentgroups) {
					for (const group in currentgroups) {
						if (event.target.id==currentgroups[group]) {
							found = group;
						}
					}
				}
				if (found>-1) {
					currentgroups.splice(found, 1);
				}
			}
		}
		setSelectedProducer({
			...selectedProducer,
			["GROUPS"]: currentgroups
		});

		// update producerlist 
		let pl = planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					pl[producerindex] = selectedProducer
					//pl[producerindex].GROUPS = currentgroups
					found = true;
				}
			}
		}
		setPlanData({
			...planData,
			["PRODUCERS"]: pl
		});
		//console.log(planData)

		setPlanSaved(false)

	};
	const handleProducerDateChange = (date) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setSelectedProducer({
			...selectedProducer,
			PRODUCERVALIDITYDATE: date
		});
		// update producerlist 
		let pl = planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					pl[producerindex].PRODUCERVALIDITYDATE = date
					found = true;
				}
			}
		}
		setPlanData({
			...planData,
			["PRODUCERS"]: pl
		});
		//console.log(planData)
		setPlanSaved(false)
	};


	// HANDLE SAVE
	const validateForm = () => {
		let isFormValid = true;

		setIsPhase1Valid(true)
		setIsPhase2Valid(true)
		setIsPhase3Valid(true)
		setIsPhase4Valid(true)
		setIsPhase5Valid(true)
		setIsPhase6Valid(true)

		if (planData.APPLYFORSTEP=="" ||
			planData.CRITERIAFORSTEP=="" || 
			planData.DECISIONPERCENT=="") {
			setIsPhase1Valid(false);
			isFormValid = false;
		}

		if (planData.FAMILIARIZATION=="") {
			setIsPhase2Valid(false);
			isFormValid = false;
		}

		if (planData.ORGANICINGREDIENTS.length<1 ||
			planData.TOTALUSAGE=="") {
			setIsPhase3Valid(false);
			isFormValid = false;
		}

		if (planData.PRODUCERS.length<1) {
			setIsPhase5Valid(false);
			isFormValid = false;
		} else {
			for (const producerindex in planData.PRODUCERS) {
				let producerdatestr = planData.PRODUCERS[producerindex].PRODUCERVALIDITY
				let producerdate = moment(producerdatestr)
				//console.log("cc: " + producerdatestr)				
				//console.log(producerdate)
				// this date is before producers date
				if (producerdatestr=="Invalid date") {
					setIsPhase5Valid(false);
					isFormValid = false;
				} else {
					if (moment().isBefore(producerdate)) {
						setIsPhase5Valid(false);
						isFormValid = false;
					}
				}
				
				//console.log(isFormValid)
			}
		}

		if (planData.CRITICAL1.length<1 ||
			planData.CRITICAL2.length<1 ||
			planData.CRITICAL3.length<1 ||
			planData.CRITICAL4.length<1 ||
			planData.CRITICAL5.length<1 ||
			planData.CRITICAL6.length<1) {
			setIsPhase6Valid(false);
			isFormValid = false;
		}

		if (isPhase1Valid && isPhase2Valid && isPhase3Valid && isPhase4Valid && isPhase5Valid && isPhase6Valid) {
		} else {
			isFormValid = false;
		}
		return isFormValid
	}

	const validateAndSaveAndSend = () => {
		
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (validateForm()) {
			// if valid and sending to agreement then change to ready
			let sendData = {...planData}
			sendData.READY = true;
			// correct producers date
			if (sendData.PRODUCERS) {
				for (const producerindex in sendData.PRODUCERS) {
					sendData.PRODUCERS[producerindex].PRODUCERVALIDITY = moment(sendData.PRODUCERS[producerindex].PRODUCERVALIDITYDATE).format("DD.MM.YYYY")
				}
			}

			//console.log(sendData)

			axios.post(plconfig.APIBase + 'apps/plan.groovy?action=save', sendData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)
					setIsPhase1Valid(true);
					setIsPhase2Valid(true);
					setIsPhase3Valid(true);
					setIsPhase4Valid(true);
					setIsPhase5Valid(true);
					setIsPhase6Valid(true);
					setPlanSaved(true)

				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	}


	const handleSave = (event) => {
		event.preventDefault()
		//console.log(planData)

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		// correct producers date
		let sendData = {...planData}
		if (sendData.PRODUCERS) {
			for (const producerindex in sendData.PRODUCERS) {
				sendData.PRODUCERS[producerindex].PRODUCERVALIDITY = moment(sendData.PRODUCERS[producerindex].PRODUCERVALIDITYDATE).format("DD.MM.YYYY")
			}
		}

		axios.post(plconfig.APIBase + 'apps/plan.groovy?action=save', sendData, { headers: { "Content-Type": "text/plain" } })
		.then(function (response) {
			if (response.data.success) {
				setPlanData({
					...planData,
					["id"]: response.data.plan.id
				});
				setIsSaveOk(true)
				setIsError(false)
				setPlanSaved(true)
			} else {
			}
		})
		.catch(function(error) {
			setIsSaveOk(false)
			setIsError(true)
		});

	}

	const handleSetNotReady = (event) => {
		event.preventDefault()

		if (confirm("Haluatko varmasti palauttaa suunnitelman tilaan kesken?")) {

			let sendData = {...planData}

			axios.post(plconfig.APIBase + 'apps/plan.groovy?action=notready', sendData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsSaveOk(true)
					setIsError(false)
					planData.READY = false;
					setSelectedTab(1)
					setSelectedTab(0)
				} else {
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		}
		
	}

	const fixSlash = (url) => {
		let newurl = url;
		if (url.indexOf("/")>-1) {
			newurl = url.substring(0, url.length-1);
		}
		return newurl
	};
	

	// LOAD DATA ON START
	useEffect(() => {
		if (props.chainid) {

			axios.post(plconfig.APIBase + 'apps/dashboard.groovy?action=chaininfo', {
				chainid: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					let needcopy = false;
					let planid = ''

					//console.log(response.data)
					
					// if draft then use it else make copy of current and use it
					if (response.data.chain.draftplan && response.data.chain.draftplan.id) {
						//console.log('draft found')
						planid = response.data.chain.draftplan.id;
					} else {
						if (response.data.chain.currentplan && response.data.chain.currentplan.id) {
							//console.log('current found')
							planid = response.data.chain.currentplan.id;
							//console.log(planid)
							needcopy = true;
						}
					}

					if (response.data.exam) {
						examcount = response.data.exam
					}

					if (planid!=='') {
						//console.log("fetching plan " + planid)
						axios.post(plconfig.APIBase + 'apps/plan.groovy?action=info', {
							id: planid
						}, { headers: { "Content-Type": "text/plain" } })
						.then(function (response) {
							if (response.data.success) {
								setIsFetchingData(false)

								let pd = response.data.plan;
								if (pd.PRODUCERS) {
									for (const producerindex in pd.PRODUCERS) {
										pd.PRODUCERS[producerindex].PRODUCERVALIDITYDATE = moment(pd.PRODUCERS[producerindex].PRODUCERVALIDITY, "DD.MM.YYYY")
									}
								}

								if (examcount>0) {
									pd.FAMILIARIZATION = "" + examcount
								}

								if (needcopy) {
									const clone = JSON.parse(JSON.stringify(pd));
									clone.id = 'new';
									clone.READY = false;
									setPlanData(clone);
								} else {
									setPlanData(pd);
								}
								
							} else {
								setIsFetchingData(true)
							}
						})
						.catch(function(error) {
							setIsFetchingData(true)
						});
					} else {
						// create new plan
						setIsFetchingData(false)
						setPlanData({
							id: 'new',
							FAMILIARIZATION: "" + examcount,
							CRITERIAFORSTEP: "",
							TOTALUSAGE: "0",
							ORGANICINGREDIENTS: [],
							WILDPRODUCTS: [],
							OTHERORGANICINGREDIENTS: "", 
							ORGANICINGREDIENTSADDPLAN_NEXT : "", 
							ORGANICINGREDIENTSADDPLAN_TYPE : "",
							ORGANICINGREDIENTSADDPLAN_NEED : "",
							AGREEORGANICINGREDIENTSPUBLISH : false,
							ORGANICINGREDIENTSKILOS : "0", 
							ORGANICINGREDIENTSEUROS : "0",
							PLANDEVELOPMENT: "",
							DECISIONMUNICIPALITY : false, 
							DECISIONORGANISATION : false,
							DECISIONYEAR : "",
							FOODTENDERING_PRODUCTS : "",
							FOODTENDERING_START : "",
							FOODTENDERING_TIME : "",
							READY: false,
							CHAIN: props.chainid, 
							PRODUCERS: [],
							CRITICAL1: [],
							CRITICAL2: [],
							CRITICAL3: [],
							CRITICAL4: [],
							CRITICAL5: [],
							CRITICAL6: [],
							APPLYFORSTEP: ''
						})
					}

				} else {
					setIsFetchingData(true)
				}
		
				return response.data;
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});


			
		}
	}, [props.chainid])

	const [productsdb, setProductsdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/api_getproducts.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setProductsdb(response.data)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])

	const [wildproductsdb, setWildProductsdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/api_getwildproducts.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setWildProductsdb(response.data)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])


	const [producersdb, setProducersdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/api_getproducers.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setProducersdb(response.data)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])
	

	// ORGANICGRADIEND DISPLAY AND SELECT HANDLERS
	const selectProductGroup = (productgroup) => {
		setSelectedProductGroup(productgroup.id);
		//console.log(productgroup.id)
	};
	const selectProduct = (product) => {
		//console.log(product)

		if (selectedProductGroup && (selectedProductGroup=='615fcfb6a1c99a6fb14e09c0' || selectedProductGroup=='61652555e4b013438203c9dc')) {
			axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=importeddata', {
				chainid: props.chainid,
				planid: planData.id,
				type: product.TUKKUCODE
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setSelectedImportedProducts(response.data.importedproducts)
				} else {
				}

				let newg = null;
				setSelectedProduct(product.id);
				if (planData.ORGANICINGREDIENTS) {
					for (const gradient in planData.ORGANICINGREDIENTS) {
						let g = planData.ORGANICINGREDIENTS[gradient]
						if (g.PRODUCTID === product.id) {
							newg = g
							newg.NAME = product.NAME
						}
					}
				}
				if (newg) {
					setSelectedOrganicGradient(newg)
				} else {
					setSelectedOrganicGradient({
						USAGE: 'nousage',
						BIODYNAMIC: false,
						WILDPRODUCT: false,
						RATE: 0,
						UNITOFRATE: 'kg/v',
						PRODUCTID: product.id,
						NAME: product.NAME,
						TUKKUCODE: product.TUKKUCODE
					})
				}
			})
			.catch(function(error) {
			});
		} else {
			// clear imported products
			setSelectedImportedProducts([])

			let newg = null;
			setSelectedProduct(product.id);
			if (planData.ORGANICINGREDIENTS) {
				for (const gradient in planData.ORGANICINGREDIENTS) {
					let g = planData.ORGANICINGREDIENTS[gradient]
					if (g.PRODUCTID === product.id) {
						newg = g
						newg.NAME = product.NAME
					}
				}
			}
			if (newg) {
				setSelectedOrganicGradient(newg)
			} else {
				setSelectedOrganicGradient({
					USAGE: 'nousage',
					BIODYNAMIC: false,
					WILDPRODUCT: false,
					RATE: 0,
					UNITOFRATE: 'kg/v',
					PRODUCTID: product.id,
					NAME: product.NAME
				})
			}

		}

		//console.log(selectedOrganicGradient)
	};

	const Calcproductrate = () => {
		var count = 0
		if (planData.ORGANICINGREDIENTS) {
			for (const gradient in planData.ORGANICINGREDIENTS) {
				if (planData.ORGANICINGREDIENTS[gradient].RATE) {
					count += parseInt(planData.ORGANICINGREDIENTS[gradient].RATE)
				}
				
			}
		}
		return <React.Fragment>{count}</React.Fragment>
	}
	const Calcyearlyrate = () => {
		var pros = 0
		var organictotal = 0
		var alltotal = planData.TOTALUSAGE
		if (planData.ORGANICINGREDIENTS) {
			for (const gradient in planData.ORGANICINGREDIENTS) {
				if (planData.ORGANICINGREDIENTS[gradient].RATE) {
					organictotal += parseInt(planData.ORGANICINGREDIENTS[gradient].RATE)
				}
			}
		}
		if (organictotal && alltotal) {
			//pros = (organictotal / alltotal) * 100;
			pros = Math.round((organictotal / alltotal) * 100)
		}
		return <React.Fragment>{pros.toFixed(0)}</React.Fragment>
	}
	const checkproductcount = (productid) => {
		var count = 0
		if (planData.ORGANICINGREDIENTS) {
			for (const gradient in planData.ORGANICINGREDIENTS) {
				if (planData.ORGANICINGREDIENTS[gradient].PRODUCTID === productid && planData.ORGANICINGREDIENTS[gradient].USAGE!=='nousage') {
					//count = planData.ORGANICINGREDIENTS[gradient].RATE + ' ' +  planData.ORGANICINGREDIENTS[gradient].UNITOFRATE
					count++
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}
	const checkproductgroupcount = (productgroup) => {
		var count = 0
		if (planData.ORGANICINGREDIENTS) {
			for (const gradient in planData.ORGANICINGREDIENTS) {
				for (const product in productsdb.products) {
					if (productsdb.products[product].GROUP === productgroup.id) {
						if (planData.ORGANICINGREDIENTS[gradient].PRODUCTID === productsdb.products[product].id) {
							count++
						}
					}
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}




	// WILD PRODUCT DISPLAY AND SELECT HANDLERS
	const selectWildProductGroup = (newValue) => {
		setSelectedWildProductGroup(newValue.id);
	};
	const selectWildProduct = (product) => {
		
		let newg = null;
		setSelectedWildProduct(product.id);
		if (planData.WILDPRODUCTS) {
			for (const gradient in planData.WILDPRODUCTS) {
				let g = planData.WILDPRODUCTS[gradient]
				if (g.PRODUCTID === product.id) {
					newg = g
					newg.NAME = product.NAME
				}
			}
		}
		if (newg) {
			setSelectedWildProductGradient(newg)
		} else {
			setSelectedWildProductGradient({
				USAGE: 'nousage',
				PRODUCTID: product.id,
				NAME: product.NAME
			})
		}
	};
	const checkwildproductcount = (productid) => {
		var count = 0
		if (planData.WILDPRODUCTS) {
			for (const gradient in planData.WILDPRODUCTS) {
				if (planData.WILDPRODUCTS[gradient].PRODUCTID === productid) {
					//count = planData.ORGANICINGREDIENTS[gradient].RATE + ' ' +  planData.ORGANICINGREDIENTS[gradient].UNITOFRATE
					count++
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}
	const checkwildproductgroupcount = (productgroup) => {
		var count = 0
		if (planData.WILDPRODUCTS) {
			for (const gradient in planData.WILDPRODUCTS) {
				for (const product in wildproductsdb.wildproducts) {
					if (wildproductsdb.wildproducts[product].GROUP === productgroup.id) {
						if (planData.WILDPRODUCTS[gradient].PRODUCTID === wildproductsdb.wildproducts[product].id) {
							count++
						}
					}
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}

	
	// PRODUCER DISPLAY AND SELECT HANDLERS
	const selectProducer = (newProducer, index) => {
		if (newProducer.PRODUCERIDENTIFIER) {
			newProducer.PRODUCERIDENTIFIER = newProducer.PRODUCERIDENTIFIER.toUpperCase();
		}

		// get certfile by name
		producersdb.producers.map((producerItem) => {
			if (producerItem.NAME==newProducer.PRODUCER) {
				newProducer.CERT = producerItem.CERT;
				if (producerItem.VALIDITYDATESTR) {
					newProducer.PRODUCERVALIDITYDATE = moment(producerItem.VALIDITYDATESTR, "DD.MM.YYYY");
				}
			}
		});

		setSelectedProducer(newProducer);
		
	};
	const producers = planData.PRODUCERS.map((producer, index) =>
		<React.Fragment>
			<ListItem key={producer.PRODUCERID + " " + index} button selected={selectedProducer===producer} onClick={() => selectProducer(producer, index)}>
				{producer.VKPRODUCER
				? <ListItemText primary={capitalizeFirstLetter(producer.VKPRODUCER)} secondary={""} />
				: <ListItemText primary={capitalizeFirstLetter(producer.PRODUCER)} secondary={producer.PRODUCERIDENTIFIER} />
				}
				<ListItemSecondaryAction>
					<IconButton edge="end" aria-label="delete" onClick={(e) => {deleteProducer(producer)}}>{selectedProducer===producer ? <DeleteIcon style={{ color: '#fff' }}/> : <DeleteIcon /> }</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</React.Fragment>
	);
	const checkProductgroupSelected = (productgroup) => {
		let found = false;
		for (const pg in productsdb.productgroups) {
			if (productgroup.id==productsdb.productgroups[pg].id) {
				found = true;
			}
		}
		return found;
	}
	const addProducer = () => {
		let pd = planData;
		let d = new Date();
		let dstr = moment().format("D.M.YYYY");
		let r = Math.floor(Math.random() * 100000) + 1
		if (planData.PRODUCERS || (planData.PRODUCERS[0] && planData.PRODUCERS[0].PRODUCER!=="Uusi toimittaja")) {
			let producer = {
				GROUPS: [],
				PRODUCER: "Uusi toimittaja",
				PRODUCERID: "",
				PRODUCERIDENTIFIER: "FI-EKO-201",
				PRODUCERVALIDITY: null,
				PRODUCERVALIDITYDATE: null,
				VKPRODUCER: "",
				tempid: "" + d.getTime() + r
			}
			setSelectedProducer(producer);
			pd.PRODUCERS.unshift(producer)
			setPlanData({
				...planData,
				["PRODUCERS"]: planData.PRODUCERS
			});
		}
	};
	const deleteProducer = (producer) => {
		let found = -1;
		let producerlist = [...planData.PRODUCERS];
		if (producerlist) {
			for (const prd in producerlist) {
				if (producer.tempid==producerlist[prd].tempid) {
					found = prd;
				}
			}
		}
		// found it so remove
		if (found>-1) {
			if (confirm("Haluatko varmasti poistaa toimittajan '" + producer.PRODUCER + "'?")) {
				producerlist.splice(found, 1);
			}
		}

		setSelectedProducer({
			PRODUCERIDENTIFIER: '',
			PRODUCERVALIDITY: '1.1.2021',
			PRODUCER: ""
		})

		setPlanData({
			...planData,
			["PRODUCERS"]: producerlist
		});
	};

	const clearAllProducts = () => {
		if (confirm("!!! Haluatko varmasti poistaa kaikki tuotetiedot? !!!")) {
			let og = [...planData.ORGANICINGREDIENTS];
			og = []
			setPlanData({
				...planData,
				["ORGANICINGREDIENTS"]: og,
				["TOTALUSAGE"]: 0,
			});
			setPlanSaved(false)
		}
	};


	/* IMPORT */
	const handleImport = (addtotal, importer) => {
		//console.log(products)
		//console.log(addtotal)

		let toproduct = null;
		for (const originalgradient in productsdb.products) {
			if (importer.type===productsdb.products[originalgradient].TUKKUCODE) {
				toproduct = productsdb.products[originalgradient]
			}
		}

		let og = [...planData.ORGANICINGREDIENTS];
		let tog = [...planData.TOTALUSAGE]
		if (importer.totalweight && addtotal) {
			//console.log(importer.totalweight)
			let numbertog = parseFloat(planData.TOTALUSAGE)
			let numberimporter = parseFloat(importer.totalweight)
			let newnumbertotal = numbertog + numberimporter
			tog = newnumbertotal.toFixed(2)
			//console.log(numbertog)
			//console.log(numberimporter)
			//console.log(newnumbertotal)
			//console.log(tog)
		}

		let found = false;
		for (const gradient in og) {
			if (importer.type===og[gradient].TUKKUCODE) {
				let numbertog2 = parseFloat(og[gradient]['RATE'])
				let numberimporter2 = parseFloat(importer.ekoweight)
				let newnumbertotal2 = numbertog2 + numberimporter2
				og[gradient]['RATE'] = newnumbertotal2.toFixed(2)
				og[gradient]['USAGE'] = "allthetime"
				og[gradient]['BIODYNAMIC'] = false
				og[gradient]['WILDPRODUCT'] = false
				found = true;
			}
		}
		// if not found add clone from selected
		if (!found) {
			for (const originalgradient in productsdb.products) {
				if (importer.type===productsdb.products[originalgradient].TUKKUCODE) {
					const clone = JSON.parse(JSON.stringify(productsdb.products[originalgradient]));
					let numberimporter3 = parseFloat(importer.ekoweight)
					clone['RATE'] = numberimporter3.toFixed(2)
					clone['USAGE'] = "allthetime"
					clone['BIODYNAMIC'] = false
					clone['WILDPRODUCT'] = false
					clone['PRODUCTID'] = productsdb.products[originalgradient].id

					delete clone['id']
					delete clone['GROUP']
					delete clone['ORDERNRO']

					og.push(clone)
				}
			}
		}

		if (addtotal) {
			setPlanData({
				...planData,
				["TOTALUSAGE"]: ""+tog,
				["ORGANICINGREDIENTS"]: og
			});
		} else {
			setPlanData({
				...planData,
				["ORGANICINGREDIENTS"]: og
			});
		}

		setPlanSaved(false)

		setSelectedOrganicGradient({
			USAGE: 'nousage',
			NAME: ''
		});
		
	};

	const uploadLuomutodistusFile = (file) => {
		const data = new FormData() 
        data.append('file', file)
		data.append('planid', planData.id);
		data.append('producerid', selectedProducer.tempid);
		axios.post(plconfig.APIBase + 'apps/plan.groovy?action=uploadproducerfile', data)
		.then(function (response) {
			if (response.data.success) {
				let url = response.data.fileurl;
				if (url) {
					setSelectedProducer({
						...selectedProducer,
						["CERT"]: url
					})

					// update producerlist 
					let pl = planData.PRODUCERS;
					let found = false;
					if (pl) {
						for (const producerindex in pl) {
							if (pl[producerindex].tempid === selectedProducer.tempid) {
								pl[producerindex].CERT = url
								found = true;
							}
						}
					}
					setPlanData({
						...planData,
						["PRODUCERS"]: pl
					});
					//console.log(planData)
					setPlanSaved(false)
				}
			}
		})
		.catch(function(error) {
		});
	};


	return (
		<Container maxWidth="xl" className={classes.container}>

			<Prompt
				when={!planSaved}
				message={location =>
				`Olet poistumassa luomusuunnitelman muokkauksesta mutta et ole tallentanut muutoksia! Haluatko varmasti poistua tallentamatta? ${location.pathname}`
				}
			/>

			{(planData.id && planData.id!=='new')
			? <h4 className={classes.PaperHeader}>Luomusuunnitelma - {planData.created ? planData.created : ''} luotu vedos</h4>
			: <h4 className={classes.PaperHeader}>Luomusuunnitelma - Uusi suunnitelma</h4>
			}

			{(!planData.READY) || (userData.aadms) ?
				<Paper className={classes.paperTopGrid}>

					<form onSubmit={handleSave}>

					<Tabs value={selectedTab} onChange={handleChange} indicatorColor="primary" variant="fullWidth" aria-label="Luomusuunnitelmalomakkeen vaiheet">
						<Tab label="Luomutavoite" className={selectedTab===0 ? (!isPhase1Valid ? classes.tabItemError : classes.tabItem) : (!isPhase1Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Luomutuotteet" className={selectedTab===1 ? (!isPhase3Valid ? classes.tabItemError : classes.tabItem) : (!isPhase3Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Villituotteet" className={selectedTab===2 ? (!isPhase4Valid ? classes.tabItemError : classes.tabItem) : (!isPhase4Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Toimittajat" className={selectedTab===3 ? (!isPhase5Valid ? classes.tabItemError : classes.tabItem) : (!isPhase5Valid ? classes.tabItemError2 : classes.tabItem2)} />
						<Tab label="Kriittiset pisteet" className={selectedTab===4 ? (!isPhase6Valid ? classes.tabItemError : classes.tabItem) : (!isPhase6Valid ? classes.tabItemError2 : classes.tabItem2)} />
					</Tabs>

					{(planData.READY && userData.aadms) &&
					<Alert severity="info" className={classes.commonAlert}>
						<AlertTitle>Suunnitelma valmis</AlertTitle>
						Tämä suunnitelma on lähetetty hyäksyttäväksi ja näkyy nyt vain Ylläpitäjän näkymässä. Voit myös <Link className={classes.linkcursor} onClick={handleSetNotReady}>palauttaa lomakkeen täytettäväksi.</Link>
					</Alert>
					}

					{isSaveOk &&
					<Alert severity="success" className={classes.commonAlert}>
						<AlertTitle>Tallennettu</AlertTitle>
						Suunnitelman tiedot on nyt tallennettu
					</Alert>
					}

					{isError &&
					<Alert severity="error" className={classes.commonAlert}>
						<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
						Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
					</Alert>
					}

					<TabPanel value={selectedTab} index={0} className={classes.tabPanel}>

						<Typography className={classes.maintText}>Kerro tässä, mille portaalle hakeudutte ja perustele. Kerro myös, kuinka paljon teillä tavoitteena lisätä luomun käyttöä.</Typography>

						<Grid container spacing={3}>
							<Grid item xs={2}>
								<FormControl variant="outlined" fullWidth className={classes.formInput}>
									<InputLabel id="APPLYFORSTEP-select-label">Hakeudutaan portaalle *</InputLabel>
									<Select
										id="APPLYFORSTEP"
										name="APPLYFORSTEP"
										labelId="APPLYFORSTEP-select-label"
										variant="outlined"
										className={classes.formInput}
										type="text"
										value={planData.APPLYFORSTEP}
										onChange={handleSelectInputChange}
										error={!isValid && (planData.APPLYFORSTEP=="" || planData.APPLYFORSTEP==null)}
										>
										<MenuItem key={1} value={"1"}>1</MenuItem>
										<MenuItem key={2} value={"2"}>2</MenuItem>
										<MenuItem key={3} value={"3"}>3</MenuItem>
										<MenuItem key={4} value={"4"}>4</MenuItem>
										<MenuItem key={5} value={"5"}>5</MenuItem>
										<MenuItem key={6} value={"6"}>6</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={10}>
								<Typography className={classes.extraInfoText}>Katso ja tutustu <Link href="http://www.luomuravintola.fi/portaiden-kriteerit" target="_blank">porrastasojen vaatimuksiin</Link></Typography>
							</Grid>
						</Grid>	


						<TextField
							id="CRITERIAFORSTEP"
							label="Perustelut *"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={planData.CRITERIAFORSTEP}
							fullWidth
							multiline
							rows="8"
							helperText="Valitse porras jolle organisaatio hakeutuu sekä perustele valintasi"
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && (planData.CRITERIAFORSTEP=="" || planData.CRITERIAFORSTEP==null)}
						/>

						<TextField
							id="DECISIONPERCENT"
							label="Kuinka paljon luomun käyttöä on tavoitteena lisätä? *"
							variant="outlined"
							className={classes.formInputSmall}
							type="number"
							value={planData.DECISIONPERCENT}
							InputProps={{
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
							error={!isValid && (planData.DECISIONPERCENT=="" || planData.DECISIONPERCENT==null)}
						/>

						<hr/>
						<h4 className={classes.mainHeader}>Strategiset päätökset</h4>

						<FormControl component="fieldset" fullWidth>
							<FormLabel component="legend">Onko ruokapalveluissa/ravintolassa tehty strateginen päätös luomutuotteiden käytön lisäämiseksi ruokapalveluissa? *</FormLabel>
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={planData.DECISIONMUNICIPALITY} onChange={handleSwitchChange} id="DECISIONMUNICIPALITY" name="DECISIONMUNICIPALITY" />} label="Kunnassa" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={planData.DECISIONORGANISATION} onChange={handleSwitchChange} id="DECISIONORGANISATION" name="DECISIONORGANISATION" />} label="Organisaatiossa" />
						</FormControl>

						<TextField
							id="DECISIONYEAR"
							label="Mihin vuoteen mennessä on tarkoitus saavuttaa päätöksen mukainen tavoite?"
							variant="outlined"
							className={classes.formInput}
							type="text"
							fullWidth
							value={planData.DECISIONYEAR}
							InputProps={{
								startAdornment: <InputAdornment position="start">vuoteen</InputAdornment>,
								endAdornment: <InputAdornment position="end">mennessä</InputAdornment>,
							}}
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<TextField
							id="FOODTENDERING_PRODUCTS"
							label="Seuraavaksi hankittavat luomutuotteet"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={planData.FOODTENDERING_PRODUCTS}
							fullWidth
							multiline
							rows="4"
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>	

						<TextField
							id="FOODTENDERING_START"
							label="Seuraavan elintarvikekilpailutuksen suunnittelu alkaa (julkinen sektori)"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={planData.FOODTENDERING_START}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>	

						<TextField
							id="FOODTENDERING_TIME"
							label="Seuraavan elintarvikehankinnan ajankohta (julkinen sektori)"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={planData.FOODTENDERING_TIME}
							fullWidth
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>	


						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(1)}>Luomutuotteet<ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	

					</TabPanel> 

					<TabPanel value={selectedTab} index={1} className={classes.tabPanel}>

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
								<Typography className={classes.maintText}>Tähän kirjataan kaikkien käytössä olevien elintarvikkeiden (myös juomat) käyttömäärä kg/vuodessa. Käyttömäärät saat tavarantoimittajien ostolistauksista. Jos lisäät suunnitelmaan tietoja manuaalisesti, niin muista lisätä luomutuotteiden kilomäärät myös tähän kohtaan. Elintarvikkeiden kokonaismäärä on tärkeä tieto luomuosuuden laskemisessa.</Typography>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Grid container spacing={0}>
									<Grid item xs={6}>
										<ImportChooser chainid={props.chainid} planid={planData.id} handleImport={handleImport}/>
									</Grid>
									<Grid item xs={6}>
										<Button variant="outlined" className={classes.deleteallbutton} onClick={clearAllProducts}>Tyhjennä tuotetiedot</Button> 
									</Grid>
								</Grid>	
							</Grid>
						</Grid>	

						<Grid container spacing={0}>
							<Grid item xs={6} className={classes.infogrid}>
								<Typography className={classes.extraInfoText2}>Elintarvikkeiden kokonaiskäyttömäärä <IconButton className={classes.HelpIconButton} aria-label="Avaa lisätietoikkuna" onClick={handleTotalModalOpen}><HelpIcon /></IconButton></Typography>
								<TextField
									id="TOTALUSAGE"
									label="Elintarvikkeiden kokonaiskäyttömäärä *"
									variant="outlined"
									fullWidth
									className={classes.formInput}
									type="number"
									value={planData.TOTALUSAGE}
									InputProps={{
										endAdornment: <InputAdornment position="end">kg/vuosi</InputAdornment>,
									}}
									onChange={handleTextInputChange}
									InputLabelProps={{
										shrink: true,
									}}
									error={!isValid && (planData.TOTALUSAGE=="" || planData.TOTALUSAGE==null)}
								/>
							</Grid>
							<Grid item xs={6} className={classes.infogridright}>
								<Box spacing={3}>
									<Typography>Merkittäviä luomuelintarvikkeita yhteensä: <strong><Calcproductrate/> kg/v</strong></Typography>
									{planData.TOTALUSAGE &&
										<Typography className={classes.maintText}>Elintarvikkeiden määrästä luomua: <strong><Calcyearlyrate/> %</strong></Typography>
									}
								</Box>
							</Grid>
						</Grid>

						<Modal
							open={totalHelpModalOpen}
							onClose={handleTotalModalClose}
							aria-labelledby="Lisäinfo kokonaispainolle"
							aria-describedby="Lisäinfo kokonaispainolle"
						>
							<div className={classes.modalPaper}>
								<IconButton className={classes.modalCloseButton} onClick={handleTotalModalClose}><CancelIcon /></IconButton>
								<p>Elintarvikkeiden kokonaiskäyttömäärä lasketaan toimipaikkojen volyymin mukaan ja mikäli tehdään uutta suunnitelmaa se esitäytetään alla olevaan kenttään. </p>
								<p>Kaikkien käytössä olevien (ei luomu + luomu) elintarvikkeiden käyttömäärä kg/vuodessa. Mikäli kaikkien käytössä olevien elintarvikkeiden käyttömäärä/vuosi on vaikea saada esim. organisaation omasta ostoraportista, niin kokonaismäärän voi suhteuttaa annosmäärien mukaan. Suhteutuksen voi tehdä esim.: elintarvikkeiden käyttömäärät/annos -{'>'} annosta/päivä -{'>'} annosta/vuosi = kokonaiskäyttömäärä/vuosi</p>
							</div>
						</Modal>


						<Grid container spacing={2}>
							<Grid item xs={3} className={classes.productColumn1}>
								<Typography className={classes.productHeader}>Tuoteryhmät</Typography>
								<List component="nav" aria-label="Luomutuoteryhmät" dense={false}>
									{(productsdb && productsdb.productgroups) &&
										<React.Fragment>
										{productsdb.productgroups.map((productgroup) =>
											<ListItem key={productgroup.id} button selected={selectedProductGroup===productgroup.id} onClick={() => selectProductGroup(productgroup)}><ListItemText primary={productgroup.NAME} />
												{checkproductgroupcount(productgroup)}
											</ListItem>
										)}
										</React.Fragment>
									}
								</List>	
							</Grid>
							<Grid item xs={3} className={classes.productColumn2}>
								<Typography className={classes.productHeader}>Tuotteet</Typography>
								<List component="nav" aria-label="Luomutuotteet" dense={false}>
									{(productsdb && productsdb.products) &&
										<React.Fragment>
										{productsdb.products.map((product) =>
											<React.Fragment>
												{product.GROUP === selectedProductGroup &&
													<ListItem button selected={selectedProduct===product.id} onClick={() => selectProduct(product)}><ListItemText primary={capitalizeFirstLetter(product.NAME)} />
														{checkproductcount(product.id)}
													</ListItem>
												}
											</React.Fragment>
										)}
										</React.Fragment>
									}
								</List>	
							</Grid>
							<Grid item xs={6} className={classes.productColumn3}>

								{(selectedOrganicGradient && selectedOrganicGradient.NAME!=='') &&

								<div className={classes.productForm}>

									<Typography className={classes.productHeader2}>Tuotteen "{selectedOrganicGradient.NAME}" käyttötiedot</Typography>

									<TextField
										id="RATE"
										label="Käyttömäärä"
										variant="outlined"
										className={classes.formInput}
										type="number"
										fullWidth
										value={selectedOrganicGradient.RATE}
										InputProps={{
											endAdornment: <InputAdornment position="end">{selectedOrganicGradient.UNITOFRATE}</InputAdornment>,
										}}
										helperText="Kuinka paljon tuotetta käytetään vuodessa"
										onChange={handleGradientInputChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>

									{(selectedProductGroup && (selectedProductGroup=='615fcfb6a1c99a6fb14e09c0' || selectedProductGroup=='61652555e4b013438203c9dc')) ?
										<React.Fragment>
											<FormControl component="fieldset" fullWidth>
												<RadioGroup aria-label="usage" id="USAGE" name="USAGE" value={selectedOrganicGradient.USAGE} onChange={handleGradientInputChange}>
													<FormControlLabel value="nousage" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'nousage'} value="nousage" />} label="Tuotteet ei käytössä (poistetaan listauksesta)" />
													<FormControlLabel value="allthetime" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'allthetime'} value="allthetime"/>} label="Tuotteet käytössä" />
												</RadioGroup>
											</FormControl>
										</React.Fragment>
									:
										<React.Fragment>
											<FormControl component="fieldset" fullWidth>
												<FormLabel component="legend">Tuotetta käytetään</FormLabel>
												<RadioGroup aria-label="usage" id="USAGE" name="USAGE" value={selectedOrganicGradient.USAGE} onChange={handleGradientInputChange}>
													<FormControlLabel value="nousage" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'nousage'} value="nousage" />} label="Tuote ei käytössä (poistetaan listauksesta)" />
													<FormControlLabel value="allthetime" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'allthetime'} value="allthetime"/>} label="Käytetään jatkuvasti" />
													<FormControlLabel value="twiceweek" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'twotimes'} value="twotimes"/>} label="Kaksi kertaa viikossa" />
													<FormControlLabel value="other" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'rarely'} value="rarely" />} label="Harvemmin" />
												</RadioGroup>
											</FormControl>

											<FormControl component="fieldset" fullWidth>
												<FormLabel component="legend">Tuote on <IconButton className={classes.HelpIconButton} aria-label="Avaa lisätietoikkuna" onClick={handleModalOpen}><HelpIcon /></IconButton></FormLabel>
												<FormControlLabel value="biodynamic" control={<Checkbox id="BIODYNAMIC" checked={selectedOrganicGradient.BIODYNAMIC} onChange={handleGradientSwitchChange}/>} label="Biodynaaminen" />
												<FormControlLabel value="wild" control={<Checkbox id="WILDPRODUCT" checked={selectedOrganicGradient.WILDPRODUCT} onChange={handleGradientSwitchChange}/>} label="Luomusertifioitu Villi/keruutuote"></FormControlLabel>
											</FormControl>
										</React.Fragment>
									}


									{(selectedImportedProducts && selectedImportedProducts.length>0 ) &&
										<React.Fragment>
											{selectedImportedProducts.map((selectedImportedProduct) =>
												<pre>{selectedImportedProduct.productdata}</pre>
											)}
										</React.Fragment>
									}

								</div>

								}

								<Modal
									open={sertifiedHelpModalOpen}
									onClose={handleModalClose}
									aria-labelledby="Lisäinfo tuotteille"
									aria-describedby="Lisäinfo tuotteille"
								>
									
									<div className={classes.modalPaper}>
										<IconButton className={classes.modalCloseButton} onClick={handleModalClose}><CancelIcon /></IconButton>
										<p>Metsästä ja järvestä saadut luonnonantimet eivät automaattisesti ole luomua. Keruutuotteet, kuten metsämarjat, sienet, luonnonyrtit ja muut erikoiskeruutuotteet ovat luomutuotteita ainoastaan silloin, kun ne on kerätty luomusertifioiduilta keruualueilta. Villikalaa, riistaa ja poroja, ei voi myydä tai sertifioida luomuksi. Lisätietoa luomukeruutuotteisiin liittyvästä lainsäädännöstä löydät osoitteesta <Link href="https://www.ruokavirasto.fi" target="_blank">www.ruokavirasto.fi</Link></p>
									</div>
								</Modal>

							</Grid>
						</Grid>	

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(3)}>Villi- ja keruutuotteet <ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	

					</TabPanel>
					<TabPanel value={selectedTab} index={2} className={classes.tabPanel}>
						<Typography className={classes.maintText}>Merkkaa tähän kohtaan kotimaiset villi- ja keruutuotteet, joita käytätte ja <Link href="https://www.ruokavirasto.fi/yritykset/elintarvikeala/luomutuotteet/luomukeruutuotteet-luomukeruualueilta/" target="_blank">jotka eivät ole sertifioitua luomua.</Link>
						<br/>Tuotteet voivat olla tuoreita tai pakasteita. Kun käytössä on säännöllisesti vähintään kahden villituoteryhmän tuotetta, voitte ottaa käyttöönne Käytämme luonnontuotteita -merkin.</Typography>

						{planData.ISWILDPRODUCTMARK &&
							<Typography className={classes.maintText}>Voit ladata merkin <Link href="https://www.luomuravintola.fi/resources/images//luomua_ravintolat_luonnontuote_tunnus_v2.jpg" target="_blank">JPG</Link> tai <Link href="https://www.luomuravintola.fi/resources/images//luomua_ravintolat_luonnontuote_tunnus_v2.pdf" target="_blank">PDF-tiedostona</Link></Typography>
						}

						<Grid container spacing={2}>
							<Grid item xs={3} className={classes.productColumn1}>
								<Typography className={classes.productHeader}>Villi- ja keruutuotteet</Typography>
								<List component="nav" aria-label="Villi- ja keruutuotteet" dense={false}>
									{(wildproductsdb && wildproductsdb.wildproductgroups) &&
										<React.Fragment>
										{wildproductsdb.wildproductgroups.map((wildproductgroup) =>
											<ListItem key={wildproductgroup.id} button selected={selectedWildProductGroup===wildproductgroup.id} onClick={() => selectWildProductGroup(wildproductgroup)}><ListItemText primary={wildproductgroup.NAME} />
												{checkwildproductgroupcount(wildproductgroup)}
											</ListItem>
										)}
										</React.Fragment>
									}
								</List>	
							</Grid>
							<Grid item xs={3} className={classes.productColumn2}>
								<Typography className={classes.productHeader}>Tuotteet</Typography>
								<List component="nav" aria-label="Luomutuoteryhmät" dense={false}>
									{(wildproductsdb && wildproductsdb.wildproducts) &&
										<React.Fragment>
										{ wildproductsdb.wildproducts.map((wildproduct) =>
											<React.Fragment>
												{wildproduct.GROUP === selectedWildProductGroup &&
													<ListItem button selected={selectedWildProduct===wildproduct.id} onClick={() => selectWildProduct(wildproduct)}><ListItemText primary={capitalizeFirstLetter(wildproduct.NAME)} />
														{checkwildproductcount(wildproduct.id)}
													</ListItem>
												}
											</React.Fragment>
										)}
										</React.Fragment>
									}
								</List>	
							</Grid>
							<Grid item xs={6} className={classes.productColumn3}>

								{(selectedWildProductGradient && selectedWildProductGradient.NAME!=='') &&
								<div className={classes.productForm}>

									<Typography className={classes.productHeader2}>Tuotteen "{selectedWildProductGradient.NAME}" käyttötiedot</Typography>

									<FormControl component="fieldset" fullWidth>
										<FormLabel component="legend">Tuotetta käytetään</FormLabel>
										<RadioGroup aria-label="usage" id="USAGE" name="USAGE"  value={selectedWildProductGradient.USAGE} onChange={handleWildProductInputChange}>
											<FormControlLabel value="nousage" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'nousage'} value="nousage" />} label="Tuote ei käytössä (poistetaan listauksesta)" />
											<FormControlLabel value="allthetime" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'allthetime'} value="allthetime"/>} label="Käytetään jatkuvasti" />
											<FormControlLabel value="twiceweek" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'twotimes'} value="twotimes"/>} label="Kaksi kertaa viikossa" />
											<FormControlLabel value="other" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'rarely'} value="rarely" />} label="Harvemmin" />
										</RadioGroup>
									</FormControl>

								</div>
								}

							</Grid>
						</Grid>	

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(4)}>Toimittajat <ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	


					</TabPanel>
					<TabPanel value={selectedTab} index={3} className={classes.tabPanel}>
						<Typography className={classes.maintText}>Täydennä tähän luomutuotteiden kaikki tavarantoimittajat, toimittajan luomutodistuksen voimassaolo sekä viranomaisen tunnus. Nämä tiedot ovat tärkeitä omavalvonnassa ja mm. Oiva-tarkastuksen yhteydessä. Voit hakea tavarantoimittajien luomutodistuksia myös osoitteesta <a href="https://www.ruokavirasto.fi/henkiloasiakkaat/tietoa-elintarvikkeista/luomuruoka/luomuhakupalvelu/" target="_blank">www.ruokavirasto.fi/henkiloasiakkaat/tietoa-elintarvikkeista/luomuruoka/luomuhakupalvelu/</a>.</Typography>

						<Grid container spacing={3}>
							<Grid item xs={4}>

								<Button variant="outlined" color="primary" className={classes.addNewButton} onClick={addProducer}>Lisää toimittaja</Button>

								<List component="nav" aria-label="Toimittajat" dense={false}>
									{producers}
								</List>

							</Grid>
							<Grid item xs={8}>

								{(selectedProducer && selectedProducer.PRODUCERIDENTIFIER!='') &&
								<React.Fragment>

									<Autocomplete
										id="PRODUCER-combo"
										options={producersdb.producers.map((option) => option.NAME)}
										freeSolo
										value={selectedProducer.PRODUCER} 
										onChange={handleProducerNameChange}
										isOptionEqualToValue={(option, value) => option.NAME === value} 
										renderInput={(params) => {
											params.inputProps.autoComplete = 'new-password';
											return (
												<TextField 
													{...params} 
													id="PRODUCER"
													label="Luomutuotteiden toimittajan/ostopaikan nimi"
													variant="outlined" 
													className={classes.formInput} 
													fullWidth 
													InputLabelProps={{shrink: true}} 
													value={selectedProducer.PRODUCER} 
													onChange={handleProducerNameChange} 
													helperText="Voit hakea toimittajia listasta tai kirjoittaa oman toimittajan."
												/>
											);
										}}
									/>

									<DatePicker
										id="PRODUCERVALIDITY"
										label="Toimittajan luomutodistuksen voimassaoloaika"
										inputVariant="outlined"
										className={classes.formInput}
										value={selectedProducer.PRODUCERVALIDITYDATE}
										onChange={handleProducerDateChange}
										animateYearScrolling
										format="DD.MM.YYYY"
										error={!isValid && (selectedProducer.PRODUCERVALIDITYDATE=="" || selectedProducer.PRODUCERVALIDITYDATE=="Invalid date" || selectedProducer.PRODUCERVALIDITYDATE==null)}
									/>

									<FormControl variant="outlined" fullWidth className={classes.formInput}>
										<InputLabel id="PRODUCERIDENTIFIER-select-label" >Viranomaisen tunnus</InputLabel>
										<Select
											labelId="PRODUCERIDENTIFIER-select-label"
											id="PRODUCERIDENTIFIER"
											name="PRODUCERIDENTIFIER"
											variant="outlined"
											value={selectedProducer.PRODUCERIDENTIFIER}
											onChange={handleProducerInputChange}
											>
											<MenuItem key={0} id="PRODUCERIDENTIFIER" value="">Valitse viranomainen</MenuItem>
											<MenuItem key={1} id="PRODUCERIDENTIFIER" value={"FI-EKO-101"} >FI-EKO-101 Uudenmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={2} id="PRODUCERIDENTIFIER" value={"FI-EKO-102"} >FI-EKO-102 Varsinais-Suomen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={3} id="PRODUCERIDENTIFIER" value={"FI-EKO-103"} >FI-EKO-103 Satakunnan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={4} id="PRODUCERIDENTIFIER" value={"FI-EKO-104"} >FI-EKO-104 Hämeen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={5} id="PRODUCERIDENTIFIER" value={"FI-EKO-105"} >FI-EKO-105 Pirkanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={6} id="PRODUCERIDENTIFIER" value={"FI-EKO-106"} >FI-EKO-106 Kaakkois-Suomen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={7} id="PRODUCERIDENTIFIER" value={"FI-EKO-107"} >FI-EKO-107 Etelä-Savon elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={8} id="PRODUCERIDENTIFIER" value={"FI-EKO-108"} >FI-EKO-108 Pohjois-Savon elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={9} id="PRODUCERIDENTIFIER" value={"FI-EKO-109"} >FI-EKO-109 Pohjois-Karjalan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={10} id="PRODUCERIDENTIFIER" value={"FI-EKO-110"} >FI-EKO-110 Keski-Suomen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={11} id="PRODUCERIDENTIFIER" value={"FI-EKO-111"} >FI-EKO-111 Etelä-Pohjanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={12} id="PRODUCERIDENTIFIER" value={"FI-EKO-112"} >FI-EKO-112 Pohjanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={13} id="PRODUCERIDENTIFIER" value={"FI-EKO-113"} >FI-EKO-113 Pohjois-Pohjanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={14} id="PRODUCERIDENTIFIER" value={"FI-EKO-114"} >FI-EKO-114 Kainuun elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={15} id="PRODUCERIDENTIFIER" value={"FI-EKO-115"} >FI-EKO-115 Lapin elinkeino,- liikenne ja ympäristökeskus</MenuItem>
											<MenuItem key={16} id="PRODUCERIDENTIFIER" value={"FI-EKO-201"} >FI-EKO-201 Ruokavirasto</MenuItem>
											<MenuItem key={17} id="PRODUCERIDENTIFIER" value={"FI-EKO-301"} >FI-EKO-301 Sosiaali- ja terveysalan lupa- ja valvontavirasto</MenuItem>
										</Select>
									</FormControl>


									<Grid container spacing={3} className={classes.bottomGrid2}>
										<Grid item xs={6}>
											<FormControl component="fieldset" fullWidth>
												<FormLabel component="legend">Luomutodistus</FormLabel>
												{selectedProducer.CERT &&
													<a href={fixSlash(plconfig.APIBase)+selectedProducer.CERT} target="_blank">Avaa todistus</a>
												}
											</FormControl>
										</Grid>
										<Grid item xs={6}>
											{(planData.id && planData.id!=='new')
											? (<FormControl component="fieldset" fullWidth>
												<FormLabel component="legend">Lataa luomutodistus</FormLabel>
												<FileInput fileHandler={uploadLuomutodistusFile} btntext="Lataa"/>
												<FormHelperText>Lataa luomutodistus pdf-muodossa. Koko max 5 mt.</FormHelperText>
											</FormControl>
											) : (
												<p>Tallenna lisätäksesi oma luomutodistus</p>
											)}
										</Grid>
									</Grid>	

									<hr/>

									<FormControl component="fieldset" fullWidth>
										<FormLabel component="legend">Mistä tuoteryhmistä toimittaja toimittaa luomutuotteita? </FormLabel>
										<RadioGroup aria-label="suppliergroups" id="suppliergroups" name="usage">
											{(productsdb && productsdb.productgroups) &&
												<React.Fragment>
												{productsdb.productgroups.map((productgroup) =>
													<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={selectedProducer.GROUPS.includes(productgroup.id)} onChange={handleProducerCheckboxChange} id={productgroup.id} name={productgroup.id} />} label={productgroup.NAME} value={productgroup.id} />
												)}
												</React.Fragment>
											}
										</RadioGroup>
									</FormControl>
								
								</React.Fragment>
								}

							</Grid>
						</Grid>	

						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className={classes.bottomGridRight}>
								<Button variant="outlined" className={classes.bottombutton3} onClick={() => handleNext(5)}>Kriittiset pisteet <ChevronRightIcon/> </Button>
							</Grid>
						</Grid>	


					</TabPanel>
					<TabPanel value={selectedTab} index={4} className={classes.tabPanel}>
						<Typography className={classes.maintText}>Täytä tähän huolellisesti luomun käyttöön ja luomusta viestimiseen liittyvät asiat. Huolellisesti täytettynä tätä lomaketta voi käyttää apuna OIVA-tarkastuksessa luomun käytön todenteena.</Typography>
						
						<div className={classes.productForm}>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend" error={!isValid && planData.CRITICAL1.length==0}>Miten varmistatte, että luomutuotteet eivät sekoitu tavanomaisiin (ei luomu) tuotteisiin ja että henkilökunta on tietoinen luomutuotteiden käytöstä? *</FormLabel>
								<RadioGroup aria-label="critical1" id="critical1" name="usage">
									<FormControlLabel className={classes.checkboxLabel} value="critical1-1" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-1")} onChange={handleCriticalCheckboxChange} id="critical1-1" name="CRITICAL1" />} label="luomutuotteille on oma merkattu paikka varastossa/hyllyssä" />
									<FormControlLabel className={classes.checkboxLabel} value="critical1-2" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-2")} onChange={handleCriticalCheckboxChange} id="critical1-2" name="CRITICAL1" />} label="luomutuotteet ovat omissa pakkauksissaan" />
									<FormControlLabel className={classes.checkboxLabel} value="critical1-3" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-3")} onChange={handleCriticalCheckboxChange} id="critical1-3" name="CRITICAL1" />} label="irtoluomutuotteet ovat omissa merkatuissa laatikoissaan" />
									<FormControlLabel className={classes.checkboxLabel} value="critical1-4" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-4")} onChange={handleCriticalCheckboxChange} id="critical1-4" name="CRITICAL1" />} label="luomutuotteiksi valittujen rinnalla ei käytetä vastaavia tavanomaisia tuotteita" />
									<FormControlLabel className={classes.checkboxLabel} value="critical1-5" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-5")} onChange={handleCriticalCheckboxChange} id="critical1-5" name="CRITICAL1" />} label="henkilökunnalla on nähtävillä listaus käytössä olevista luomutuotteista" />
									<FormControlLabel className={classes.checkboxLabel} value="critical1-6" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-6")} onChange={handleCriticalCheckboxChange} id="critical1-6" name="CRITICAL1" />} label="reseptiikkaan on kiinnitetty luomuna käytettävät tuotteet" />
									<FormControlLabel className={classes.checkboxLabel} value="critical1-7" control={<Checkbox checked={planData.CRITICAL1.includes("critical1-7")} onChange={handleCriticalCheckboxChange} id="critical1-7" name="CRITICAL1" />} label="muuta mitä?" />
								</RadioGroup>
							</FormControl>

							<TextField
								id="CRITICAL1OTHER"
								label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={planData.CRITICAL1OTHER}
								fullWidth
								multiline
								rows="3"
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>	

							<hr/>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend" error={!isValid && planData.CRITICAL2.length==0}>Miten vastaanottotarkastuksen yhteydessä tarkistetaan, että tuotteet ovat luomua? </FormLabel>
								<RadioGroup aria-label="critical2" id="critical2" name="usage">
									<FormControlLabel className={classes.checkboxLabel} value="critical2-1" control={<Checkbox checked={planData.CRITICAL2.includes("critical2-1")} onChange={handleCriticalCheckboxChange} id="critical2-1" name="CRITICAL2" />} label="tarkistetaan lähetyslistojen ja saapuneen kuorman vastaavuus luomun osalta" />
									<FormControlLabel className={classes.checkboxLabel} value="critical2-2" control={<Checkbox checked={planData.CRITICAL2.includes("critical2-2")} onChange={handleCriticalCheckboxChange} id="critical2-2" name="CRITICAL2" />} label="tarkistetaan, että luomutuotteista löytyy viralliset luomumerkinnät" />
									<FormControlLabel className={classes.checkboxLabel} value="critical2-3" control={<Checkbox checked={planData.CRITICAL2.includes("critical2-3")} onChange={handleCriticalCheckboxChange} id="critical2-3" name="CRITICAL2" />} label="pyydetään tavarantoimittajilta luomutodistukset, jotka säilytetään omavalvontakansiossa" />
									<FormControlLabel className={classes.checkboxLabel} value="critical2-4" control={<Checkbox checked={planData.CRITICAL2.includes("critical2-4")} onChange={handleCriticalCheckboxChange} id="critical2-4" name="CRITICAL2" />} label="muuta mitä?" />
								</RadioGroup>
							</FormControl>


							<TextField
								id="CRITICAL2OTHER"
								label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={planData.CRITICAL2OTHER}
								fullWidth
								multiline
								rows="3"
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>	

							<hr/>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend" error={!isValid && planData.CRITICAL3.length==0}>Luomutuotteiden käytön läpinäkyvyys? </FormLabel>
								<RadioGroup aria-label="critical3" id="critical3" name="usage">
									<FormControlLabel className={classes.checkboxLabel} value="critical3-1" control={<Checkbox checked={planData.CRITICAL3.includes("critical3-1")} onChange={handleCriticalCheckboxChange} id="critical3-1" name="CRITICAL3" />} label="käytössä olevat luomutuotteet ovat näkyvillä asiakastiloissa esim. listattuna" />
									<FormControlLabel className={classes.checkboxLabel} value="critical3-2" control={<Checkbox checked={planData.CRITICAL3.includes("critical3-2")} onChange={handleCriticalCheckboxChange} id="critical3-2" name="CRITICAL3" />} label="ruokalistalla luomutuotteet merkattuna" />
									<FormControlLabel className={classes.checkboxLabel} value="critical3-3" control={<Checkbox checked={planData.CRITICAL3.includes("critical3-3")} onChange={handleCriticalCheckboxChange} id="critical3-3" name="CRITICAL3" />} label="henkilökunnan kanssa käydään käytössä olevat luomutuotteet läpi säännöllisesti" />
									<FormControlLabel className={classes.checkboxLabel} value="critical3-4" control={<Checkbox checked={planData.CRITICAL3.includes("critical3-4")} onChange={handleCriticalCheckboxChange} id="critical3-4" name="CRITICAL3" />} label="Luomua ravintola -tunnus on näkyvillä asiakastiloissa" />
									<FormControlLabel className={classes.checkboxLabel} value="critical3-5" control={<Checkbox checked={planData.CRITICAL3.includes("critical3-5")} onChange={handleCriticalCheckboxChange} id="critical3-5" name="CRITICAL3" />} label="muuta mitä?" />
								</RadioGroup>
							</FormControl>

							<TextField
								id="CRITICAL3OTHER"
								label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={planData.CRITICAL3OTHER}
								fullWidth
								multiline
								rows="3"
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>	

							<hr/>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend" error={!isValid && planData.CRITICAL4.length==0}>Miten varmistatte, että ruuan valmistuksessa on käytetty niiden hankintaa vastaava määrä? varastokirjanpito, omavalvontakirjanpito tms </FormLabel>
								<RadioGroup aria-label="critical4" id="critical4" name="usage">
									<FormControlLabel className={classes.checkboxLabel} value="critical4-1" control={<Checkbox checked={planData.CRITICAL4.includes("critical4-1")} onChange={handleCriticalCheckboxChange} id="critical4-1" name="CRITICAL4" />} label="luomutuotteiden tilaus tehdään voimassa olevan ruokalistan mukaan " />
									<FormControlLabel className={classes.checkboxLabel} value="critical4-2" control={<Checkbox checked={planData.CRITICAL4.includes("critical4-2")} onChange={handleCriticalCheckboxChange} id="critical4-2" name="CRITICAL4" />} label="tilatut luomutuotteet käytetään mahdollisimman nopeasti ja 'first in, first out' -periaatteella" />
									<FormControlLabel className={classes.checkboxLabel} value="critical4-3" control={<Checkbox checked={planData.CRITICAL4.includes("critical4-3")} onChange={handleCriticalCheckboxChange} id="critical4-3" name="CRITICAL4" />} label="lähetyslistat säilytetään omavalvontakansiossa" />
									<FormControlLabel className={classes.checkboxLabel} value="critical4-4" control={<Checkbox checked={planData.CRITICAL4.includes("critical4-4")} onChange={handleCriticalCheckboxChange} id="critical4-4" name="CRITICAL4" />} label="tuotannonohjausjärjestelmästä saadaan selville luomutuotteiden käyttömäärät vuositasolla" />
									<FormControlLabel className={classes.checkboxLabel} value="critical4-5" control={<Checkbox checked={planData.CRITICAL4.includes("critical4-5")} onChange={handleCriticalCheckboxChange} id="critical4-5" name="CRITICAL4" />} label="muuta mitä?" />
								</RadioGroup>
							</FormControl>

							<TextField
								id="CRITICAL4OTHER"
								label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={planData.CRITICAL4OTHER}
								fullWidth
								multiline
								rows="3"
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>	

							<hr/>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend" error={!isValid && planData.CRITICAL5.length==0}>Miten henkilökuntaa on ohjeistettu/koulutettu luomutuotteiden käyttöön liittyvistä asioista?(luomuviestintä, luomun erot tavanomaiseen (ei luomuun), luomun sekoittuminen tavanomaiseen (ei luomu) jne. </FormLabel>
								<RadioGroup aria-label="critical5" id="critical5" name="usage">
									<FormControlLabel className={classes.checkboxLabel} value="critical5-1" control={<Checkbox checked={planData.CRITICAL5.includes("critical5-1")} onChange={handleCriticalCheckboxChange} id="critical5-1" name="CRITICAL5" />} label="henkilökunnan kanssa käydään säännöllisesti läpi käytössä olevat raaka-aineet ja luomutuotteet" />
									<FormControlLabel className={classes.checkboxLabel} value="critical5-2" control={<Checkbox checked={planData.CRITICAL5.includes("critical5-2")} onChange={handleCriticalCheckboxChange} id="critical5-2" name="CRITICAL5" />} label="henkilökunnalle on kerrottu organisaation vastuullisuusstrategiasta sekä siihen liittyvästä luomutuotteiden käytöstä" />
									<FormControlLabel className={classes.checkboxLabel} value="critical5-3" control={<Checkbox checked={planData.CRITICAL5.includes("critical5-3")} onChange={handleCriticalCheckboxChange} id="critical5-3" name="CRITICAL5" />} label="henkilökunnalle on kerrottu Portaat luomuun -ohjelmasta ja sen toiminnasta " />
									<FormControlLabel className={classes.checkboxLabel} value="critical5-4" control={<Checkbox checked={planData.CRITICAL5.includes("critical5-4")} onChange={handleCriticalCheckboxChange} id="critical5-4" name="CRITICAL5" />} label="henkilökunnan kanssa on käyty läpi luomuun ja luomuviestintään liittyvät perusasiat" />
									<FormControlLabel className={classes.checkboxLabel} value="critical5-5" control={<Checkbox checked={planData.CRITICAL5.includes("critical5-5")} onChange={handleCriticalCheckboxChange} id="critical5-5" name="CRITICAL5" />} label="muuta mitä?" />
								</RadioGroup>
							</FormControl>

							<TextField
								id="CRITICAL5OTHER"
								label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={planData.CRITICAL5OTHER}
								fullWidth
								multiline
								rows="3"
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>	

							<hr/>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend" error={!isValid && planData.CRITICAL6.length==0}>Miten luomutuotteiden käytöstä kerrotaan asiakkaille?  </FormLabel>
								<RadioGroup aria-label="critical6" id="critical6" name="usage">
									<FormControlLabel className={classes.checkboxLabel} value="critical6-1" control={<Checkbox checked={planData.CRITICAL6.includes("critical6-1")} onChange={handleCriticalCheckboxChange} id="critical6-1" name="CRITICAL6" />} label="luomutuotteet on merkattu ruokalistalle" />
									<FormControlLabel className={classes.checkboxLabel} value="critical6-2" control={<Checkbox checked={planData.CRITICAL6.includes("critical6-2")} onChange={handleCriticalCheckboxChange} id="critical6-2" name="CRITICAL6" />} label="käytössä olevien luomutuotteiden listaus on näkyvillä asiakkaille ja se päivitetään säännöllisesti " />
									<FormControlLabel className={classes.checkboxLabel} value="critical6-3" control={<Checkbox checked={planData.CRITICAL6.includes("critical6-3")} onChange={handleCriticalCheckboxChange} id="critical6-3" name="CRITICAL6" />} label="asiakastiloissa, ruokalistoilla ja nettisivustolla on näkyvillä Luomua ravintola -tunnus" />
									<FormControlLabel className={classes.checkboxLabel} value="critical6-4" control={<Checkbox checked={planData.CRITICAL6.includes("critical6-4")} onChange={handleCriticalCheckboxChange} id="critical6-4" name="CRITICAL6" />} label="luomutuotteiden käytöstä kerrotaan ravintolan/ruokapalvelun nettisivustolla sekä muussa sähköisessä mediassa" />
									<FormControlLabel className={classes.checkboxLabel} value="critical6-5" control={<Checkbox checked={planData.CRITICAL6.includes("critical6-5")} onChange={handleCriticalCheckboxChange} id="critical6-5" name="CRITICAL6" />} label="muuta mitä?" />
								</RadioGroup>
							</FormControl>

							<TextField
								id="CRITICAL6OTHER"
								label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={planData.CRITICAL6OTHER}
								fullWidth
								multiline
								rows="3"
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
							/>	

							<hr/>

						</div>


					</TabPanel>
					

						
					<hr/>

					<Grid container spacing={3} className={classes.bottomGrid}>
						<Grid item xs={4}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.bottombutton1}>Tallenna</Button>
						</Grid>
						<Grid item xs={4}>
							<Button type="button" variant="contained" color="secondary" size="large" className={classes.bottombutton2} onClick={() => validateAndSaveAndSend()}>Lähetä hyväksyttäväksi</Button>
						</Grid>
						<Grid item xs={4} className={classes.bottomGridRight}>
							<LinkButton to='/dashboard' className={classes.bottombutton3}>Peruuta</LinkButton>
						</Grid>
					</Grid>	

					</form>

			</Paper>
			: 
			<Paper className={classes.paper}>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography className={classes.maintText}>Luomusuunnitelma on lähetetty hyväksyttäväksi joten sitä ei voi muokata.</Typography>
					</Grid>
				</Grid>	

				<Grid container className={classes.bottomGrid}>
					<Grid item xs={8}>
					</Grid>
					<Grid item xs={4} className={classes.bottomGridRight}>
						<LinkButton to='/dashboard' className={classes.cancelButton}>Peruuta</LinkButton>
					</Grid>
				</Grid>	
			</Paper>
			}
		
		</Container>
	);
}

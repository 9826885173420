import React, {useState, useEffect, useRef} from 'react';
import { useAuth } from './Auth/Auth'
import "moment/locale/fi";
import { makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import plconfig from './config.js'
import FileInput from './Components/FileInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { Alert, AlertTitle } from '@material-ui/lab';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Link from '@material-ui/core/Link';
import CancelIcon from '@material-ui/icons/Cancel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkButton from './Components/LinkButton'

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		marginBottom: 80
	},
	gridRight: {
		textAlign: 'right'
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(4),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginTop: 16,
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	ImportTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
		fontSize: '1.3em'
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	PaperText: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '400',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	maintText: {
		marginBottom: theme.spacing(4)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	logoutButton: {
		marginTop: 14,
		marginLeft: "auto"
	},
	commonAlert: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	productListContainer: {
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		paddingTop: theme.spacing(0),
		paddingRight: theme.spacing(3)
	},
	productList: {
		marginBottom: theme.spacing(4),
		overflow: 'auto',
		height: '40vh'
	},
	productListItem: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
	},
	productListBadge: {
		marginRight: theme.spacing(3),
	},
	tukkuList: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
		overflow: 'auto',
		height: '25vh'
	}
}));

function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string
	}
}

function calcPros(total, eko) {
	var pros = Math.ceil(((eko / total) * 100))
	if (pros>100) {
		pros = 100
	}
	if (!pros) {
		pros = 0;
	}
	return pros.toFixed(0)
};

function getImporterDate(importer) {
	var datestr = importer.datemonth + " / " + importer.dateyear
	if (importer.type === "jamix") {
		datestr = importer.created
	}
	if (!datestr) {
		datestr = "";
	}
	return datestr
};

const defaultUser = {
	id: "",
	username: ""
}

export default function KatsyDemo(props) {
	const classes = useStyles();

	const [userData, setUserData] = React.useState(defaultUser);
	const emailField = useRef(null)
	const passwordField = useRef(null)
	const [authData, setAuthData] = useState({});
	const [isValid, setIsValid] = useState(true);
	const [isLoginfailed, setLoginfailed] = useState(false);
	const [showRegister, setShowRegister] = React.useState(false);

	const [selectedImporter, setSelectedImporter] = React.useState("");

	const [importTotalCheck, setImportTotalCheck] = React.useState(true);
	const handleImportTotalCheckChange = (event) => {
		setImportTotalCheck(event.target.checked);
	};

	const [showProductsData, setShowProductsData] = React.useState(false);
	const showProductsDataHandler = (importer) => {
		setSelectedImporter(importer)
		setShowProductsData(true)
		setIsUploadError(false)
	};

	const [isUploadError, setIsUploadError] = React.useState(false);
	const [importsdb, setImportsdb] = React.useState([]);
	const [totalWeight, setTotalWeight] = React.useState(0.0);
	const [ekoWeight, setEkoWeight] = React.useState(0.0);

	const handleTextInputChange = (event) => {
		setAuthData({
			...authData,
			[event.target.id]: event.target.value
		})
    };

	const getImports = () => {
		axios.post(plconfig.APIBase + 'apps/api_katsydemo.groovy?action=info', {
			id: userData.id
		}, { headers: { "Content-Type": "text/plain" } })
		.then(function (response) {
			if (response.data.success) {
				setImportsdb(response.data)
			} else {
			}
		})
		.catch(function(error) {
		});
	};

	const calcTotalWeigths = () => {
		let tw = 0.0;
		let ew = 0.0;
		if (importsdb && importsdb.importers) {
			for (const itemindex in importsdb.importers) {
				tw = tw + importsdb.importers[itemindex].totalweight;
				ew = ew + importsdb.importers[itemindex].ekoweight;
			}
		}
		if (tw) {
			setTotalWeight(tw);
		}
		if (ew) {
			setEkoWeight(ew);
		}
	};


	const handleImport = () => {
		var noconfirm = true;
		if (selectedImporter.importedAt) {
			noconfirm = false;
		}

		if (noconfirm || confirm("Haluatko varmasti tuoda tiedot uudestaan tuontitiedoista '" + capitalizeFirstLetter(selectedImporter.type) + " " + getImporterDate(selectedImporter) + "'?")) {
			axios.post(plconfig.APIBase + 'apps/api_katsydemo.groovy?action=markused', {
				id: props.chainid,
				planid: props.planid,
				importerid: selectedImporter.id
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					props.handleImport(importTotalCheck, selectedImporter)
					handleChangeImportModalClose();
				} else {
					setIsUploadError(true)
				}
			})
			.catch(function(error) {
			});
		}
		
	};

	const deleteImporter = (importer) => {
		if (confirm("Haluatko varmasti poistaa tuontitiedot '" + capitalizeFirstLetter(importer.type) + " " + getImporterDate(importer) + "'?")) {
			axios.post(plconfig.APIBase + 'apps/api_katsydemo.groovy?action=delete', {
				id: userData.id,
				importerid: importer.id
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setShowProductsData(false)
					setSelectedImporter("")
					getImports()
				} else {
				}
			})
			.catch(function(error) {
			});
		}
		
	};

	const uploadFile = (file) => {
		setIsUploadError(false)

		let data = new FormData() 
        data.append('file', file)
		data.append('id', userData.id);
		data.append('demo', true);
		axios.post(plconfig.APIBase + 'apps/parseimports.groovy?action=upload', data)
		.then(function (response) {
			if (response.data.success) {
				getImports();
			} else {
				// show errors
				setIsUploadError(true)
			}
		})
		.catch(function(error) {
		});
	};

	const handleAuthCode = (event) => {
		event.preventDefault()

		let loginurl = plconfig.APIBase + 'apps/api_katsydemo.groovy?action=sendauth';
		// check if autofilled fields
		if (!authData.username && emailField.current) {
			setAuthData({
				...authData,
				username: emailField.current.value
			})
		}
		if (!authData.passwd && passwordField.current) {
			authData.passwd = passwordField.current.value
			setAuthData({
				...authData,
				passwd: passwordField.current.value
			})
		}

		if (authData.passwd) {
			loginurl = plconfig.APIBase + 'apps/api_katsydemo.groovy?action=login';
		}
		
		if (authData.username) {
			axios.post(loginurl, {
				username: authData.username,
				passwd: authData.passwd
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsValid(true)
					setLoginfailed(false)

					if (response.data.user) {
						setUserData(response.data.user)
					} else if (response.data.logincode) {
						setAuthData({
							...authData,
							logincode: response.data.logincode
						})
					}

				} else {
					setIsValid(false)
					setLoginfailed(true)
				}
			})
			.catch(function(error) {
				console.error(error)
				setIsValid(false)
				setLoginfailed(true)
			});

		} else {
			setIsValid(false)
		}
	}

	const handleLogin = (event) => {
		event.preventDefault()

		// check if autofilled fields
		if (!authData.username && emailField.current) {
			setAuthData({
				...authData,
				username: emailField.current.value
			})
		}
		if (!authData.passwd && passwordField.current) {
			authData.passwd = passwordField.current.value
			setAuthData({
				...authData,
				passwd: passwordField.current.value
			})
		}
		
		if (authData.username && authData.passwd) {
			axios.post(plconfig.APIBase + 'apps/api_katsydemo.groovy?action=login', {
				username: authData.username,
				passwd: authData.passwd
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsValid(true)
					setLoginfailed(false)
					setUserData(response.data.user)
				} else {
					setIsValid(false)
					setLoginfailed(true)
				}
			})
			.catch(function(error) {
				console.error(error)
				setIsValid(false)
				setLoginfailed(true)
			});

		} else {
			setIsValid(false)
		}
	}

	const logout = (event) => {
		event.preventDefault()

		setUserData(defaultUser);
		setAuthData({})
	}

	useEffect(() => {
		if (importsdb) {
			calcTotalWeigths();
		}
	}, [importsdb])

	return (
		<>
			{(userData && userData.username)
				? (
					<Container maxWidth="xl" className={classes.container}>
						
						<Grid container spacing={3} className={classes.bottomGrid2}>
							<Grid item xs={6}>
								<h4 className={classes.PaperHeader}>KÄTSY - kokeiluversio</h4>
							</Grid>
							<Grid item xs={6} className={classes.bottomGridRight}>
								<Button type="button" variant="none" color="primary" size="small" disableElevation className={classes.logoutButton} onClick={logout}>Poistu</Button>
							</Grid>
						</Grid>
						
						
						<Paper className={classes.paper}>
							<Grid container spacing={3} className={classes.bottomGrid2}>
								<Grid item xs={4}>

									<Typography className={classes.maintText}>Tällä työkalulla voit tuoda luomutuotteiden käyttötietoja eri toiminnanohjaus ohjelmista ja/tai tukkujen ostotiedoista. Lue lisää <Link href="https://www.luomuravintola.fi/katsy-ohje" target="_blank">KÄTSY-ohjesivulta</Link></Typography>
									<hr />

									<p className={classes.PaperTitle}>Tuodut siirtotiedot</p>
									<p className={classes.PaperText}>Kaikkia tuotteita yhteensä <strong>{totalWeight.toFixed(2)} kg</strong> joista luomutuotteita <strong>{ekoWeight.toFixed(2)} kg</strong>. Luomuprosentti on <strong>{calcPros(totalWeight, ekoWeight)} %</strong></p>

									<FileInput fileHandler={uploadFile} btntext="Lataa siirtotiedosto"/>

									<div className={classes.tukkuList}>
									{(importsdb && importsdb.importers) &&
										<React.Fragment>
											{importsdb.importers.map((importer) =>
												<ListItem key={importer.id} button selected={selectedImporter.id===importer.id} onClick={() => showProductsDataHandler(importer)}>
													<ListItemText 
														primary={capitalizeFirstLetter(importer.type) + " " + getImporterDate(importer)} 
														secondary={
															<React.Fragment>Yhteensä {importer.totalcount} kpl / {importer.totalweight.toFixed(0)} kg
																<ListItemSecondaryAction>
																	<IconButton edge="end" aria-label="delete" onClick={() => deleteImporter(importer)}>
																		<DeleteIcon />
																	</IconButton>
																</ListItemSecondaryAction>
															</React.Fragment>
													} />
													
												</ListItem>
											)}
										</React.Fragment>
									}
									</div>

								</Grid>
								<Grid item xs={8}>

									{isUploadError &&
										<Alert severity="alert" className={classes.commonAlert}>
											<AlertTitle>Siirtotiedoston käsittely ei onnistunut</AlertTitle>
											Katsothan tarkasti että siirtotiedoston muoto on sama kuin <Link href="https://www.luomuravintola.fi/katsy-ohje" target="_blank">KÄTSY-ohjesivulta</Link> löytyvien mallitiedostojen. Erityisesti sarakkeet täytyy olla samalla kohdalla.
										</Alert>
									}

									{showProductsData &&
										<div id="productListContainer" className={classes.productListContainer}>

											<p className={classes.ImportTitle}>{capitalizeFirstLetter(selectedImporter.type)} {getImporterDate(selectedImporter)}</p>
											<p className={classes.PaperText}>Kaikkia tuotteita yhteensä <strong>{selectedImporter.totalweight.toFixed(2)} kg</strong> joista luomutuotteita <strong>{selectedImporter.ekoweight.toFixed(2)} kg</strong>. Luomuprosentti on <strong>{calcPros(selectedImporter.totalweight, selectedImporter.ekoweight)} %</strong></p>
											<p className={classes.PaperText}>Luomutuotteet</p>
											<List component="nav" aria-label="Luomutuotteet" dense={true} className={classes.productList}>
												{(selectedImporter.productdata) &&
													<React.Fragment>
													{selectedImporter.productdata.split('\n').map((product) =>
														<ListItem key={product.id}><ListItemText className={classes.productListItem} primary={product.replace('\r', '')} /></ListItem>
													)}
													</React.Fragment>
												}
											</List>	

										</div>
									}
									
								</Grid>
							</Grid>	
						</Paper>
					</Container>
				)
				: (

					<Container maxWidth="sm" className={classes.container}>
						<h4 className={classes.PaperHeader}>KÄTSY - kokeiluversio</h4>
						<Paper className={classes.paper}>

							{(showRegister) 
							? (

								<Grid container spacing={3} className={classes.bottomGrid2}>
									<Grid item xs={12}>

									<Typography className={classes.maintText}>Rekisteröidy KÄTSY kokeiluversion käyttäjäksi.</Typography>

									<form onSubmit={handleRegister}>

										<TextField
											id="firstname"
											name="firstname"
											label="Etunimi"
											variant="outlined"
											error={!isValid && authData.firstname != ""}
											className={classes.formInput}
											type="text"
											value={authData.firstname}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
										/>	

										<TextField
											id="lastname"
											name="lastname"
											label="Sukunimi"
											variant="outlined"
											error={!isValid && authData.lastname != ""}
											className={classes.formInput}
											type="text"
											value={authData.lastname}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
										/>	

										<TextField
											id="username"
											name="username"
											label="Sähköpostiosoite"
											variant="outlined"
											error={!isValid && authData.username != ""}
											className={classes.formInput}
											type="text"
											value={authData.username}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												ref: emailField
											}}
										/>	
						
										<TextField
											id="passwd"
											name="passwd"
											label="Salasana"
											variant="outlined"
											error={!isValid && authData.passwd != ""}
											className={classes.formInput}
											type="password"
											value={authData.passwd}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												ref: passwordField
											}}
										/>		
						
										<hr/>
						
										<Grid container className={classes.bottomGrid}>
											<Grid item xs={6}>
												<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Rekisteröidy</Button>
											</Grid>
											<Grid item xs={6} className={classes.bottomGridRight}>
												<Button type="button" variant="none" color="primary" size="large" disableElevation className={classes.saveButton} onClick={() => setShowRegister(false)}>Siirry kirjautumiseen</Button>
											</Grid>
										</Grid>	
										
									</form>

									</Grid>
								</Grid>

							)
							: (

								<Grid container spacing={3} className={classes.bottomGrid2}>
									<Grid item xs={12}>

									<Typography className={classes.maintText}>Käyttääksesi KÄTSY kokeiluversiota sinun on kirjauduttava sähköpostillasi. Kirjoitettuasi sähköpostiosoitteesi saat kertakäyttöisen koodin jolla pääset kirjautumaan.</Typography>

									<form onSubmit={handleAuthCode}>

										<TextField
											id="username"
											name="username"
											label="Sähköpostiosoite"
											variant="outlined"
											error={!isValid && authData.username != ""}
											className={classes.formInput}
											type="text"
											value={authData.username}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												ref: emailField
											}}
										/>	

										{authData.logincode &&
										<TextField
											id="passwd"
											name="passwd"
											label="Aktivointikoodi"
											variant="outlined"
											error={!isValid && authData.passwd != ""}
											className={classes.formInput}
											type="text"
											value={authData.passwd}
											fullWidth
											onChange={handleTextInputChange}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												ref: passwordField
											}}
										/>	
										}
						
						
										<Grid container className={classes.bottomGrid}>
											<Grid item xs={6}>
												<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>{authData.logincode ? "Kirjaudu" : "Lähetä"}</Button>
											</Grid>
											<Grid item xs={6} className={classes.bottomGridRight}>
											</Grid>
										</Grid>	
										
										{isLoginfailed &&
											<Typography className={classes.maintText}>Käyttäjätunnusta ei löytynyt tai salasana virheellinen.</Typography>
										}

									</form>
										
									</Grid>
								</Grid>

							)
							}

						</Paper>
					</Container>

				)
			}
		</>
	);
}

import React, {useEffect} from 'react';
import axios from 'axios';
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import { DatePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinkButton from './Components/LinkButton'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	productHeader: {
		marginBottom: theme.spacing(2),
		fontWeight: 600
	},
	productForm: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	}
}));


export default function SustainableDevelopment(props) {

	const classes = useStyles();

	const [isFetchingData, setIsFetchingData] = React.useState(true);

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [sustainableDevelopmentData, setSustainableDevelopmentData] = React.useState({
		id: '',
		ADDEDVEGETABLESINMENU: false,
		FREEVEGETABLESELECTION: false,
		SEASONSINMENU: false,
		FOLLOWENERGYBYMEASUREMENT: false,
		FOLLOWWATERBYMEASUREMENT: false,
		FREEFOODLOSTSELECTION: false
	});

	const handleTextInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setSustainableDevelopmentData({
			...sustainableDevelopmentData,
			[event.target.id]: event.target.value
		});
	};

	const handleSwitchChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)
		setSustainableDevelopmentData({
			...sustainableDevelopmentData,
			[event.target.id]: event.target.checked
		});
	};

	const handleSave = (event) => {
		event.preventDefault()
		console.log(sustainableDevelopmentData)

		let isFormValid = true;

		/*
		if (sustainableDevelopmentData.companyname=="" ||
			sustainableDevelopmentData.marketingname=="" || 
			sustainableDevelopmentData.reknro=="") {
				isFormValid = false;
		}
		*/

		if (isFormValid) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=savesustainable', sustainableDevelopmentData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)
				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	}
	
	useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=infosustainable', {
				id: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsFetchingData(false)
					setSustainableDevelopmentData(response.data.organisation)
				} else {
					setIsFetchingData(true)
				}
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});
		}
	}, [props.chainid])


	return (
		<Container maxWidth="xl" className={classes.container}>

			<h4 className={classes.PaperHeader}>Kestävä kehitys</h4>

			<Paper className={classes.paper}>

				<Typography className={classes.maintText}>Luomutuotteiden käyttö on tärkeä osa ruokapalvelun kestävän kehityksen toimintoja. Mitkä muut kestävän kehityksen toiminnot teillä ovat jo arkipäivää?</Typography>

				{isSaveOk &&
				<Alert severity="success" className={classes.commonAlert}>
					<AlertTitle>Tallennettu</AlertTitle>
					Kestävän kehityksen tiedot on nyt tallennettu
				</Alert>
				}

				{isError &&
				<Alert severity="error" className={classes.commonAlert}>
					<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
					Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
				</Alert>
				}

				<form onSubmit={handleSave}>

					<div className={classes.productForm}>

						<FormControl component="fieldset" fullWidth>
							<FormLabel component="legend">Kestävän kehityksen mukainen toiminta</FormLabel>
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={sustainableDevelopmentData.ADDEDVEGETABLESINMENU} onChange={handleSwitchChange} id="ADDEDVEGETABLESINMENU" name="ADDEDVEGETABLESINMENU" />} label="olemme lisänneet kasvisten käyttöä ruokalistalla" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={sustainableDevelopmentData.FREEVEGETABLESELECTION} onChange={handleSwitchChange} id="FREEVEGETABLESELECTION" name="FREEVEGETABLESELECTION" />} label="kasviruokavaihtoehto on vapaasti valittavissa kaikille ruokailijoille" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={sustainableDevelopmentData.SEASONSINMENU} onChange={handleSwitchChange} id="SEASONSINMENU" name="SEASONSINMENU" />} label="ruokalistalla huomioidaan sesonkien vaihtelut" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={sustainableDevelopmentData.FOLLOWENERGYBYMEASUREMENT} onChange={handleSwitchChange} id="FOLLOWENERGYBYMEASUREMENT" name="FOLLOWENERGYBYMEASUREMENT" />} label="seuraamme energian kulutusta mittaamalla" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={sustainableDevelopmentData.FOLLOWWATERBYMEASUREMENT} onChange={handleSwitchChange} id="FOLLOWWATERBYMEASUREMENT" name="FOLLOWWATERBYMEASUREMENT" />} label="seuraamme veden kulutusta mittaamalla" />
							<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={sustainableDevelopmentData.FREEFOODLOSTSELECTION} onChange={handleSwitchChange} id="FREEFOODLOSTSELECTION" name="FREEFOODLOSTSELECTION" />} label="seuraamme ruokahävikkiä mittaamalla" />
						</FormControl>

						<TextField
							id="OTHERDEVELOPMENT"
							name="OTHERDEVELOPMENT"
							label="Lisätietoja kestävän kehityksen mukaisista toiminnoista"
							variant="outlined"
							className={classes.formInput}
							type="text"
							value={sustainableDevelopmentData.OTHERDEVELOPMENT}
							fullWidth
							multiline
							rows="6"
							onChange={handleTextInputChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>	


					</div>

					<Grid container className={classes.bottomGrid}>
						<Grid item xs={8}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Tallenna</Button>
						</Grid>
						<Grid item xs={4} className={classes.bottomGridRight}>
							<LinkButton to='/dashboard' className={classes.cancelButton}>Peruuta</LinkButton>
						</Grid>
					</Grid>	
					
				</form>
					
			</Paper>
			

		</Container>
	);
}


import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useAuth } from './Auth/Auth'
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ContactSupportOutlined } from '@material-ui/icons';
import LinkButton from './Components/LinkButton'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: 60
	},
	topGrid: {
		marginBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
	bottomGrid: {
		paddingBottom: theme.spacing(2),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(4),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(4	)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
    materialLink: {
	},
    commonAlert: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4)
	},
    loginButton: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4)
	},
}));


export default function Register(props) {

	const classes = useStyles();
    const [registerData, setRegisterData] = React.useState({
        NAME: '',
        Y_TUNNUS: '',
        ORGADDRESS: '',
        ZIP: '',
        CITY: '',
        ADDRESS: '',
        WWW: '',
        FIRSTNAME: '',
        LASTNAME: '',
        PHONE: '',
        EMAIL: '',
        USERNAME: '',
        PASSWORD: '',
        CANPUBLISH: false,
        AGREEWWWPUBLISH: false,
        AGREERESEARCH: false,
        AGREEMARKETING: false,
        AGREETERMS: false
    });

	const [isValid, setIsValid] = useState(true);
    const [registerFailed, setRegisterFailed] = useState(false);
    const [registerInfo, setRegisterInfo] = useState(false);
    
    const [isPasswordError, setIsPasswordError] = React.useState(false);

	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();
	let sessionStorage = window.sessionStorage;
	let token = sessionStorage.getItem('aat');
	let auth = false;

    useEffect(() => {
        token = sessionStorage.getItem('aat');

        if (isAuthenticated) {
            auth = true;
        } else {
            axios.post(plconfig.APIBase + 'apps/auth.groovy?action=check', {
                token: token
            }, { headers: { "Content-Type": "text/plain" } })
            .then(function (response) {
                if (response.data.success) {
                    auth = true;
                    setIsAuthenticated(true)
                    setUserData(response.data.user)
                    sessionStorage.setItem('aat', response.data.authtokens);
                }
            }).catch(function(error) {
            });
        }
	})

    useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=info', {
				id: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
                    setRegisterData({
                        ...registerData,
                        NAME: response.data.organisation.NAME,
                        Y_TUNNUS: response.data.organisation.Y_TUNNUS,
                        ORGADDRESS: response.data.organisation.ORGADDRESS,
                        ZIP: response.data.organisation.ZIP,
                        CITY: response.data.organisation.CITY,
                        ADDRESS: response.data.organisation.ADDRESS,
                        WWW: response.data.organisation.WWW,
                        FIRSTNAME: response.data.organisation.FIRSTNAME,
                        LASTNAME: response.data.organisation.LASTNAME,
                        PHONE: response.data.organisation.PHONE,
                        EMAIL: response.data.organisation.EMAIL
                    });
                    
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])
	
	const handleTextInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setRegisterData({
			...registerData,
			[event.target.id]: event.target.value
		});
	};

    const handleSwitchChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.checked)
		setRegisterData({
			...registerData,
			[event.target.id]: event.target.checked
		});
	};
	
	const handleRegister = (event) => {
		event.preventDefault()

        //console.log(userData)

		let isFormValid = true;

		if (registerData.NAME=="" ||
            registerData.Y_TUNNUS=="" || 
            registerData.ORGADDRESS=="" || 
            registerData.ZIP=="" || 
            registerData.CITY=="" || 
            registerData.ADDRESS=="" || 
            registerData.FIRSTNAME=="" || 
            registerData.LASTNAME=="" || 
            registerData.PHONE=="" || 
            registerData.EMAIL=="" || 
            registerData.AGREETERMS=="") {
				isFormValid = false;
		}
        if (!isAuthenticated) {
            if (registerData.USERNAME=="" || 
                registerData.PASSWORD=="") {
                    isFormValid = false;
            }
        }

        window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (isFormValid) {

            if (isAuthenticated) {
                registerData.USERID = userData.uid
            }

			axios.post(plconfig.APIBase + 'apps/register.groovy', registerData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
                    setRegisterInfo(true)
                    setRegisterFailed(false)
					setIsValid(true)

                    // add and change to created chain
                    let chainlist = [...userData.chains]
                    if (response.data.newchaindata && chainlist) {
                        chainlist.unshift(response.data.newchaindata);
                    }
                    userData.chains = chainlist
		            setUserData(userData);
                    props.handleChainChange(response.data.newchaindata)

				} else {
                    setRegisterFailed(true)
					setIsValid(true)
				}
			})
			.catch(function(error) {
                setRegisterFailed(true)
				setIsValid(true)
			});

		} else {
            setRegisterFailed(false)
			setIsValid(false)
		}
	}

    

	return (
		<Container maxWidth="md" className={classes.container}>

            {isAuthenticated 
                ? <h4 className={classes.PaperHeader}>Uuden organisaation (ryhmän) rekisteröinti</h4>
                : <h4 className={classes.PaperHeader}>Rekisteröityminen</h4>
            }
			
			<Paper className={classes.paper}>

                {registerInfo &&
                    <React.Fragment>
                        <Alert severity="success" className={classes.commonAlert}>
                            <AlertTitle>Rekisteröityminen onnistui!</AlertTitle>
                        </Alert>

                        {isAuthenticated 
                            ? <Typography className={classes.maintText}>Olet rekisteröinyt uuden organisaation/ryhmän Portaat Luomuun -ohjelmaan ja lähetimme sinulle sähköpostia, jossa on linkki kirjautumiseen.</Typography>
                            : <Typography className={classes.maintText}>Olet rekisteröitynyt Portaat Luomuun -ohjelmaan ja lähetimme sinulle sähköpostia, jossa on kirjautumiseen liittyvät tunnukset.</Typography>
                        }
                    </React.Fragment>
                }

                {!registerInfo &&
                <React.Fragment>

                    {isAuthenticated &&
                    <React.Fragment>
                        <Typography className={classes.maintText}>Rekisteröi tällä lomakkeella teidän organisaationne/yrityksenne uusi luomua käyttävä ryhmä. Uuden ryhmän alle voi liittää toimipaikkoja, jotka käyttävät samoja luomutuotteita.</Typography>
                        <Typography className={classes.maintText}>Täältä voit tarkistaa ohjelman <Link className={classes.materialLink} target="_blank" href="https://www.luomuravintola.fi/liity-mukaan-">vuosimaksun</Link>. Vuosimaksu määräytyy ohjelmassa mukana olevissa toimipaikoissa vuosittain tarjottavien annosten yhteismäärästä.</Typography>

                        <Typography className={classes.maintText}><i><small>Tähdellä (*) merkityt kentät ovat pakollisia.</small></i></Typography>
                    </React.Fragment>
                    }

                    {!isAuthenticated &&
                    <React.Fragment>
                        <Typography className={classes.maintText}><strong>Tervetuloa mukaan Portaat luomuun -ohjelmaan!</strong></Typography>
                        <Typography className={classes.maintText}>Täytä alla olevan lomakkeen tiedot. Tämän jälkeen pääset luomaan yrityksellesi luomusuunnitelman. Ohjelmaan rekisteröitymisen vaiheet, ohjelman ehdot, vuosimaksut sekä porrastasojen kriteerit löydät <Link className={classes.materialLink} target="_blank" href="https://www.luomuravintola.fi/rekisterointi-info-">täältä</Link>.</Typography>
                        <Typography className={classes.maintText}>Rekisteröitynyt organisaatio sitoutuu toimimaan ohjelman ehtojen mukaisesti.</Typography>

                        <Typography className={classes.maintText}><i><small>Tähdellä (*) merkityt kentät ovat pakollisia.</small></i></Typography>
                        </React.Fragment>
                    }
                    {!isValid &&
                        <Alert severity="error" className={classes.commonAlert}>
                            <AlertTitle>Osa pakollista tiedoista puuttuu!</AlertTitle>
                            Tarkasta että kaikki tiedot on syötetty ja kokeile uudestaan!
                        </Alert>
                    }

                    {registerFailed &&
                        <Alert severity="error" className={classes.commonAlert}>
                            <AlertTitle>Rekisteröityminen epäonnistui!</AlertTitle>
                            Odota hetki ja kokeile uudestaan. Mikäli ongelma ei poistu voit ilmoittaa asiasta <Link className={classes.materialLink} target="_blank" href="https://www.luomuravintola.fi/yhteystiedot">ohjelman ylläpitäjälle</Link>.
                        </Alert>
                    }

                    <form onSubmit={handleRegister}>

                        <TextField
                            id="NAME"
                            label="Yrityksen virallinen nimi *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.NAME}
                            fullWidth
                            helperText="Kaupparekisterin mukainen nimi"
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.NAME==""}
                        />

                        <TextField
                            id="Y_TUNNUS"
                            label="Y-Tunnus *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.Y_TUNNUS}
                            fullWidth
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.Y_TUNNUS==""}
                        />	

                        <TextField
                            id="ORGADDRESS"
                            label="Osoite *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.ORGADDRESS}
                            fullWidth
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.ORGADDRESS==""}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField
                                    id="ZIP"
                                    label="Postinumero *"
                                    variant="outlined"
                                    className={classes.formInput}
                                    type="text"
                                    value={registerData.ZIP}
                                    fullWidth
                                    onChange={handleTextInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!isValid && registerData.ZIP==""}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="CITY"
                                    label="Postitoimipaikka *"
                                    variant="outlined"
                                    className={classes.formInput}
                                    type="text"
                                    value={registerData.CITY}
                                    fullWidth
                                    onChange={handleTextInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!isValid && registerData.CITY==""}
                                />
                            </Grid>
                        </Grid>	

                        <TextField
                            id="WWW"
                            label="Organisaation internetsivut *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.WWW}
                            fullWidth
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.WWW==""}
                        />

                        <TextField
                            id="ADDRESS"
                            label="Laskutustiedot *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.ADDRESS}
                            fullWidth
                            multiline
                            rows="4"
                            onChange={handleTextInputChange}
                            helperText="Yrityksen laskutustiedot esim osoite tai verkkolaskuosoite"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.ADDRESS==""}
                        />	

                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Luvat tietojen käyttöön</FormLabel>
                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={registerData.CANPUBLISH} onChange={handleSwitchChange} id="CANPUBLISH" name="CANPUBLISH" />} label="Organisaation nimen ja yhteystiedot saa julkaista Portaat luomuun ohjelman www-sivuilla" />
                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={registerData.AGREEWWWPUBLISH} onChange={handleSwitchChange} id="AGREEWWWPUBLISH" name="AGREEWWWPUBLISH" />} label="Internetsivujen osoitteen saa julkaista luomuravintola.fi sivuilla" />
                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={registerData.AGREERESEARCH} onChange={handleSwitchChange} id="AGREERESEARCH" name="AGREERESEARCH" />} label="Yhteystietoja saa käyttää luomualan ja -markkinoiden kehittämiseen liittyvien tutkimusten ja selvitysten tekemiseen" />
                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={registerData.AGREEMARKETING} onChange={handleSwitchChange} id="AGREEMARKETING" name="AGREEMARKETING" />} label="Yhteystietoja saa käyttää kaupalliseen markkinointiin (kuten tukkutoimijoiden markkinakirjeet jne)" />
                        </FormControl>

                        <hr/>

                        <h4 className={classes.mainHeader}>Organisaation yhteyshenkilö</h4>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    id="FIRSTNAME"
                                    label="Etunimi *"
                                    variant="outlined"
                                    className={classes.formInput}
                                    type="text"
                                    value={registerData.FIRSTNAME}
                                    fullWidth
                                    onChange={handleTextInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!isValid && registerData.FIRSTNAME==""}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="LASTNAME"
                                    label="Sukunimi *"
                                    variant="outlined"
                                    className={classes.formInput}
                                    type="text"
                                    value={registerData.LASTNAME}
                                    fullWidth
                                    onChange={handleTextInputChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!isValid && registerData.LASTNAME==""}
                                />
                            </Grid>
                        </Grid>	

                        <TextField
                            id="PHONE"
                            label="Puhelin *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.PHONE}
                            fullWidth
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.PHONE==""}
                        />	

                        <TextField
                            id="EMAIL"
                            label="Sähköposti *"
                            variant="outlined"
                            className={classes.formInput}
                            type="text"
                            value={registerData.EMAIL}
                            fullWidth
                            onChange={handleTextInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!isValid && registerData.EMAIL==""}
                        />

                        <hr/>		

                        <h4 className={classes.mainHeader}>Käyttäjätunnukset</h4>

                        {isAuthenticated &&
                            <React.Fragment>
                                <Typography className={classes.maintText}>Olet kirjautunut käyttäjällä <strong>{userData.displayName}</strong> joten uusi rekisteröityminen liitetään samalle käyttäjälle.</Typography>
                            </React.Fragment>
                        }

                        {!isAuthenticated &&

                        <React.Fragment>
                            <TextField
                                id="USERNAME"
                                label="Käyttäjätunnus *"
                                variant="outlined"
                                className={classes.formInput}
                                type="text"
                                value={registerData.USERNAME}
                                fullWidth
                                onChange={handleTextInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!isValid && registerData.USERNAME==""}
                            />
                            <TextField
                                id="PASSWORD"
                                label="Salasana *"
                                variant="outlined"
                                className={classes.formInput}
                                type="password"
                                value={registerData.PASSWORD}
                                fullWidth
                                onChange={handleTextInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!isValid && registerData.PASSWORD==""}
                            />

                            <TextField
                                id="PASSWORD2"
                                name="PASSWORD2"
                                label="Salasana uudestaan *"
                                variant="outlined"
                                className={classes.formInput}
                                type="password"
                                defaultValue=""
                                autoComplete="new-password"
                                fullWidth
                                helperText="Syötä salasana toiseen kertaan oikeinkirjoituksen varmistamiseksi"
                                onChange={handleTextInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={isPasswordError}
                            />
                        </React.Fragment>

                        }

                        <FormControl component="fieldset" fullWidth>
                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={registerData.AGREETERMS} onChange={handleSwitchChange} id="AGREETERMS" name="AGREETERMS" />} label={<React.Fragment>Olen lukenut ja hyväksyn ohjelmaan liittymiseen ja toimintaan liittyvät <Link className={classes.materialLink} target="_blank" href="https://www.luomuravintola.fi/kayttoehdot">ehdot</Link></React.Fragment>} />
                        </FormControl>

                        <hr/>
        
                        <Grid container className={classes.bottomGrid}>
                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Rekisteröidy</Button>
                            </Grid>
                            <Grid item xs={6} className={classes.bottomGridRight}>
                            {isAuthenticated &&
                                <LinkButton to='/dashboard' className={classes.cancelButton}>Peruuta</LinkButton>
                            }
                            </Grid>
                        </Grid>	
                        
                    </form>


                </React.Fragment>
                }

                

			</Paper>

		</Container>
	);

}
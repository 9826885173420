import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import axios from 'axios';
import { useAuth } from './Auth/Auth'
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinkButton from './Components/LinkButton'
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import DeleteIcon from '@material-ui/icons/Delete';
import FlagIcon from '@material-ui/icons/Flag';

import Modal from '@material-ui/core/Modal';
import { Alert, AlertTitle } from '@material-ui/lab';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	logo: {
		marginRight: 8,
		marginTop: 5,
		marginBottom: 5,
		maxWidth: 60,
		height: 'auto',
		[theme.breakpoints.up('md')]: {
			maxWidth: 60,
			marginRight: 20,
		}
	},
	title: {
		display: 'block',
		marginRight: theme.spacing(5),
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8em',
			marginRight: theme.spacing(5)
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	menubutton: {
		marginTop: 3,
		marginRight: theme.spacing(3),
		fontSize: '1em',
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
		color: '#333',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8em',
			marginRight: theme.spacing(2),
		},
	},
	menubutton2: {
		marginTop: 3,
		marginRight: theme.spacing(3),
		fontSize: '0.9em',
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8em',
			marginRight: theme.spacing(2),
		}
	},
	menubutton3: {
		marginTop: 3,
		marginRight: theme.spacing(3),
		fontSize: '0.9em',
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
		color: theme.palette.primary.contrastText,
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8em',
			marginRight: theme.spacing(2),
		}
	},
	menubuttonMuted: {
		marginTop: 3,
		marginRight: theme.spacing(2),
		color: '#636363'
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 1200,
		minWidth: 800,
		minHeight: 500,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	adminbuttons: {
		position: 'fixed',
		bottom: 0,
		right: 5,
		zIndex: 9999,
		maxWidth: 260,
		textAlign: 'right'
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	fab: {
		marginBottom: theme.spacing(2),
	},
	fabDelete: {
		backgroundColor: "#fdecea",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	}
}));

export default function MainToolbar(props) {

	const classes = useStyles();
	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();

	const handleLogout = (event) => {

		let sessionStorage = window.sessionStorage;
		axios.post(plconfig.APIBase + 'apps/auth.groovy?action=logout', {
			token: sessionStorage.getItem("aat")
		}, { headers: { "Content-Type": "text/plain" } })
		.then(function (response) {
			if (response.data.success) {
			}
			setIsAuthenticated(false)
			setUserData({
				displayName : '',
				email : '',
				uid : '',
				chains: [],
				selectedChain: {
					id: null,
					name: ''
				}
			});
			sessionStorage.removeItem('aat');
			})
		.catch(function(error) {
			setIsValid(false)
			setLoginfailed(true)
		});
		
	};

	const [organisationName, setOrganisationName] = useState('');

	const [stepChangeModalOpen, setStepChangeModalOpen] = useState(false);
	const [stepChangeSaved, setStepChangeSaved] = useState(false);
	const [newStep, setNewStep] = useState('0');
	const [notifyCustomer, setNotifyCustomer] = useState(false);
	const handleStepChangeModalOpen = () => {
		setStepChangeModalOpen(true);
	};
	const handleStepChangeModalClose = () => {
		setStepChangeModalOpen(false);
	};
	const handleStepChange = (event) => {
		setNewStep(event.target.value);
	};
	const handleNotifyCustomer = (event) => {
		setNotifyCustomer(event.target.checked);
	};
	const saveStepChange = (event) => {
		event.preventDefault()

		if (confirm("Haluatko varmasti muuttaa organisaation '" + organisationName + "' portaalle '" + newStep + "'?")) {
			if (props.chainid) {
				axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=changestep', {
					id: props.chainid,
					newstep: newStep,
					notifycustomer: notifyCustomer
				}, { headers: { "Content-Type": "text/plain" } })
				.then(function (response) {
					if (response.data.success) {
						handleStepChangeModalClose()
						props.reloadchain();
					}
				})
				.catch(function(error) {
				});
			}
		}
	}

	const [adminNotesModalOpen, setAdminNotesModalOpen] = useState(false);
	const [adminNotesSaved, setAdminNotesSaved] = useState(false);
	const [adminNotes, setAdminNotes] = useState('');
	const handleAdminNotesModalOpen = () => {
		setAdminNotesModalOpen(true);
	};
	const handleAdminNotesModalClose = () => {
		setAdminNotesModalOpen(false);
	};
	const handleAdminNotesModalSave = () => {
		setAdminNotesModalOpen(false);
	};
	const handleAdminNotesTextChange = (event) => {
		setAdminNotes(event.target.value);
	};
	useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=loadnotes', {
				id: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setAdminNotes(response.data.adminnotes)
					setOrganisationName(response.data.organisationname)
					setAdminNotesSaved(false)
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])
	const saveAdminNotes = (event) => {
		event.preventDefault()
		if (props.chainid && adminNotes) {
			axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=savenotes', {
				id: props.chainid,
				notes: adminNotes
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setAdminNotesSaved(true)
				}
			})
			.catch(function(error) {
			});
		}
	}

	const deleteOrganisation = (event) => {
		event.preventDefault()
		if (props.chainid) {

			if (confirm("Haluatko varmasti poistaa organisaation '" + organisationName + "'?")) {
				
				
				axios.post(plconfig.APIBase + 'apps/organisation.groovy?action=delete', {
					id: props.chainid,
				}, { headers: { "Content-Type": "text/plain" } })
				.then(function (response) {
					if (response.data.success) {
						props.clearchain()
					}
				})
				.catch(function(error) {
				});	
				
			}
			
		}
	}

  	return (
		<div className={classes.grow}>
			<AppBar position="static" color="inherit">
				<Toolbar>
					<img className={classes.logo} src="/logo.png" alt="Luomua ravintola logo"/>
					<Typography className={classes.title} variant="h6" noWrap> | Portaat luomuun</Typography>

					{isAuthenticated &&
						<div>
							<Link to="/dashboard" className={classes.menubutton}>Etusivu</Link>
							<Link to="/organisation" className={classes.menubutton}>Organisaation tiedot</Link>
							<Link to="/locations" className={classes.menubutton}>Toimipaikat</Link>
							<Link to="/plan" className={classes.menubutton}>Luomusuunnitelma</Link>
							<Link to="/auditions" className={classes.menubutton}>Auditoinnit</Link>
							<Link to="/sustainabledevelopment" className={classes.menubutton}>Kestävä kehitys</Link>
						</div>
					}
					
					<div className={classes.grow} />
					{false &&
					<Button color="inherit" className={classes.menubuttonMuted}><ChevronLeftIcon/> luomuravintola.fi</Button>
					}
					<div className={classes.sectionDesktop}>
					{isAuthenticated &&
						<div>
							<LinkButton to='/user' variant="outlined" color="primary" className={classes.menubutton2} startIcon={<AccountCircle />}>Omat tiedot</LinkButton>
							<Button variant="contained" className={classes.menubutton3} startIcon={<ExitToAppIcon />} onClick={handleLogout}>Kirjaudu ulos</Button>
						</div>
					}
					</div>
					<div className={classes.sectionMobile}>
						<IconButton color="inherit">
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{userData.aadms && 
				<React.Fragment>
					<div className={classes.adminbuttons}>
						<Fab variant="extended" size="small" color="primary" className={classes.fab} onClick={handleStepChangeModalOpen}>
							<FlagIcon className={classes.extendedIcon} />
							Muuta porrastasoa
						</Fab>
						<Fab variant="extended" size="small" color="primary" className={classes.fab} onClick={handleAdminNotesModalOpen}>
							<SpeakerNotesIcon className={classes.extendedIcon}/>
							Muistiinpanot
						</Fab>
						<Fab variant="extended" size="small" color="error" className={classes.fabDelete} onClick={deleteOrganisation}>
							<DeleteIcon className={classes.extendedIcon} />
							Poista organisaatio
						</Fab>
					</div>
					<Modal
						open={adminNotesModalOpen}
						onClose={handleAdminNotesModalClose}>
						<div className={classes.modalPaper}>

							{adminNotesSaved &&
							<Alert severity="success" className={classes.commonAlert}>
								<AlertTitle>Tallennettu</AlertTitle>
								Ylläpitäjän muistiinpanot on nyt tallennettu
							</Alert>
							}

							<form onSubmit={saveAdminNotes}>
								<div className={classes.productForm}>
									<TextField
										id="adminnotes"
										name="adminnotes"
										label="Ylläpitäjän muistiinpanot"
										variant="outlined"
										className={classes.formInput}
										type="text"
										value={adminNotes}
										fullWidth
										multiline
										rows="17"
										onChange={handleAdminNotesTextChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>	
								</div>

								<Grid container className={classes.bottomGrid}>
									<Grid item xs={8}>
										<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Tallenna</Button>
									</Grid>
									<Grid item xs={4} className={classes.bottomGridRight}>
										<Button type="button" className={classes.cancelButton} onClick={handleAdminNotesModalClose}>Peruuta</Button>
									</Grid>
								</Grid>	

							</form>
						</div>
					</Modal>
					<Modal
						open={stepChangeModalOpen}
						onClose={handleStepChangeModalClose}>
						<div className={classes.modalPaper}>

							{stepChangeSaved &&
							<Alert severity="success" className={classes.commonAlert}>
								<AlertTitle>Tallennettu</AlertTitle>
								Organisaation on nyt siirretty portaalle {newStep}
							</Alert>
							}

							<form onSubmit={saveStepChange}>
								<div className={classes.productForm}>
									<FormControl component="fieldset" fullWidth>
										<FormLabel component="legend">Siirrä portaalle</FormLabel>
										<RadioGroup aria-label="newStep" value={newStep} onChange={handleStepChange}>
											<FormControlLabel control={<Radio id="newStep1" checked={newStep === '1'} value="1"/>} label="1" />
											<FormControlLabel control={<Radio id="newStep2" checked={newStep === '2'} value="2"/>} label="2" />
											<FormControlLabel control={<Radio id="newStep3" checked={newStep === '3'} value="3"/>} label="3" />
											<FormControlLabel control={<Radio id="newStep4" checked={newStep === '4'} value="4"/>} label="4" />
											<FormControlLabel control={<Radio id="newStep5" checked={newStep === '5'} value="5"/>} label="5" />
											<FormControlLabel control={<Radio id="newStep6" checked={newStep === '6'} value="6"/>} label="Tähti" />
										</RadioGroup>
									</FormControl>

									<FormControl component="fieldset" fullWidth>
										<FormLabel component="legend">Lähetetäänkö viesti asiakkaalle muutoksesta</FormLabel>
										<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={notifyCustomer} onChange={handleNotifyCustomer} id="notifyCustomer" name="notifyCustomer" />} label="Lähetä muutoksesta sähköposti ilmoitus asiakkaalle" />
									</FormControl>

								</div>

								<Grid container className={classes.bottomGrid}>
									<Grid item xs={8}>
										<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Siirrä</Button>
									</Grid>
									<Grid item xs={4} className={classes.bottomGridRight}>
										<Button type="button" className={classes.cancelButton} onClick={handleStepChangeModalClose}>Peruuta</Button>
									</Grid>
								</Grid>	

							</form>
						</div>
					</Modal>
				</React.Fragment>
			}
		</div>
	);
}
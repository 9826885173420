import React, {useEffect} from 'react';
import axios from 'axios';
import moment from "moment";
import "moment/locale/fi";
import { useAuth } from './Auth/Auth'
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import { DatePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText  from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinkButton from './Components/LinkButton'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	productHeader: {
		marginBottom: theme.spacing(2),
		fontWeight: 600
	},
	productForm: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	okBadge: {
		marginRight: theme.spacing(6),
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	}
}));


export default function Auditions(props) {

	const classes = useStyles();
	const preventDefault = (event) => event.preventDefault();

	const [isAdminUser, setIsAdminUser] = React.useState(false);

	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();

	const [isFetchingData, setIsFetchingData] = React.useState(true);

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [selectedAuditionData, setSelectedAuditionData] = React.useState({
		id: '',
		customeragreement: false,
		agreement: false
	});
	const [auditions, setAuditions] = React.useState([])

	const handleTextInputChange = (event) => {
		setSelectedAuditionData({
			...selectedAuditionData,
			[event.target.id]: event.target.value
		});
	};
	const handleSelectChange = (event) => {
		setSelectedAuditionData({
			...selectedAuditionData,
			[event.target.name]: event.target.value
		});
    };
    const handleSwitchChange = (event) => {
        setSelectedAuditionData({
			...selectedAuditionData,
			[event.target.id]: event.target.checked
		});
	};
	const handlePublicPrivateChange = (event) => {
		setSelectedAuditionData({
			...selectedAuditionData,
			[event.target.id]: event.target.value
		});
	};
	const handleDateChange = (date) => {
		setSelectedAuditionData({
			...selectedAuditionData,
			["DATE"]: date
		});
	};

	useEffect(() => {
		if (props.chainid) {
			axios.post(plconfig.APIBase + 'apps/audition.groovy?action=info', {
				id: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsFetchingData(false)

					// correct dates 
					for (const audition in response.data.auditions) {
						var d = response.data.auditions[audition].DATE2
						response.data.auditions[audition].DATESTR = response.data.auditions[audition].DATE2
						response.data.auditions[audition].DATE = moment(d, "DD.MM.YYYY")
						//console.log(d)
						//console.log(response.data.auditions[audition].DATE)
					}

					setAuditions(response.data.auditions)
					setSelectedAuditionData({
						id: '',
						customeragreement: false,
						agreement: false
					})
					if (userData.aadms) {
						setIsAdminUser(true);
					}

				} else {
					setIsFetchingData(true)
				}
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});
		}
	}, [props.chainid])

	const selectAudition = (audition) => {
		setSelectedAuditionData(audition)
		//console.log(audition)
	};

	const addAudition = () => {
		let dstr = moment().format("M.D.YYYY");
		let dstr2 = moment().format("D.M.YYYY");
		if (auditions || (auditions[0] && auditions[0].id!=="new")) {
			let audition = {
				NAME: "Uusi auditoitu organisaatio",
				AGREEMENT: false,
				AGREEMENTCUSTOMER: false,
				DATE: new Date(),
				DATESTR: dstr2,
				PLACE: "",
				PERSONS: "",
				MEAN: "",
				PROGRESS: "",
				ORGSPECIAL: "",
				FINDINGS: "1. Tietojen paikkansapitävyys\n\n\n2. Luomun käytön luotettavuus\n\n\n3. Luomusta viestiminen\n\n\n",
				AGREEDDATE: null,
				AGREEDSEND: false,
				COMMENTS: "",
				CUSTOMERCOMMENTS: "",
				id: "new",
				CHAINID: props.chainid
			}
			setSelectedAuditionData(audition)
		}
	};


	

	const saveAudition = (event) => {
		event.preventDefault()
		//console.log(userDetailData)

		let isFormValid = true;

		if (selectedAuditionData.NAME=="" ||
			selectedAuditionData.PLACE=="") {
				isFormValid = false;
		}

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (isFormValid) {

			// correct date
			selectedAuditionData.DATE2 = moment(selectedAuditionData.DATE).format("DD.MM.YYYY")
			//console.log(selectedAuditionData.DATE2)

			axios.post(plconfig.APIBase + 'apps/audition.groovy?action=save', selectedAuditionData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)

					// update list
					let found = false;
					let auditionlist =  [...auditions]
					if (auditionlist) {
						for (const audition in auditionlist) {
							if (response.data.audition.id==auditionlist[audition].id) {
								found = true;
							}
						}
					}
					// if it's new audition
					if (!found) {
						auditionlist.push(response.data.audition)
					}
					setAuditions(auditionlist);
					

				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	};

	const deleteAudition = (audition) => {
		let found = -1;
		let loclist =  [...auditions]
		if (loclist) {
			for (const loc in loclist) {
				if (audition.id==loclist[loc].id) {
					found = loc;
				}
			}
		}
		// found it so remove
		if (found>-1) {
			if (confirm("Haluatko varmasti poistaa auditoinnin organisaatiolta '" + audition.NAME + "'?")) {

				axios.post(plconfig.APIBase + 'apps/audition.groovy?action=delete', audition, { headers: { "Content-Type": "text/plain" } })
				.then(function (response) {
					if (response.data.success) {
						loclist.splice(found, 1);
					}
				})
				.catch(function(error) {
				});
					
			}
		}
		setAuditions(loclist);
	};

	const auditionslist = auditions.map((audition) =>
		<ListItem key={audition.id} button selected={selectedAuditionData.id === audition.id} onClick={(e) => {selectAudition(audition)}}>
			<ListItemText primary={"Auditointi " + audition.DATESTR} />
			{(audition.AGREEMENT && audition.CUSTOMERAGREEMENT) &&<Badge className={classes.okBadge} color="secondary" badgeContent={'Hyväksytty'} />}
			{(isAdminUser) &&
				<ListItemSecondaryAction>
					<IconButton edge="end" aria-label="delete" onClick={(e) => {deleteAudition(audition)}}><DeleteIcon style={{ color: '#fff' }}/></IconButton>
				</ListItemSecondaryAction>
			}
			</ListItem>
	);


	return (
		<Container maxWidth="xl" className={classes.container}>

			<h4 className={classes.PaperHeader}>Auditoinnit</h4>

			<Paper className={classes.paper}>

				<Typography className={classes.maintText}>Auditoinnin tarkoitus on vahvistaa Portaat luomuun- ohjelman luotettavuutta sekä kehittää edelleen ohjelmaa vastaamaan ammattikeittiöiden käytännön tarpeita. Auditoinnissa keskitytään kolmeen asiaan: tietojen paikkansapitävyyteen, luomun käytön luotettavuuteen, luomusta viestimiseen. Ohjelmaan liittynyt organisaatio voi tilata auditointikäynnin Portaat luomuun -hankepäälliköltä, Anu Arolaaksolta. Auditointikäyntiä varten voitte käydä läpi <a href="https://luomuravintola.fi/tarkistuslista" target="_blank">tarkistuslistan</a>, joka helpottaa ja nopeuttaa asioiden käsittelyä auditointitilanteessa. Tarkistuslista auttaa myös luomusuunnitelman tietojen päivittämisessä.</Typography>

				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography className={classes.productHeader}>Auditoinnit</Typography>
						{isAdminUser &&
							<Button variant="outlined" color="primary" className={classes.addNewButton} onClick={addAudition}>Uusi auditointi</Button>
						}
						<List component="nav" aria-label="Auditoinnit" dense={false}>
							{auditionslist}
						</List>
					</Grid>
					<Grid item xs={8}>

						{selectedAuditionData.id!=='' &&

							<div className={classes.productForm}>

								{isSaveOk &&
								<Alert severity="success" className={classes.commonAlert}>
									<AlertTitle>Tallennettu</AlertTitle>
									Auditoinnin tiedot on nyt tallennettu
								</Alert>
								}

								{isError &&
								<Alert severity="error" className={classes.commonAlert}>
									<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
									Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
								</Alert>
								}

								<form onSubmit={saveAudition}>

									<h4 className={classes.mainHeader}>Auditointi {selectedAuditionData.DATESTR}</h4>

									<TextField
										id="NAME"
										label="Auditoitu keittiö *"
										variant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										type="text"
										value={selectedAuditionData.NAME}
										fullWidth
										onChange={handleTextInputChange}
										InputProps={{
											readOnly: !isAdminUser,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										error={!isValid && (selectedAuditionData.NAME=="" || selectedAuditionData.NAME==null)}
									/>

									<DatePicker
										id="DATE"
										label="Auditointipäivä"
										inputVariant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										value={selectedAuditionData.DATE}
										onChange={handleDateChange}
										animateYearScrolling
										format="D.M.YYYY"
										readOnly={!isAdminUser}
									/>

									<TextField
										id="PLACE"
										label="Paikka *"
										variant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										type="text"
										value={selectedAuditionData.PLACE}
										fullWidth
										onChange={handleTextInputChange}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											readOnly: !isAdminUser,
										}}
										error={!isValid && (selectedAuditionData.PLACE=="" || selectedAuditionData.PLACE==null)}
									/>

									<TextField
										id="PERSONS"
										label="Läsnä *"
										variant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										type="text"
										value={selectedAuditionData.PERSONS}
										fullWidth
										onChange={handleTextInputChange}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											readOnly: !isAdminUser,
										}}
										error={!isValid && (selectedAuditionData.PERSONS=="" || selectedAuditionData.PERSONS==null)}
									/>

									<TextField
										id="PROGRESS"
										label="Auditoinnin kulku *"
										variant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										type="text"
										value={selectedAuditionData.PROGRESS}
										fullWidth
										multiline
										rows="4"
										onChange={handleTextInputChange}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											readOnly: !isAdminUser,
										}}
										error={!isValid && (selectedAuditionData.PROGRESS=="" || selectedAuditionData.PROGRESS==null)}
									/>

									<TextField
										id="ORGSPECIAL"
										label="Organisaation toiminnan erityispiirteet *"
										variant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										type="text"
										value={selectedAuditionData.ORGSPECIAL}
										fullWidth
										multiline
										rows="4"
										onChange={handleTextInputChange}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											readOnly: !isAdminUser,
										}}
										error={!isValid && (selectedAuditionData.ORGSPECIAL=="" || selectedAuditionData.ORGSPECIAL==null)}
									/>

									<hr/>

									<h4 className={classes.mainHeader}>Auditoinnin tulos</h4>

									{!isAdminUser ?
										<React.Fragment>
											{selectedAuditionData.FINDINGS &&
												<div dangerouslySetInnerHTML={{__html: selectedAuditionData.FINDINGS + '<br/><br/>'}} />
											}
										</React.Fragment>
									:
										<TextField
										id="FINDINGS"
										label="Tulos *"
										variant={!isAdminUser ? "standard" : "outlined"}
										className={classes.formInput}
										type="text"
										value={selectedAuditionData.FINDINGS}
										fullWidth
										multiline
										rows="12"
										onChange={handleTextInputChange}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											readOnly: !isAdminUser,
										}}
									/>
									}
									

									{selectedAuditionData.id!=='new' &&
										<React.Fragment>

											<TextField
												id="CUSTOMERCOMMENTS"
												label="Organisaation edustajan kommentit"
												variant={(!selectedAuditionData.AGREEMENT || isAdminUser) ? "outlined" : "standard"}
												className={classes.formInput}
												type="text"
												value={selectedAuditionData.CUSTOMERCOMMENTS}
												fullWidth
												multiline
												rows="6"
												onChange={handleTextInputChange}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													readOnly: (selectedAuditionData.AGREEMENT || !isAdminUser),
												}}
											/>

											<TextField
												id="COMMENTS"
												label="Ylläpitäjän kommentit"
												variant={!isAdminUser ? "standard" : "outlined"}
												className={classes.formInput}
												type="text"
												value={selectedAuditionData.COMMENTS}
												fullWidth
												multiline
												rows="6"
												onChange={handleTextInputChange}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													readOnly: !isAdminUser,
												}}
											/>

											<hr/>

											<h4 className={classes.mainHeader}>Auditoinnin hyväksyntä</h4>

											<FormControl component="fieldset" fullWidth>
												{!isAdminUser 
												? 
												<React.Fragment>
													<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={selectedAuditionData.AGREEMENTCUSTOMER} onChange={handleSwitchChange} id="AGREEMENTCUSTOMER" name="AGREEMENTCUSTOMER" />} label="Hyväksyn auditoinnin organisaation edustajana" />
													
												</React.Fragment>
												: 
												<React.Fragment>
													{selectedAuditionData.CUSTOMERAGREEMENT &&
														<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={true} />} label="Hyväksyn auditoinnin organisaation edustajana" />
													}
													<FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={selectedAuditionData.AGREEMENT} onChange={handleSwitchChange} id="AGREEMENT" name="AGREEMENT" />} label="Hyväksyn auditoinnin Portaat luomuun ohjelman edustajana" />
												</React.Fragment>
												}
											</FormControl>
										</React.Fragment>
									}

									<hr/>

									{(!selectedAuditionData.AGREEMENT || isAdminUser) &&
										<Grid container className={classes.bottomGrid}>
											<Grid item xs={8}>
												<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Tallenna</Button>
											</Grid>
										</Grid>	
									}

								</form>

							</div>
						}

					</Grid>
				</Grid>	

					
				

				
			</Paper>
			

		</Container>
	);
}

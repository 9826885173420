import React, {useState, useEffect, useRef} from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useAuth } from './Auth/Auth'
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: 60
	},
	topGrid: {
		marginBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
	bottomGrid: {
		paddingBottom: theme.spacing(4),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
    bottomGridTypo: {
        marginTop: theme.spacing(2),
    },
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(3),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(4),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(4)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
    materialLink: {
	},
    commonAlert: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4)
	},
    loginButton: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4)
	},
    answerBox: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
        fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
    answerButton: {
        marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        border: "1px solid #999",
        borderRadius: 12,
        cursor: "pointer",
        fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
        transition: "all 0.2s ease",
        '& p': {
            marginBottom: 0,
        },
        '&:hover': {
            backgroundColor: "#38b559",
            borderColor: "#333",
            color: "#fff"
        },
        '&.selected': {
            backgroundColor: "#38b559",
            borderColor: "#999",
            color: "#fff"
        }
    },
    answerButtonSelected: {
        marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        border: "1px solid #38b559",
        borderRadius: 12,
        cursor: "pointer",
        fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
        backgroundColor: "#38b559",
        borderColor: "#999",
        color: "#fff",
        transition: "all 0.2s ease",
        '& p': {
            marginBottom: 0,
        },
        '&:hover': {
            backgroundColor: "#38b559",
            borderColor: "#333",
            color: "#fff"
        }
    },
    answerButtonCorrect: {
        marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        border: "1px solid #4caf50",
        borderRadius: 12,
        fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
        color: "#4caf50",
        transition: "all 0.2s ease",
        '& p': {
            marginBottom: 0,
        }
    },
    answerButtonInCorrect: {
        marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        border: "1px solid #af4c4c",
        borderRadius: 12,
        fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
        color: "#af4c4c",
        transition: "all 0.2s ease",
        '& p': {
            marginBottom: 0,
        }
    }

}));


export default function Exam(props) {

    const { organisationid } = useParams();

	const classes = useStyles();
    const [examData, setExamData] = React.useState({});
    const [commentData, setCommentData] = React.useState({
        EMAIL: ''
    });

    const [organisationData, setOrganisationData] = React.useState({});

    const [nameInitialized, setNameInitialized] = React.useState(false);
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');

    const [initialized, setInitialized] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [passed, setPassed] = React.useState(false);
    const [isShaking, setIsShaking] = React.useState(false);

    const [isValid, setIsValid] = useState(true);
    const [commentDone, setCommentDone] = useState(false);
    const [commentFailed, setCommentFailed] = useState(false);

    const [correctCount, setCorrectCount] = React.useState(0);

    const [totalcount, setTotalcount] = React.useState(0);
    const [progresscount, setProgresscount] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [examDone, setExamDone] = React.useState(false);

    const [alocked, setALocked] = React.useState(false);
    const [blocked, setBLocked] = React.useState(false);
    const [clocked, setCLocked] = React.useState(false);
    const [dlocked, setDLocked] = React.useState(false);

    const [acorrect, setACorrect] = React.useState(false);
    const [bcorrect, setBCorrect] = React.useState(false);
    const [ccorrect, setCCorrect] = React.useState(false);
    const [dcorrect, setDCorrect] = React.useState(false);

    useEffect(() => {
        if (!initialized) {
            axios.post(plconfig.APIBase + 'apps/exam.groovy?action=get', {}, { headers: { "Content-Type": "text/plain" } })
            .then(function (response) {
                if (response.data.success) {
                    //console.log(response.data)
                    setInitialized(true)
                    setExamData(response.data.question)
                    setTotalcount(response.data.count)
                }
            })
            .catch(function(error) {
            });
        }
	})

    useEffect(() => {
        if (organisationid) {
            axios.post(plconfig.APIBase + 'apps/exam.groovy?action=getorganisation', {
                id: organisationid
            }, { headers: { "Content-Type": "text/plain" } })
            .then(function (response) {
                if (response.data.success) {
                    setOrganisationData(response.data.organisation)
                    setCommentData({
                        NAME: response.data.organisation.NAME,
                        FIRSTNAME: '',
                        LASTNAME: '',
                        COMMENTS: ''
                    })
                }
            })
            .catch(function(error) {
            });
        }
	}, [organisationid])

    useEffect(() => {
        if (examDone) {
            axios.post(plconfig.APIBase + 'apps/exam.groovy?action=done', {
                id: organisationid,
                firstname: firstname,
                lastname: lastname
            }, { headers: { "Content-Type": "text/plain" } })
            .then(function (response) {
                if (response.data.success) {
                    //console.log(response.data)
                }
            })
            .catch(function(error) {
            });
        }
	}, [examDone])
    

    const lockAnswer = (name) => {
        if (!passed) {

            setChecked(false)

            if (examData.ONESELECT) {
                setALocked(false)
                setBLocked(false)
                setCLocked(false)
                setDLocked(false)
            }

            if (name=='a') {
                setALocked(!alocked)
            } else if (name=='b') {
                setBLocked(!blocked)
            } else if (name=='c') {
                setCLocked(!clocked)
            } else if (name=='d') {
                setDLocked(!dlocked)
            }
        }
    }

    const checkAnswer = (event) => {
        event.preventDefault()

        setIsShaking(false)

        axios.post(plconfig.APIBase + 'apps/exam.groovy?action=check', {
            id: examData.id
        }, { headers: { "Content-Type": "text/plain" } })
        .then(function (response) {
            if (response.data.success) {
                //console.log(response.data)
                setChecked(true)

                var isAllCorrect = true;
                var correctCountInt = 0;

                if (response.data.answer1==alocked) {
                    setACorrect(true)
                    correctCountInt++;
                } else {
                    setACorrect(false)
                    isAllCorrect = false;
                }
                if (response.data.answer2==blocked) {
                    setBCorrect(true)
                    correctCountInt++;
                } else {
                    setBCorrect(false)
                    isAllCorrect = false;
                }
                if (response.data.answer3==clocked) {
                    setCCorrect(true)
                    correctCountInt++;
                } else {
                    setCCorrect(false)
                    isAllCorrect = false;
                }
                if (response.data.answer4==dlocked) {
                    setDCorrect(true)
                    correctCountInt++;
                } else {
                    setDCorrect(false)
                    isAllCorrect = false;
                }

                if (isAllCorrect) {
                    setPassed(true)
                    setCorrectCount(0)

                } else {
                    if (!examData.ONESELECT) {
                        setALocked(false)
                        setBLocked(false)
                        setCLocked(false)
                        setDLocked(false)
                    }
                    setIsShaking(true)
                    setCorrectCount(correctCountInt)
                }
            }
        })
        .catch(function(error) {
        });
        
    }

    const nextQuestion = (event) => {
        event.preventDefault()

        if (examData.NEXT) {

            axios.post(plconfig.APIBase + 'apps/exam.groovy?action=get', {
                id: examData.NEXT
            }, { headers: { "Content-Type": "text/plain" } })
            .then(function (response) {
                if (response.data.success) {
                    //console.log(response.data)
                    setALocked(false)
                    setBLocked(false)
                    setCLocked(false)
                    setDLocked(false)
                    setChecked(false)
                    setACorrect(false)
                    setBCorrect(false)
                    setCCorrect(false)
                    setDCorrect(false)
                    setPassed(false)
                    setExamData(response.data.question)

                    // calc progress
                    let newproggress = (progresscount+1)*100/(totalcount)
                    setProgresscount(progresscount+1)
                    setProgress(Math.round(newproggress))
                }
            })
            .catch(function(error) {
            });

        } else {
            setExamDone(true)
        }
        
    }

    const initName = (event) => {
        event.preventDefault()
        if (firstname && lastname) {
            setNameInitialized(true)
        } else {
            setNameInitialized(false)
        }
    }

    const handleComments = (event) => {
		event.preventDefault()

        //console.log(commentData)
		let isFormValid = true;
		if (commentData.EMAIL=="") {
			isFormValid = false;
		}

		if (isFormValid) {
			axios.post(plconfig.APIBase + 'apps/exam.groovy?action=join', commentData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
                    setCommentDone(true)
                    setCommentFailed(false)
					setIsValid(true)
				} else {
                    setCommentFailed(true)
					setIsValid(true)
				}
			})
			.catch(function(error) {
                setCommentFailed(true)
				setIsValid(true)
			});

		} else {
            setCommentFailed(false)
			setIsValid(false)
		}
	}

    const handleTextInputChange = (event) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setCommentData({
			...commentData,
			[event.target.id]: event.target.value
		});
	};

    const handleFirstnameInputChange = (event) => {
		setFirstname(event.target.value);
        setCommentData({
			...commentData,
			FIRSTNAME: event.target.value
		});
	};
    const handleLastnameInputChange = (event) => {
		setLastname(event.target.value);
        setCommentData({
			...commentData,
			LASTNAME: event.target.value
		});
	};

	return (
		<Container maxWidth="md" className={classes.container}>

            {(organisationData.NAME) 
            ? <h4 className={classes.PaperHeader}>Luomutesti - {organisationData.NAME}</h4>
            : <h4 className={classes.PaperHeader}>Luomutesti</h4>
            }

			<Paper className={classes.paper}>

            {(!nameInitialized && organisationData.NAME) &&
                <React.Fragment>

                    <Typography className={classes.maintText}>Aloita kirjoittamalla nimesi</Typography>

                    <TextField
                        id="firstname"
                        label="Etunimi"
                        variant="outlined"
                        className={classes.formInput}
                        type="text"
                        value={firstname}
                        fullWidth
                        onChange={handleFirstnameInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="lastname"
                        label="Sukunimi"
                        variant="outlined"
                        className={classes.formInput}
                        type="text"
                        value={lastname}
                        fullWidth
                        onChange={handleLastnameInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <hr/>
                        
                    <Grid container className={classes.bottomGrid}>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6} className={classes.bottomGridRight}>
                            <Button type="submit" variant="contained" color="primary" onClick={initName} size="large" className={classes.saveButton}>Seuraava</Button>
                        </Grid>
                    </Grid>	


                </React.Fragment>
            }

            {(nameInitialized || !organisationData.NAME) &&
                <React.Fragment>

                    {(examDone) ?
                        <React.Fragment>
                            <h4 className={classes.mainHeader}>Luomutesti suoritettu!</h4>	

                            {(organisationData.NAME) 
                            ? <Typography className={classes.maintText}>Hyvin tehty! Sinulla on jo luomu hyvin hallussa. Suorituksesi tallennetaan teidän oman organisaationne Portaat luomuun -lomakkeelle. </Typography>
                            : <Typography className={classes.maintText}>Hyvin tehty! Sinulla on jo luomu hyvin hallussa. </Typography>
                            }

                            {(!organisationData.NAME) && 
                            <>
                                <hr/>

                                {commentDone &&
                                    <React.Fragment>
                                        <Alert severity="success" className={classes.commonAlert}>
                                            <AlertTitle>Kiitos! Sähköpostiosoitteesi on liitetty kuukausikirjeen tilaajaksi.</AlertTitle>
                                        </Alert>
                                    </React.Fragment>
                                }

                                {!commentDone &&
                                <React.Fragment>

                                    {!isValid &&
                                        <Alert severity="error" className={classes.commonAlert}>
                                            <AlertTitle>Syötäthän sähköpostiosoitteen!</AlertTitle>
                                        </Alert>
                                    }

                                    {commentFailed &&
                                        <Alert severity="error" className={classes.commonAlert}>
                                            <AlertTitle>Liittymisen käsittely epäonnistui!</AlertTitle>
                                            Odota hetki ja kokeile uudestaan. Mikäli ongelma ei poistu voit ilmoittaa asiasta <Link className={classes.materialLink} target="_blank" href="https://www.luomuravintola.fi/yhteystiedot">ohjelman ylläpitäjälle</Link>.
                                        </Alert>
                                    }

                                </React.Fragment>
                                }

                                {!commentDone &&
                                <form onSubmit={handleComments}>

                                    <Typography className={classes.maintText}>Haluatko tietää enemmän? Luomutietoa sähköpostiisi kerran kuussa: tietoa luomusta ja luomutuotteista sekä luomua käyttävien ravintoloiden kuulumisia. Tilaa kuukausikirje:</Typography>
                                    <TextField
                                        id="EMAIL"
                                        label="Syötä sähköposti"
                                        variant="outlined"
                                        className={classes.formInput}
                                        type="text"
                                        value={commentData.EMAIL}
                                        fullWidth
                                        onChange={handleTextInputChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!isValid && commentData.EMAIL==""}
                                    />

                                    <Button type="submit" variant="contained" color="primary" className={classes.saveButton}>Tilaa</Button>
                                
                                </form>
                                }

                            </>
                            }


                        </React.Fragment>
                    :
                        <React.Fragment>

                            {(examData && initialized) &&
                                <div className={isShaking ? 'shake' : ''}>
                                    <h4 className={classes.mainHeader}>{examData.NAME}</h4>	
                                    
                                    <div className={(checked && alocked) ? (acorrect ? classes.answerButtonCorrect : classes.answerButtonInCorrect) : (alocked ? classes.answerButtonSelected : classes.answerButton)}  onClick={() => lockAnswer('a')} dangerouslySetInnerHTML={{__html: examData.Q1}}></div>
                                    {(checked && !examData.ONEANSWER && alocked) &&
                                        <div className={classes.answerBox} dangerouslySetInnerHTML={{__html: examData.A1}}></div>
                                    }

                                    <div className={(checked && blocked) ? (bcorrect ? classes.answerButtonCorrect : classes.answerButtonInCorrect) : (blocked ? classes.answerButtonSelected : classes.answerButton)}  onClick={() => lockAnswer('b')} dangerouslySetInnerHTML={{__html: examData.Q2}}></div>
                                    {(checked && !examData.ONEANSWER && blocked) &&
                                        <div className={classes.answerBox} dangerouslySetInnerHTML={{__html: examData.A2}}></div>
                                    }

                                    <div className={(checked && clocked) ? (ccorrect ? classes.answerButtonCorrect : classes.answerButtonInCorrect) : (clocked ? classes.answerButtonSelected : classes.answerButton)}  onClick={() => lockAnswer('c')} dangerouslySetInnerHTML={{__html: examData.Q3}}></div>
                                    {(checked && !examData.ONEANSWER && clocked) &&
                                        <div className={classes.answerBox} dangerouslySetInnerHTML={{__html: examData.A3}}></div>
                                    }

                                    <div className={(checked && dlocked) ? (dcorrect ? classes.answerButtonCorrect : classes.answerButtonInCorrect) : (dlocked ? classes.answerButtonSelected : classes.answerButton)}  onClick={() => lockAnswer('d')} dangerouslySetInnerHTML={{__html: examData.Q4}}></div>
                                    {(checked && !examData.ONEANSWER && dlocked) &&
                                        <div className={classes.answerBox} dangerouslySetInnerHTML={{__html: examData.A4}}></div>
                                    }

                                    {(passed && examData.ONEANSWER) &&
                                        <div className={classes.answerBox} dangerouslySetInnerHTML={{__html: examData.A1}}></div>
                                    }

                                    <hr/>
                        
                                    <Grid container className={classes.bottomGrid}>
                                        <Grid item xs={6}>
                                            {(!passed) &&
                                                <React.Fragment>
                                                    <Button type="submit" variant="contained" color="primary" onClick={checkAnswer} size="large" className={classes.saveButton}>Vastaa</Button>
                                                    {(checked && !examData.ONESELECT) &&
                                                        <Typography className={classes.bottomGridTypo}>Kokeile vielä toista vaihtoehtoa.</Typography>
                                                    }
                                                    {(checked && examData.ONESELECT) &&
                                                        <Typography className={classes.bottomGridTypo}>Sait {correctCount} oikein. Kokeile vielä tarkentaa vastausta.</Typography>
                                                    }
                                                </React.Fragment>
                                            }
                                        </Grid>
                                        <Grid item xs={6} className={classes.bottomGridRight}>
                                            {(checked && passed) &&
                                                <Button type="submit" variant="contained" color="primary" onClick={nextQuestion} size="large" className={classes.saveButton}>Seuraava</Button>
                                            }
                                        </Grid>
                                    </Grid>	

                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
                                        </Box>
                                    </Box>

                                    
                                </div>
                            }
                        </React.Fragment>
                    }

                </React.Fragment>
            }

			</Paper>

		</Container>
	);

}

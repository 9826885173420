import React, {useEffect} from 'react';
import { useAuth } from './Auth/Auth'
import axios from 'axios';
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinkButton from './Components/LinkButton'
import { Alert, AlertTitle } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: theme.spacing(5)
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	bottomGrid: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	saveButton: {
		marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: '#fff'
    },
    cancelButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	mainHeader: {
		marginBottom: theme.spacing(3),
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '300',
	},
	maintText: {
		marginBottom: theme.spacing(3)
	},
	formInput: {
		marginBottom: theme.spacing(3)
	},
	commonAlert: {
		marginBottom: theme.spacing(4)
	}
}));

export default function User(props) {

	const classes = useStyles();

	const {userData} = useAuth();
	const [isFetchingData, setIsFetchingData] = React.useState(true);

	const [isValid, setIsValid] = React.useState(true);
	const [isSaveOk, setIsSaveOk] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [isPasswordError, setIsPasswordError] = React.useState(false);
	const [userDetailData, setUserDetailData] = React.useState({
		email: "",
		firstname: "",
		id: "",
		lastname: "",
		phone: "",
		username: ""
	});

	const handleTextInputChange = (event) => {
		setUserDetailData({
			...userDetailData,
			[event.target.id]: event.target.value
		});
    };
	
	const handleSave = (event) => {
		event.preventDefault()
		//console.log(userDetailData)

		let isFormValid = true;
		let isPasswordValid = true;

		if (userDetailData.firstname=="" ||
			userDetailData.lastname=="" || 
			userDetailData.username=="") {
				isFormValid = false;
		}
		if ((userDetailData.password!=="" && userDetailData.password2!=="") && userDetailData.password!==userDetailData.password2) {
			isPasswordValid = false;
			isFormValid = false;
		}

		setIsPasswordError(!isPasswordValid)

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

		if (isFormValid) {
			axios.post(plconfig.APIBase + 'apps/user.groovy?action=saveuser', userDetailData, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsError(false)
					setIsSaveOk(true)
					setIsValid(true)
				} else {
					setIsSaveOk(false)
					setIsError(true)
					setIsValid(false)
				}
			})
			.catch(function(error) {
				setIsSaveOk(false)
				setIsError(true)
			});

		} else {
			setIsSaveOk(false)
			setIsError(true)
			setIsValid(false)
		}
		
	}
	
	useEffect(() => {
		if (userData) {
			axios.post(plconfig.APIBase + 'apps/user.groovy?action=userinfo', {
				userid: userData.uid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsFetchingData(false)
					setUserDetailData(response.data.user)
				} else {
					setIsFetchingData(true)
				}
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});
		}
	}, [userData])

	return (
		<Container maxWidth="xl" className={classes.container}>

			{!isFetchingData &&
			<React.Fragment>

			<h4 className={classes.PaperHeader}>Käyttäjän tiedot</h4>

			<Paper className={classes.paper}>

				<Typography className={classes.maintText}>Voit muuttaa omat tietosi syöttämällä uudet tiedot alla oleviin kenttiin</Typography>

				{isSaveOk &&
				<Alert severity="success" className={classes.commonAlert}>
					<AlertTitle>Tallennettu</AlertTitle>
					Käyttäjän tiedot on nyt tallennettu
				</Alert>
				}

				{isError &&
				<Alert severity="error" className={classes.commonAlert}>
					<AlertTitle>Lomakkeen tallennus epäonnistui</AlertTitle>
					Tarkasta että kaikki tiedot on syötetty ja kokeiles uudestaan!
					{isPasswordError &&
					" Salasanat eivät täsmää."
					}
					
				</Alert>
				}

				<form onSubmit={handleSave}>

					<Grid container spacing={2}>
						<Grid item xs={4}>
							<TextField
								id="firstname"
								name="firstname"
								label="Etunimi"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={userDetailData.firstname}
								fullWidth
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
								error={!isValid && userDetailData.firstname==""}
							/>
						</Grid>
						<Grid item xs={8}>
							<TextField
								id="lastname"
								name="lastname"
								label="Sukunimi"
								variant="outlined"
								className={classes.formInput}
								type="text"
								value={userDetailData.lastname}
								fullWidth
								onChange={handleTextInputChange}
								InputLabelProps={{
									shrink: true,
								}}
								error={!isValid && userDetailData.lastname==""}
							/>
						</Grid>
					</Grid>	

					<TextField
						id="phone"
						name="phone"
						label="Puhelin"
						variant="outlined"
						className={classes.formInput}
						type="text"
						value={userDetailData.phone}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						error={!isValid && userDetailData.phone==""}
					/>	

					<TextField
						id="email"
						name="email"
						label="Sähköposti"
						variant="outlined"
						className={classes.formInput}
						type="text"
						value={userDetailData.email}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						error={!isValid && userDetailData.email==""}
					/>		

					<hr/>

					<h4 className={classes.mainHeader}>Kirjautumiseen liittyvät tiedot</h4>	
					
					<TextField
						id="username"
						name="username"
						label="Käyttäjätunnus"
						variant="outlined"
						className={classes.formInput}
						type="text"
						value={userDetailData.username}
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						error={!isValid && userDetailData.username==""}
					/>	

					<Typography className={classes.maintText}>Mikäli haluat vaihtaa salasanaan kirjoita uusi salasana molempiin alla oleviin kenttiin. Jätä muuten nämä kentät tyhjiksi.</Typography>

					<TextField
						id="password"
						name="password"
						label="Salasana"
						variant="outlined"
						className={classes.formInput}
						type="password"
						defaultValue=""
						autoComplete="new-password"
						fullWidth
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						error={isPasswordError}
					/>

					<TextField
						id="password2"
						name="password2"
						label="Salasana uudestaan"
						variant="outlined"
						className={classes.formInput}
						type="password"
						defaultValue=""
						autoComplete="new-password"
						fullWidth
						helperText="Syötä salasana toiseen kertaan oikeinkirjoituksen varmistamiseksi"
						onChange={handleTextInputChange}
						InputLabelProps={{
							shrink: true,
						}}
						error={isPasswordError}
					/>
						
					<hr/>

					<Grid container className={classes.bottomGrid}>
						<Grid item xs={8}>
							<Button type="submit" variant="contained" color="primary" size="large" className={classes.saveButton}>Tallenna</Button>
						</Grid>
						<Grid item xs={4} className={classes.bottomGridRight}>
							<LinkButton to='/dashboard' className={classes.cancelButton}>Peruuta</LinkButton>
						</Grid>
					</Grid>	

				</form>

			</Paper>
		
			</React.Fragment>
			}

		</Container>
	);
}

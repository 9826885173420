import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useAuth } from './Auth'
import axios from 'axios';
import plconfig from '../config.js'

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
export default function PrivateRoute({ component: Component, ...rest }) {
	
	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();
	let sessionStorage = window.sessionStorage;
	let token = sessionStorage.getItem('aat');

	let auth = false;

	if (isAuthenticated) {
		auth = true;
	} else {
		axios.post(plconfig.APIBase + 'apps/auth.groovy?action=check', {
			token: token
		}, { headers: { "Content-Type": "text/plain" } })
		.then(function (response) {
			if (response.data.success) {
				auth = true;
				setIsAuthenticated(true)
				setUserData(response.data.user)
				sessionStorage.setItem('aat', response.data.authtokens);
			}
		}).catch(function(error) {
		});
	}

	if (auth) {
		return (
			<Route {...rest} render={(props) => (
				<Component {...props} />
			)}
			/>
		);
	} else {
		return (
			<Redirect to={{	pathname: "/login" }} />
		);
	}
}

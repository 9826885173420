
let APIBase = "https://www.luomuravintola.fi/";
if (window.location.hostname && window.location.hostname.indexOf('localhost')>-1) {
	APIBase = "http://pl2021.bonsait.fi/";
}


const plconfig = {
	APIBase: APIBase
}

export default plconfig;